import Router from 'vue-router'

/**
 * Fix Unhandled NavigationDuplicated issue
 * Ref: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 *
 * If error is created by Element-UI, this code may not work.
 * Please refer to article below for more information.
 *
 * Ref: https://www.cnblogs.com/rever/p/11577322.html
 */
const originalPush = Router.prototype.push

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      console.warn(err)
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
