


















import { RawLocation } from 'vue-router'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Array as PropType<RawLocation[]>,
      default: () => [],
    },
    icon: {
      type: String,
      default: 'el-icon-arrow-right',
    },
    separator: {
      type: String,
      default: '',
    },
    routerEnableReplace: {
      type: Boolean,
      default: false,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
})
