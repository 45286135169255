var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"return-order"},[_c('div',{staticClass:"return-order__header"},[_c('div',{staticClass:"return-order__header-left"},[_c('div',{staticClass:"return-order__title"},[_vm._v("退倉單號")]),_c('div',{staticClass:"return-order__order-number"},[_vm._v(_vm._s(_vm.order.order_number))]),_c('span',{class:[
          _vm.order.is_delivered
            ? 'return-order__deliver-status--delivered'
            : 'return-order__deliver-status--not-delivered' ]})]),_c('el-button',{class:[
        _vm.order.is_confirmed
          ? 'return-order__submit-button--confirmed'
          : 'return-order__submit-button--not-confirmed' ],attrs:{"type":"primary","size":"large","round":"","disabled":_vm.order.is_confirmed,"loading":_vm.submitLoading},on:{"click":_vm.submit}})],1),_c('div',{staticClass:"return-order__body"},[(_vm.order.is_confirmed)?_c('div',{staticClass:"return-order__notice--confirmed"},[_vm._v(" 已於 "+_vm._s(_vm.order.last_confirm_date)+" 確認送出，進入退倉作業。 ")]):_c('div',{staticClass:"return-order__notice--not-confirmed"},[_c('ol',[_c('li',[_vm._v(" 請於 "+_vm._s(_vm.order.last_confirm_date)+" 前確認以下退倉資訊： "),_vm._m(0)]),_c('li',[_vm._v(" 資訊確認無誤後，可點擊右上角「確認送出」，此退倉單即進入退倉排序作業。 ")]),_c('li',[_vm._v(" 如未於上述期限內確認或修正資料，此退倉單將於 "+_vm._s(_vm.order.last_confirm_date)+" 後自動生效並進入退倉作業。 ")])])]),(_vm.order.comment)?_c('div',{staticClass:"return-order__comment"},[_c('div',{staticClass:"return-order__comment-title"},[_vm._v("備註")]),_c('div',{staticClass:"return-order__comment-content"},[_vm._v(_vm._s(_vm.order.comment))])]):_vm._e(),_c('form',{staticClass:"return-order__block"},[_c('div',{staticClass:"return-order__subtitle"},[_vm._v("退倉收件資訊")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-group__label--required",attrs:{"for":"recipient-name"}},[_vm._v(" 收件人 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.recipient_name),expression:"order.recipient_name"}],staticClass:"form-group__input",class:{
                'input-required': _vm.order.recipient_name == '',
              },attrs:{"id":"recipient-name","disabled":_vm.order.is_confirmed},domProps:{"value":(_vm.order.recipient_name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.order, "recipient_name", $event.target.value)},_vm.returnOrderEdited]}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-group__label--required",attrs:{"for":"recipient-phone"}},[_vm._v(" 電話 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.recipient_phone),expression:"order.recipient_phone"}],staticClass:"form-group__input",class:{
                'input-required': _vm.order.recipient_phone == '',
              },attrs:{"id":"recipient-phone","disabled":_vm.order.is_confirmed},domProps:{"value":(_vm.order.recipient_phone)},on:{"change":_vm.returnOrderEdited,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.order, "recipient_phone", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-group__label--required",attrs:{"for":"recipient-email"}},[_vm._v(" Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.recipient_email),expression:"order.recipient_email"}],staticClass:"form-group__input",class:{
                'input-required': _vm.order.recipient_email == '',
              },attrs:{"id":"recipient-email","disabled":_vm.order.is_confirmed},domProps:{"value":(_vm.order.recipient_email)},on:{"change":_vm.returnOrderEdited,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.order, "recipient_email", $event.target.value)}}})])]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-group__label--required",attrs:{"for":"recipient-address"}},[_vm._v(" 退倉地址 ")]),_c('div',{staticClass:"address-input-row"},[_c('div',{staticClass:"address-input-row__cell"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.recipient_address),expression:"order.recipient_address"}],staticClass:"form-group__input",class:{
                    'input-required': _vm.order.recipient_address == '',
                  },attrs:{"id":"recipient-address","disabled":_vm.order.is_confirmed},domProps:{"value":(_vm.order.recipient_address)},on:{"change":_vm.returnOrderEdited,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.order, "recipient_address", $event.target.value)}}})]),_c('div',{staticClass:"address-input-row__cell is-default-address"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.is_default_address),expression:"order.is_default_address"}],attrs:{"type":"checkbox","id":"is-default-address","disabled":_vm.order.is_confirmed},domProps:{"checked":_vm.order.is_default_address,"checked":Array.isArray(_vm.order.is_default_address)?_vm._i(_vm.order.is_default_address,null)>-1:(_vm.order.is_default_address)},on:{"change":[function($event){var $$a=_vm.order.is_default_address,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.order, "is_default_address", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.order, "is_default_address", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.order, "is_default_address", $$c)}},_vm.returnOrderEdited]}}),_c('label',{attrs:{"for":"is-default-address"}},[_vm._v("預設為下次退倉地址")])])])])]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-group__label"},[_vm._v("寄送方式")]),_c('div',{staticClass:"form-group__options",class:{
                'options-with-notice': _vm.order.shipping_method == 'VENDOR',
              }},[_c('label',{staticClass:"form-group__option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.shipping_method),expression:"order.shipping_method"}],attrs:{"type":"radio","name":"shipping-method","value":"UPN","disabled":_vm.order.is_confirmed},domProps:{"checked":_vm._q(_vm.order.shipping_method,"UPN")},on:{"change":[function($event){return _vm.$set(_vm.order, "shipping_method", "UPN")},_vm.returnOrderEdited]}}),_vm._v(" 上恩寄送 ")]),_c('label',{staticClass:"form-group__option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.shipping_method),expression:"order.shipping_method"}],attrs:{"type":"radio","name":"shipping-method","value":"VENDOR","disabled":_vm.order.is_confirmed},domProps:{"checked":_vm._q(_vm.order.shipping_method,"VENDOR")},on:{"change":[function($event){return _vm.$set(_vm.order, "shipping_method", "VENDOR")},_vm.returnOrderEdited]}}),_vm._v(" 廠商自取 ")])])])])])]),_c('div',{staticClass:"return-order__block"},[_c('div',{staticClass:"return-order__subtitle"},[_vm._v("商品清單")]),(_vm.order.type == 'CONSIGNMENT')?_c('ConsignmentItemListTemplate',{attrs:{"itemList":_vm.order.items}}):_vm._e(),(_vm.order.type == 'PURCHASE')?_c('PurchaseItemListTemplate',{attrs:{"itemList":_vm.order.items}}):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticStyle:{"list-style-type":"lower-alpha"}},[_c('li',[_vm._v("收件資訊")]),_c('li',[_vm._v("退倉商品清單（如有疑問請Email聯繫廠管或商開）")])])}]

export { render, staticRenderFns }