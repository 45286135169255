import request from '@/legacy/utils/request'
import * as types from '../mutation-types'

const state = {
  readyToShippingOrderCount: 0,
  prepareDeliverOrderCount: 0,
}

const getters = {
  readyToShippingOrderCount: (state) => state.readyToShippingOrderCount,

  prepareDeliverOrderCount: (state) => state.prepareDeliverOrderCount,
}

const actions = {
  fetchOrderCount({ commit }) {
    Promise.all([
      request.get('shipping/ready_shipping_orders_count'),
      request.get('shipping/prepare_delivery_orders_count'),
    ]).then(([readyCount, prepareCount]) => {
      commit(types.FETCH_ORDER_COUNT_SUCCESS, {
        readyToShippingOrderCount: readyCount.data.data,
        prepareDeliverOrderCount: prepareCount.data.data,
      })
    })
  },
}

const mutations = {
  [types.FETCH_ORDER_COUNT_SUCCESS](state, countData) {
    state.readyToShippingOrderCount = countData.readyToShippingOrderCount
    state.prepareDeliverOrderCount = countData.prepareDeliverOrderCount
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
