import _, { Dictionary } from 'lodash'
import cuid from 'cuid'
import moment, { Moment } from 'moment'
import {
  OrderItem as ApiOrderItem,
  ProductItem as ApiProductItem,
  ConvertToDeliveredOrderItem as ApiConvertToDeliveredOrderItem,
} from '@/legacy/api/shipment-manager-upn-stock/shipping.types'
import { DefaultFormOrderItem } from './types/order'
import {
  FormData,
  OrderItem as FormOrderItem,
  StatusItem,
  generateOrderListByItemIsChecked,
} from './shipping-list.form'
import { generateOrderGroupMergeArrived } from './shipping.service'
import { Data as ShippingMarkData } from '@/legacy/utils/print/render/shipping-mark'
import { StatusMap } from '@/legacy/stores/mixins/form-status'

/**
 * 出貨中訂單項目 從一般 => 表單用
 * @param order 一般訂單項目
 * @param defaultField 預設值
 */
export const transformOrderItem2FormOrderItem = (
  order: OrderItem,
  defaultField?: DefaultFormOrderItem
): FormOrderItem => ({
  key: (defaultField && defaultField.key) || cuid(),
  code: (defaultField && defaultField.code) || order.code,
  createdDate:
    (defaultField && defaultField.createdDate) || order.createdDate.toDate(),
  estimateDeliveryDate:
    (defaultField && defaultField.estimateDeliveryDate) ||
    order.estimateDeliveryDate.toDate(),
  forwarder: (defaultField && defaultField.forwarder) || null,
  shipmentId: (defaultField && defaultField.shipmentId) || null,
  boxCount: (defaultField && defaultField.boxCount) || null,
  comment: (defaultField && defaultField.comment) || null,
})

/**
 * 出貨中的訂單內產品項目 從API => 一般用
 * @param product API的產品項目
 */
export const transformFetchOrderProductItem2ProductItem = (
  product: ApiProductItem
) => ({
  code: product.item_id.toString(),
  name: product.name,
  originalName: product.original_name,
  upc: product.upc,
  qty: _.parseInt(product.number),
})

/**
 * 出貨中的訂單內產品列表 從 API => 一般用
 * @param products API的產品列表
 */
export const transformFetchOrderProductList2ProductList = (
  products: ApiProductItem[]
) =>
  _.map(products, (product) =>
    transformFetchOrderProductItem2ProductItem(product)
  )

/**
 * 出貨中訂單項目，從API => 一般
 * @param order API 出貨中訂單項目
 */
export const transformFetchOrderItem2OrderItem = (order: ApiOrderItem) =>
  ({
    code: order.order_number,
    amount: order.order_items_sum,
    estimateDeliveryDate: moment(order.estimate_arrival_time),
    createdDate: moment(order.created_time),
    comment: order.comment,
    products: transformFetchOrderProductList2ProductList(order.items),
  } as OrderItem)

/**
 * 出貨中訂單列表，從API => 一般
 * @param response API 出貨中訂單列表
 */
export const transformApiOrderList2OrderList = (
  orderList: ApiOrderItem[]
): OrderItem[] =>
  _.map(orderList, (order) => transformFetchOrderItem2OrderItem(order))

export const transformOrderMergedGroup2PreviewOrderList = (
  orderGroup: OrderGroup,
  venderName: string
) =>
  _.map(orderGroup, (orders) => {
    const { boxCount } = _.first(orders)!

    const codes = _.map(orders, 'code')

    return {
      venderName,
      orderCodes: codes,
      boxCount,
      isResend: false,
    } as ShippingMarkData
  })

/**
 * 出貨中訂單列表 從一般 => 表單用
 * @param orders 一般訂單列表
 */
export const transfromOrderList2FormOrderList = (
  orders: OrderItem[]
): FormOrderItem[] =>
  _.map(orders, (order) => transformOrderItem2FormOrderItem(order))

export const transformFormData2PrintShippingMarkData = (
  formData: FormData,
  formStatus: StatusMap<StatusItem>,
  venderName: string
): ShippingMarkData[] => {
  const orderGroup = _.flow(
    generateOrderListByItemIsChecked,
    generateOrderGroupMergeArrived
  )(formData.orders, formStatus)

  return transformOrderMergedGroup2PreviewOrderList(orderGroup, venderName)
}

export const transformFormOrderItem2PrintShippingMarkDataItem = (
  order: OrderGroup,
  venderName: string
) => ({
  venderName,
  orderCodes: [order.code],
  boxCount: order.boxCount,
  isResend: false,
})

export const transformFormOrderList2ApiConvertToDeliveredOrderList = (
  orders: FormOrderItem[],
  formStatus: StatusMap<StatusItem>
): ApiConvertToDeliveredOrderItem[] =>
  _.map(orders, (order) => {
    const currentStatus = formStatus[order.key]
    const isMergeArrived = !_.isEmpty(currentStatus.merge)
    const isForwarderVND = order.forwarder === 'VND'

    return {
      order_number: order.code,
      company: order.forwarder,
      deliver_number: isForwarderVND ? null : order.shipmentId,
      boxes_count: isMergeArrived ? null : order.boxCount,
      estimated_arrival_date:
        isMergeArrived && !order.estimateDeliveryDate
          ? null
          : moment(order.estimateDeliveryDate).format('YYYY-MM-DD').toString(),
      comment: isMergeArrived ? null : order.comment,
      is_resend: '0',
    } as ApiConvertToDeliveredOrderItem
  })

export interface OrderItem {
  /** 訂單編號 */
  code: string

  /** 訂貨總計 */
  amount: number

  /** 預計到貨時間 */
  estimateDeliveryDate: Moment

  /** 訂貨時間 */
  createdDate: Moment

  /** 商品列表 */
  products: ProductItem[]

  /** 備註 */
  comment: string
}

export interface ProductItem {
  /** 商品ID */
  code: string

  /** 商品名稱 */
  name: string

  /** 原始名稱 */
  originalName: string

  /** 廠商貨號 */
  upc: string

  /** 訂貨數量 */
  qty: number
}

interface OrderGroup extends Dictionary<Array<FormOrderItem>> {}
