export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'

export const BEFORE_LOADING = 'BEFORE_LOADING'
export const AFTER_LOADING = 'AFTER_LOADING'
export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE'
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE'

export const FETCH_ORDER_COUNT_SUCCESS = 'FETCH_ORDER_COUNT_SUCCESS'
