import VueRouter from 'vue-router'
import { AxiosInstance } from 'axios'
import { AuthActionType } from '@/legacy/stores/modules/auth'
import { Store } from 'vuex'

export default <S>(req: AxiosInstance, router: VueRouter, store: Store<S>) => {
  const handleError = async (error: any) => {
    if (error.response && error.response.status === 401) {
      if (router.currentRoute.path === '/login') {
        return error
      }

      await store.dispatch(`auth/${AuthActionType.Logout}`)

      router.replace('/')
    }

    return Promise.reject(error)
  }

  return req.interceptors.response.use(undefined, handleError)
}
