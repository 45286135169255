













import { Component, Vue } from 'vue-property-decorator'
import { Supplier } from '@/legacy/api/auth/auth.types'
import DefaultHeader from './Header/DefaultHeader.vue'
import DefaultNavBar from './Navbar/DefaultNavbar.vue'
import { NavItem } from '@/legacy/layouts/Header/models/nav-item'
import { NavbarService } from '@/legacy/layouts/Header/services/navbar'
import { PermissionItem } from '@/legacy/api/auth/auth.types'
import Spinner from '@/legacy/components/Spinner/Spinner.vue'

@Component({
  components: {
    DefaultHeader,
    DefaultNavBar,
    Spinner,
  },
  beforeCreate(this: DefaultLayout) {
    this.navbarService = new NavbarService()
  },
})
export default class DefaultLayout extends Vue {
  navbarService!: NavbarService

  get supplier(): Supplier {
    return this.$store.getters['auth/supplier']
  }
  get navList(): NavItem[] {
    return this.navbarService.getNavListByPermissions(this.permissions)!
  }

  get permissions(): PermissionItem[] {
    return this.$store.getters['auth/permissions']
  }
}
