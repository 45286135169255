import _ from 'lodash'
import { Module } from 'vuex'

export const MUTATION_SET_ORIGINAL_DATA = 'SET_ORIGINAL_DATA'
export const MUTATION_SET_ORIGINAL_FIELD = 'SET_ORIGINAL_FIELD'
export const ACTION_UPDATE_ORIGINAL_FIELD = 'updateOriginalField'

export default <S extends State<O>, RS, O extends object>(
  defaultOriginal?: O
) =>
  ({
    state: {
      original: _.cloneDeep(defaultOriginal || {}),
    } as S,

    mutations: {
      [MUTATION_SET_ORIGINAL_DATA](state, { data }: PayloadSetOriginalData) {
        state.original = data
      },

      [MUTATION_SET_ORIGINAL_FIELD](
        state,
        { path, value }: PayloadSetOriginalField
      ) {
        _.set(state.original, path, value)
      },
    },

    actions: {
      [ACTION_UPDATE_ORIGINAL_FIELD](
        { commit, state },
        { path, value }: PayloadActionUpdateOriginalField
      ) {
        if (!_.has(state.original, path)) return

        commit(MUTATION_SET_ORIGINAL_FIELD, {
          path,
          value,
        } as PayloadSetOriginalField)
      },
    },
  } as Module<S, RS>)

export interface State<O> {
  original: O
}

export interface PayloadSetOriginalData {
  data
}

export interface PayloadSetOriginalField {
  path: string
  value
}

export interface PayloadActionUpdateOriginalField {
  path: string
  value
}
