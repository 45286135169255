import _ from 'lodash'
import axios, { AxiosInstance, AxiosRequestConfig, Canceler } from 'axios'
const { CancelToken } = axios

interface RequestPoolItem {
  key: string
  cancel: Canceler
}

const pool: RequestPoolItem[] = []

const sortObjectKey = <T>(obj: T): T => {
  return _.reduce(
    _.keys(obj).sort(),
    (_obj: T, key) => {
      _obj[key] = obj[key]
      return _obj
    },
    {} as T
  )
}

// 生成請求的唯一Key
const generateUniqueKey = (config: AxiosRequestConfig) => {
  const method = config.method !== undefined ? config.method + '&' : ''
  const url = config.url || ''
  const params =
    config.params !== undefined
      ? JSON.stringify(sortObjectKey(config.params)) + '&'
      : ''
  const data =
    config.data !== undefined
      ? JSON.stringify(sortObjectKey(config.data)) + '&'
      : ''

  return method + params + data + url
}

export const dropByKey = (key: string, cancel: boolean = true) => {
  const index = _.findIndex(pool, ['key', key])

  if (index < 0) {
    return
  }

  if (cancel === true) {
    pool[index].cancel()
  }

  pool.splice(index, 1)
}

/**
 * 移除請求
 * @param config AxiosRequestConfig
 */
export const drop = (config: AxiosRequestConfig, cancel: boolean = true) => {
  const key = generateUniqueKey(config)
  dropByKey(key, cancel)
}

export default (req: AxiosInstance) => {
  return req.interceptors.request.use((config) => {
    const key = generateUniqueKey(config)

    dropByKey(key)

    config.cancelToken = new CancelToken((cancel) => {
      pool.push({ key, cancel })
    })

    return config
  })
}
