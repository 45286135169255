import _ from 'lodash'
import * as apiShipmentUpnStockShipping from '@/legacy/api/shipment-manager-upn-stock/shipping'
import {
  FormData,
  StatusItem,
  OrderItem as FormOrderItem,
  generateOrderListByItemIsChecked,
} from './shipping-list.form'
import {
  OrderItem,
  transformApiOrderList2OrderList,
  transformFormOrderList2ApiConvertToDeliveredOrderList,
} from './shipping.transformer'
import { StatusMap } from '@/legacy/stores/mixins/form-status'

/**
 * 取得訂單總數
 */
export const count = async (): Promise<number> => {
  const response = await apiShipmentUpnStockShipping.count()

  return response.data
}

/**
 * 取得當前訂單列表
 * @return {Promise<OrderItem[]>} 一般訂單列表
 */
export const fetchOrderList = async (): Promise<OrderItem[]> => {
  const response = await apiShipmentUpnStockShipping.orders()

  return transformApiOrderList2OrderList(response.data)
}

/**
 * 上傳表單: 出貨中 轉 已出貨
 * @param formData 表單資訊
 * @param formStatus 表單狀態
 */
export const convertToDelivered = (
  formData: FormData,
  formStatus: StatusMap<StatusItem>
) => {
  const checkedOrderList = generateOrderListByItemIsChecked(
    formData.orders,
    formStatus
  )
  const submitOrderList = transformFormOrderList2ApiConvertToDeliveredOrderList(
    checkedOrderList,
    formStatus
  )

  return apiShipmentUpnStockShipping.convertToDelivered(submitOrderList)
}

export const generateMergeKey = (order: FormOrderItem) => {
  return `${order.forwarder}-${order.shipmentId}`
}

export const generateOrderGroupMergeArrived = (orders: FormOrderItem[]) =>
  _.chain(orders)
    .filter((o: FormOrderItem) => {
      const isForwarderVND = o.forwarder === 'VND'
      if (isForwarderVND) {
        return true
      }

      return !(_.isEmpty(o.shipmentId) || _.isEmpty(o.forwarder))
    })
    .groupBy((o) => generateMergeKey(o))
    .value()

/**
 * 檢查合併到貨
 * @param orders 訂單列表
 * @param iteratee 走訪器
 */
export const checkOrderListMergeArrived = (
  orders: FormOrderItem[],
  iteratee: (
    currentOrderKey: string,
    parentOrderKey: string,
    isMatched: boolean
  ) => void
) => {
  const groups = generateOrderGroupMergeArrived(orders)

  _.each(orders, (order) => {
    let parentCode
    let isMatched = false

    _.each(groups, (group) => {
      // 已經被合併了
      if (isMatched) return
      parentCode = _.first(group)!.code

      _.each(group, (item) => {
        if (isMatched) return
        if (parentCode !== undefined && parentCode === item.code) return
        isMatched = order.key === item.key
      })
    })

    iteratee(order.key, parentCode, isMatched)
  })
}

/**
 * 檢查逾期出貨
 * @param originOrder 表單原始訂單
 * @param targetIndex 表單目標訂單
 */
export const checkOrderItemOverdue = (
  originOrder: FormOrderItem,
  targetOrder: FormOrderItem
): boolean => {
  let value = false

  if (
    !_.isNil(targetOrder.estimateDeliveryDate) &&
    !_.isNil(originOrder.estimateDeliveryDate)
  ) {
    value =
      targetOrder.estimateDeliveryDate.valueOf() >
      originOrder.estimateDeliveryDate.valueOf()
  }

  return value
}

/**
 * 檢查合併到貨有無勾選
 * - 全部沒被勾選
 * - 全部被勾選
 * @param orders
 * @param statusMap
 */
export const checkOrderListMergeArrivedIsChecked = (
  orders: FormOrderItem[],
  statusMap: StatusMap<StatusItem>
) => {
  const groups = generateOrderGroupMergeArrived(orders)
  let checked = true

  _.each(groups, (orders) => {
    if (checked === false) {
      return false
    }

    const checkeds = _.filter(orders, (order: FormOrderItem) => {
      return _.get(statusMap, `${order.key}.checked`, false)
    })

    const isNoChooseOne = checkeds.length === 0
    const isChooseAll = checkeds.length === orders.length

    checked = isNoChooseOne || isChooseAll
  })

  return checked
}
