import _ from 'lodash'

export default {
  data() {
    return {
      sortArrayName: '',
      sortKey: '',
      sortDir: '', // asc, desc
      sortType: '', // string, number
      sortSecondKey: '',
    }
  },

  methods: {
    /**
     * init sorting data
     * @param  String sortArrayName 	要 Sorting 的成員變數名稱
     * @param  String defaultSortKey    預設 SortKey
     * @param  String defaultSortDir    預設排序方向 asc | desc
     * @param  String defaultSortType   預設排序類型 string | number
     * @param  String sortSecondKey 	當 number 資料相同時，以此欄位做比較
     */
    initSortData(
      sortArrayName,
      defaultSortKey,
      defaultSortDir = 'asc',
      defaultSortType = 'string',
      sortSecondKey = ''
    ) {
      this.sortArrayName = sortArrayName
      this.sortKey = defaultSortKey
      this.sortDir = defaultSortDir.toLowerCase()
      this.sortType = defaultSortType.toLowerCase()
      this.sortSecondKey = sortSecondKey
    },

    /**
     * 依 Key 做排序
     * @param  String sortKey
     * @param  String sortType
     */
    sortBy(sortKey, sortType = 'string') {
      if (sortKey === this.sortKey) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortKey = sortKey
        this.sortDir = 'desc'
      }
      this.sortType = sortType.toLowerCase()
      this.sortItems()
    },

    /**
     * 依目前設定做排序
     */
    sortItems() {
      if (this.sortArrayName === '') {
        console.warn('Must initSortData first')
        return
      }

      eval('this.' + this.sortArrayName).sort((a, b) => {
        var val_a = a[this.sortKey]
        var val_b = b[this.sortKey]

        if (val_a === null) {
          return -1
        }

        let direct = this.sortDir === 'asc' ? 1 : -1
        var rel = 0

        if (
          this.sortType === 'number' &&
          _.isNumber(val_a) &&
          _.isNumber(val_b)
        ) {
          val_a = Number(val_a)
          val_b = Number(val_b)
          rel = (val_a - val_b) * direct
        } else {
          rel = val_a.localeCompare(val_b) * direct
        }

        if (rel == 0 && this.sortSecondKey !== '') {
          return a[this.sortSecondKey].localeCompare(b[this.sortSecondKey])
        } else {
          return rel
        }
      })
    },

    /**
     * 取得 Class 名稱
     * @param  String sortKey
     * @return String
     */
    getSortClass(sortKey) {
      if (sortKey === this.sortKey) {
        return this.sortDir === 'asc' ? 'sorting_asc' : 'sorting_desc'
      }
      return 'sorting'
    },
  },
}
