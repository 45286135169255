















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '沒有符合的搜尋結果',
    },
  },
})
