<template>
  <div>
    <InfoMessage
      v-if="!deliveredOrderList.length"
      message="近期沒有已出貨訂單"
    ></InfoMessage>
    <h4 v-if="deliveredOrderList.length" class="hide-for-print table-title">
      已出貨訂單
    </h4>
    <div v-if="deliveredOrderList.length" class="hide-for-print">
      已出貨訂單列表
    </div>
    <table v-if="deliveredOrderList.length" class="unstriped h6">
      <thead>
        <tr>
          <th class="center">訂單編號</th>
          <th class="center">物流公司</th>
          <th class="center">物流編號</th>
          <th class="center">媽咪愛商品編號</th>
          <th class="center">供應商商品貨號</th>
          <th class="center">商品資訊</th>
          <th class="center">數量</th>
          <th class="center">轉單時間</th>
          <th class="center">上傳編號時間</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="order in deliveredOrderList">
          <tr v-for="(item, key) in order.items" :key="`tr-item-${key}`">
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.id }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.deliver_company }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.deliver_id }}
            </td>
            <td class="center">{{ item.item_id }}</td>
            <td class="center">{{ item.upc }}</td>
            <td>{{ item.name }}</td>
            <td class="center">{{ item.qty }}</td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.print_time.date | formatToLocalDateTime }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.deliver_time.date | formatToLocalDateTime }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import InfoMessage from './components/InfoMessage.vue'
import request from '@/legacy/utils/request'

export default {
  data() {
    return {
      deliveredOrderList: [],
    }
  },

  mounted() {
    this.fetchReadyShippingOrderList()
  },

  computed: {
    items() {
      const items = {}
      this.deliveredOrderList.forEach((order) => {
        order.items.forEach((item) => {
          if (!(item.item_id in items)) {
            items[item.item_id] = item
            return
          }
          items[item.item_id].qty += item.qty
        })
      })

      return items
    },

    message() {
      return this.$store.getters['flashMessage']
    },
  },

  methods: {
    fetchReadyShippingOrderList() {
      this.$store.dispatch('startLoading')
      request
        .get('shipping/delivered_orders')
        .then(({ data }) => {
          this.deliveredOrderList = data.data
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },
  },

  components: {
    InfoMessage,
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

.table-title {
  font-weight: bold;
  margin: 0.3rem 0;
}
</style>
