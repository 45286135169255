import _ from 'lodash'
import { validate, RuleMap } from '@/legacy/stores/mixins/form-validate'
import { DeliverItem } from './delivered.transformer'

export const validateRules: RuleMap = {
  forwarder: [
    {
      required: true,
      message: '物流公司 必填',
    },
  ],
  shipmentId: [
    {
      required: true,
      message: '貨運編號 必填',
    },
    {
      pattern: /^\w+$/,
      message: '勿填 -/.@*',
    },
  ],
  boxCount: [
    {
      required: true,
      message: '箱數 必填',
    },
    {
      type: 'number',
      message: '箱數 為數字',
    },
  ],
  estimateDeliveryDate: [
    {
      type: 'date',
      required: true,
      message: '預計到貨日 必填',
      trigger: 'change',
    },
  ],
}

export const convertDeliveredOrder2FormData = (
  deliveredOrderItem: DeliverItem
): FormData => ({
  id: deliveredOrderItem.id,
  forwarder: deliveredOrderItem.forwarder,
  shipmentId: deliveredOrderItem.shipmentId,
  boxCount: deliveredOrderItem.boxCount,
  estimateDeliveryDate: deliveredOrderItem.estimateDeliveryDate.toDate(),
  comment: deliveredOrderItem.comment,
  isResend: deliveredOrderItem.isResend,
})

export const validateAll = async (data: FormData) => {
  const validateConfigs = _.reduce(
    validateRules,
    (all, rules, field) => {
      // 此驗證規則是否有對應得值
      if (!_.has(data, field)) return all

      if (data.forwarder === 'VND' && field === 'shipmentId') {
        return all
      }

      const value = _.get(data, field)
      all.push({ rules, value, field })

      return all
    },
    [] as { rules: any[]; value; field: string }[]
  )

  return Promise.all(
    _.map(validateConfigs, (config) =>
      validate(config.rules, config.value, config.field)
    )
  )
}

export interface FormData {
  /** 合併後的id */
  id: number
  forwarder: string
  shipmentId: string
  boxCount: number
  estimateDeliveryDate: Date
  comment: string
  isResend: boolean
}
