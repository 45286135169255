import { Module } from 'vuex'
import forwarder, { NAME as FORWARDER_NAME } from './forwarder'
import brand, { NAME as BRAND_NAME } from './brand'
import businessLineGroup, {
  NAME as BUSINESS_LINE_GROUP_NAME,
} from './business-line-group'

export default {
  namespaced: true,

  modules: {
    [FORWARDER_NAME]: forwarder,
    [BRAND_NAME]: brand,
    [BUSINESS_LINE_GROUP_NAME]: businessLineGroup,
  },
} as Module<State, {}>

export interface State {}
