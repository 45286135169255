import _ from 'lodash'
import { RouteConfig } from 'vue-router'
import { ListQueryFactory } from '../services/list/list-query.factory'

const ProductManager = () => import('../views/Main.vue')
const ProductDeclarationListTabV2 = () =>
  import('../views/ProductDeclarationListTabV2.vue')
const ProductList = () => import('../views/product-list/Index.vue')
const ProductDeclarationDetail = () =>
  import('../views/ProductDeclarationDetail.vue')

export const ROUTE_NAME_PRODUCT_MANAGER = 'product-manager'
export const ROUTE_NAME_PRODUCT_DECLARATION_LIST = 'product-declaration-list'
export const ROUTE_NAME_PRODUCT_LIST = 'product-list'
export const ROUTE_NAME_COMBO_PRODUCT_DECLARATION_LIST =
  'combo-product-declaration-list'
export const ROUTE_NAME_PRODUCT_DECLARATION_CREATE =
  'product_declaration_create'
export const ROUTE_NAME_PRODUCT_DECLARATION_EDIT = 'product_declaration_edit'
export const ROUTE_NAME_PRODUCT_EDIT = 'product_edit'

const routes: RouteConfig[] = [
  {
    name: ROUTE_NAME_PRODUCT_MANAGER,
    path: '/product-manager',
    component: ProductManager,
    children: [
      {
        name: ROUTE_NAME_PRODUCT_DECLARATION_LIST,
        path: 'product-declaration-list',
        meta: {
          breadcrumbs: [{ name: '商品與活動管理' }, { name: '商品提報與進度' }],
        },
        component: ProductDeclarationListTabV2,
      },
      {
        name: ROUTE_NAME_PRODUCT_DECLARATION_CREATE,
        path: 'product-declaration-list/create',
        component: ProductDeclarationDetail,
        meta: {
          breadcrumbs: [
            { name: '商品與活動管理' },
            {
              name: '商品提報與進度',
              to: { name: ROUTE_NAME_PRODUCT_DECLARATION_LIST },
            },
            { name: '新增商品' },
          ],
        },
      },
      {
        name: ROUTE_NAME_PRODUCT_DECLARATION_EDIT,
        path: 'product-declaration-list/:id',
        component: ProductDeclarationDetail,
        meta: {
          breadcrumbs: [
            { name: '商品與活動管理' },
            {
              name: '商品提報與進度',
              to: { name: ROUTE_NAME_PRODUCT_DECLARATION_LIST },
            },
            { name: '編輯商品' },
          ],
        },
      },
      {
        name: ROUTE_NAME_PRODUCT_EDIT,
        path: 'product/:id',
        component: ProductDeclarationDetail,
        meta: {
          breadcrumbs: [
            { name: '商品與活動管理' },
            {
              name: '商品管理',
              to: { name: ROUTE_NAME_PRODUCT_LIST },
            },
            { name: '編輯商品' },
          ],
        },
      },
      {
        name: ROUTE_NAME_PRODUCT_LIST,
        path: 'product-list',
        meta: {
          breadcrumbs: [{ name: '商品與活動管理' }, { name: '商品管理' }],
        },
        component: ProductList,
        beforeEnter(to, from, next) {
          const listQuery = new ListQueryFactory().routeToParam(to.query)
          const routeQuery = listQuery.convertToRouteQuery()

          if (!_.isEqual(routeQuery, to.query)) {
            // @TODO: vue-router type issue
            return next(
              // @ts-ignore
              Object.assign({}, to, {
                replace: true,
                query: routeQuery,
              })
            )
          }

          next()
        },
      },
    ],
  },
]

export default routes
