import { RouteConfig } from 'vue-router'
const ShipmentUpnStock = () =>
  import('@/legacy/views/ShipmentUpnStock/Main.vue')
const ShipmentUpnStockShippingTab = () =>
  import('@/legacy/views/ShipmentUpnStock/ShippingTab.vue')
const ShipmentUpnStockPrepareDeliveryTab = () =>
  import('@/shipment-manager/upn-stock/PrepareDeliveryTab.vue')
const ShipmentUpnStockPrepareDeliveryDetailTab = () =>
  import('@/shipment-manager/upn-stock/PrepareDeliveryDetailTab.vue')
const ShipmentUpnStockDeliveredOrdersTab = () =>
  import('@/legacy/views/ShipmentUpnStock/DeliveredOrdersTab.vue')
const ShipmentUpnStockResendProcessTab = () =>
  import('@/legacy/views/ShipmentUpnStock/ResendProcessTab.vue')

const routes: RouteConfig[] = [
  {
    path: '/shipment-manager/upn-stock',
    name: 'shipment-manager-upn-stock',
    meta: {
      pageTitle: '出貨至媽咪愛倉庫',
      breadcrumbs: [{ name: '出貨管理' }, { name: '出貨至媽咪愛倉庫' }],
    },
    component: ShipmentUpnStock,
    children: [
      {
        path: 'prepare-delivery',
        alias: '',
        name: 'shipment-manager-upn-stock--prepare-delivery',
        component: ShipmentUpnStockPrepareDeliveryTab,
      },
      {
        path: 'shipping',
        name: 'shipment-manager-upn-stock--shipping',
        component: ShipmentUpnStockShippingTab,
      },
      {
        path: 'delivered-orders',
        name: 'shipment-manager-upn-stock--delivered-orders',
        component: ShipmentUpnStockDeliveredOrdersTab,
      },
      {
        path: 'resend-process',
        name: 'shipment-manager-upn-stock--resend-process',
        component: ShipmentUpnStockResendProcessTab,
      },
    ],
  },
  {
    path: '/shipment-manager/upn-stock/prepare-delivery/:id',
    name: 'shipment-manager-upn-stock--prepare-delivery-detail',
    meta: {
      breadcrumbs: [
        { name: '出貨管理' },
        {
          name: '出貨至媽咪愛倉庫',
          to: { name: 'shipment-manager-upn-stock--prepare-delivery' },
        },
        { name: '入倉單明細' },
      ],
    },
    component: ShipmentUpnStockPrepareDeliveryDetailTab,
  },
]

export default routes
