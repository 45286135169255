<!--
Usage:
<floating-head-table>
    <thead slot="thead">
        ...
    </thead>
    <tbody>
        ...
    </tbody>
</floating-head-table>
-->
<script>
import {
  querySelectorByTarget,
  querySelectorAllByTarget,
} from '@/legacy/utils/dom/query-selector'

export default {
  render: function (createElement) {
    var fhead = createElement(
      'thead',
      { class: 'fixed_header' },
      this.deepClone(this.$slots.thead[0].children, createElement)
    )
    return createElement('table', [
      this.$slots.thead,
      fhead,
      this.$slots.default,
    ])
  },

  created() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.syncHeaderWidth)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.syncHeaderWidth)
  },

  updated() {
    this.syncHeaderWidth()
  },

  methods: {
    syncHeaderWidth() {
      const $table = this.$el
      const $thead = querySelectorByTarget(
        ':scope > thead:not(.fixed_header)',
        $table
      )
      const $fhead = querySelectorByTarget(
        ':scope > thead.fixed_header',
        $table
      )

      // 將各欄位寬度設定跟原 head 相同
      $fhead.style.width = getComputedStyle($table)['width']
      const $fths = querySelectorAllByTarget(':scope > tr > th', $fhead)
      const $ths = querySelectorAllByTarget(':scope > tr > th', $thead)
      $fths.forEach(($fth, index) => {
        const $th = $ths[index]
        const thWidth = $th.offsetWidth
        $fth.style.width = thWidth + 'px'
      })
    },

    handleScroll() {
      const $table = this.$el
      const $fhead = querySelectorByTarget(
        ':scope > thead.fixed_header',
        $table
      )

      const currTop = window.scrollY
      const tblPos = (() => {
        const rect = $table.getBoundingClientRect()
        return rect.top + window.pageYOffset
      })()
      const tblEndPos =
        tblPos +
        parseInt(getComputedStyle($table)['height']) -
        parseInt(getComputedStyle($fhead)['height'])

      if ((currTop > tblPos) & (currTop < tblEndPos)) {
        this.syncHeaderWidth()
        $fhead.style.visibility = 'visible'
      } else {
        $fhead.style.visibility = 'hidden'
      }
    },

    deepClone(vnodes, createElement) {
      function cloneVNode(vnode) {
        const clonedChildren =
          vnode.children && vnode.children.map((vnode) => cloneVNode(vnode))
        const cloned = createElement(vnode.tag, vnode.data, clonedChildren)
        cloned.text = vnode.text
        cloned.isComment = vnode.isComment
        cloned.componentOptions = vnode.componentOptions
        cloned.elm = vnode.elm
        cloned.context = vnode.context
        cloned.ns = vnode.ns
        cloned.isStatic = vnode.isStatic
        cloned.key = vnode.key
        return cloned
      }
      const clonedVNodes = vnodes.map((vnode) => cloneVNode(vnode))
      return clonedVNodes
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

.fixed_header {
  position: fixed;
  top: 0;
  z-index: 2;
  visibility: hidden;
}
</style>
