import _ from 'lodash'

export const toIds = (id: string, uniq: boolean = true): string[] => {
  const ids = id
    .split(/[\s,]+/)
    .filter((val) => val.length)
    .map((val) => val.trim())
    .filter((val) => !!val)

  if (uniq) {
    return _.uniq(ids)
  }

  return ids
}
