<template>
  <div>
    <InfoMessage
      v-if="readyShippingOrderList.length"
      message="「待出貨」訂單在轉為「出貨中」前，消費者仍可自行取消訂單，請盡快安排出貨事宜。"
    ></InfoMessage>
    <InfoMessage
      v-if="!readyShippingOrderList.length"
      message="目前沒有待出貨資料"
    ></InfoMessage>
    <h4 v-if="readyShippingOrderList.length" class="table-title">
      待出貨商品總覽
    </h4>
    <table
      v-if="readyShippingOrderList.length"
      class="items-table unstriped h6"
    >
      <thead>
        <tr>
          <th class="center">媽咪愛商品編號</th>
          <th class="center">供應商貨號</th>
          <th class="center">商品資訊</th>
          <th class="center">數量</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="`tr-item-${index}`">
          <td class="center">{{ item.item_id }}</td>
          <td class="center">{{ item.upc }}</td>
          <td>{{ item.name }}</td>
          <td class="center">{{ item.qty }}</td>
        </tr>
      </tbody>
    </table>
    <h4 v-if="readyShippingOrderList.length" class="table-title">待出貨訂單</h4>
    <div v-if="readyShippingOrderList.length" class="grid-x">
      <div class="medium-6 cell">
        選取訂單轉為出貨中，系統將自動為您下載出貨單。
      </div>
      <div class="medium-6 cell text-right h6">
        總共 {{ readyShippingOrderList.length }} 筆訂單，
        <span class="red">{{ countOverDueOrder }}</span>
        筆訂單已逾期，
        <span class="red">{{ countDueSoonOrder }}</span>
        筆訂單即將到期
      </div>
    </div>
    <FloatHeaderTable v-if="readyShippingOrderList.length" class="unstriped h6">
      <thead slot="thead">
        <tr>
          <th class="center">
            <input type="checkbox" v-model="isAllChecked" />
          </th>
          <th
            class="center"
            :class="getSortClass('id')"
            @click="sortBy('id', 'number')"
          >
            訂單編號
          </th>
          <th class="center">媽咪愛商品編號</th>
          <th class="center">供應商商品貨號</th>
          <th class="center">商品資訊</th>
          <th class="center">數量</th>
          <th class="center">訂單備註</th>
          <th class="center">預購</th>
          <th
            class="center"
            :class="getSortClass('checked_time')"
            @click="sortBy('checked_time')"
          >
            訂單付款日
          </th>
          <th
            class="center"
            :class="getSortClass('estimated_delivery_time_text')"
            @click="sortBy('estimated_delivery_time_text')"
          >
            指定收貨日
          </th>
          <th
            class="center"
            :class="getSortClass('estimate_deliver_start_time')"
            @click="sortBy('estimate_deliver_start_time')"
          >
            出貨開始日
          </th>
          <th
            class="center"
            :class="getSortClass('estimate_deliver_end_time')"
            @click="sortBy('estimate_deliver_end_time')"
          >
            出貨到期日
          </th>
          <th class="center">收件人</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(order, oindex) in readyShippingOrderList">
          <tr
            v-for="(item, key) in order.items"
            :key="`order-${oindex}-item-${key}`"
          >
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              <input
                type="checkbox"
                :value="order.id"
                v-model="checkedOrdersIds"
              />
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.id }}
            </td>
            <td class="center">{{ item.item_id }}</td>
            <td class="center">{{ item.upc }}</td>
            <td>{{ item.name }}</td>
            <td class="center">{{ item.qty }}</td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              <p v-if="order.is_outlying_islands" class="red">
                外島訂單，請確認收到運費再出貨
              </p>
              {{ order.comment }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              <span v-if="order.is_preorder">預購</span>
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.checked_time | formatToLocalDate }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.estimated_delivery_time_text }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.estimate_deliver_start_time | formatToLocalDate }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.deliver_deadline.date | formatToLocalDate }}
              <span class="overdue" v-if="isOverdue(order.deadline_status)">
                <br />
                已逾期，請盡快出貨
              </span>
              <span class="duesoon" v-if="isDueSoon(order.deadline_status)">
                <br />
                即將逾期
              </span>
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.receiver.name }}
            </td>
          </tr>
        </template>
      </tbody>
    </FloatHeaderTable>
    <div class="submit-container">
      <div class="submit-area">
        <button
          class="button secondary large"
          type="button"
          @click="prepareDelivery"
        >
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          轉為出貨中
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/legacy/utils/request'
import InfoMessage from './components/InfoMessage.vue'
import FloatHeaderTable from '@/legacy/components/Table/FloatHeaderTable.vue'
import SorterMixin from '@/legacy/mixins/sorter'
import * as FileHelper from '@/legacy/utils/helper/file'

export default {
  data() {
    return {
      readyShippingOrderList: [],
      checkedOrdersIds: [],
      isAllChecked: false,
    }
  },

  mounted() {
    this.fetchReadyShippingOrderList()
    this.initSortData('readyShippingOrderList', 'estimate_deliver_end_time')
  },

  mixins: [SorterMixin],

  computed: {
    countOverDueOrder() {
      return this.readyShippingOrderList.filter((order) =>
        this.isOverdue(order.deadline_status)
      ).length
    },

    countDueSoonOrder() {
      return this.readyShippingOrderList.filter((order) =>
        this.isDueSoon(order.deadline_status)
      ).length
    },

    items() {
      const items = {}
      this.readyShippingOrderList.forEach((order) => {
        order.items.forEach((item) => {
          if (!(item.item_id in items)) {
            items[item.item_id] = Object.assign({}, item)
            return
          }
          items[item.item_id].qty += item.qty
        })
      })

      return items
    },
  },

  watch: {
    isAllChecked(value) {
      let checkIds = []

      if (value) {
        checkIds = this.readyShippingOrderList.map((order) => {
          return order.id
        })
      }

      this.checkedOrdersIds = checkIds
    },
  },

  methods: {
    fetchReadyShippingOrderList() {
      this.$store.dispatch('startLoading')
      request.get('shipping/ready_shipping_orders').then(({ data }) => {
        this.readyShippingOrderList = data.data
        this.isAllChecked = false
        this.checkedOrdersIds = []
        this.$store.dispatch('endLoading')
      })
    },

    isOverdue(deadlineStatus) {
      return deadlineStatus === 'Overdue'
    },

    isDueSoon(deadlineStatus) {
      return deadlineStatus === 'DueSoon'
    },

    prepareDelivery() {
      if (!this.checkedOrdersIds.length) {
        this.$alert('請選擇訂單')
        return
      }
      this.$store.dispatch('startLoading')

      request
        .post(
          'shipping/prepare_delivery',
          { order_ids: this.checkedOrdersIds },
          { responseType: 'blob' }
        )
        .then((response) => {
          this.$store.dispatch('shipping/fetchOrderCount')
          this.$router.push('shipping/prepare_delivery')

          FileHelper.donwload(response)
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },
  },

  components: {
    InfoMessage,
    FloatHeaderTable,
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

.table-title {
  font-weight: bold;
  margin: 0.3rem 0;
}

.items-table {
  th,
  td {
    padding: 0.2rem;
  }
}

.overdue {
  color: #ff0000;
}

.duesoon {
  color: #ff6600;
}

.red {
  color: red;
}

.submit-area {
  .button {
    margin-top: 15px;
  }
}
</style>
