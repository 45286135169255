<template>
  <div>
    <page-title></page-title>
    <div class="page-content">
      <router-view name="tab"></router-view>
      <router-view name="flash"></router-view>
      <router-view></router-view>
    </div>
    <router-view class="view two" name="submitArea"></router-view>
  </div>
</template>

<script>
import PageTitle from '@/legacy/components/PageTitle/PageTitle.vue'

export default {
  components: {
    PageTitle,
  },
}
</script>
