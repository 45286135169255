<template>
  <div class="login-form">
    <h3 class="title text-center">重新設定您的密碼</h3>

    <form v-on:submit.prevent="sendEmail">
      <div v-if="!mailSent" class="form-ctrl">
        <label for="email">請輸入您註冊的 E-Mail</label>
        <input
          id="email"
          type="email"
          class="form-control"
          v-model="email"
          required
        />
      </div>

      <button
        v-if="!mailSent"
        class="button secondary large login-btn"
        type="submit"
      >
        繼續
      </button>
    </form>

    <p v-if="mailSent">重設密碼連結已寄到你的email，請查看並進行驗證!</p>
  </div>
</template>

<script>
import request from '@/legacy/utils/request'

export default {
  data() {
    return {
      email: '',
      mailSent: false,
    }
  },

  methods: {
    sendEmail() {
      this.$store.dispatch('startLoading')
      request
        .post('password/email', {
          email: this.email,
        })
        .then(() => {
          this.mailSent = true
        })
        .catch(() => {
          this.$alert('請確認 email 格式是否正確')
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';

.login-form {
  .title {
    font-weight: bold;
  }

  width: 367px;
  height: 242px;
  background-color: $light-gray;
  margin: 10rem auto;
  padding: 36px;

  label {
    font-weight: bold;
  }

  .form-ctrl {
    margin-top: 20px;
  }

  .login-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
  }

  p {
    margin-top: 3rem;
  }
}
</style>
