import _ from 'lodash'
import Cookies from 'js-cookie'

Cookies.has = function (name: string): boolean {
  if (!name) return false
  return _.isString(Cookies.get(name))
}

Cookies.getOnce = function (name: string): string | undefined {
  const value = Cookies.get(name)
  Cookies.remove(name)

  return value
}

export default Cookies

export const URL_INTENDED = 'ML::URL_INTENDED'

declare module 'js-cookie' {
  interface CookiesStatic {
    has(name: string): boolean
    getOnce(name: string): string | undefined
  }
}
