import _ from 'lodash'
import { Route } from 'vue-router'
import {
  ListQueryDto,
  SearchType,
  ListQuery,
} from '@/product-manager/models/list-query'
import { firstValue } from '@/utils/get-first-value'
import { ProductStatusFilterType } from '@/product-manager/models/product-status-filter'

export enum OrderSort {
  Ascending = 'asc',
  Descending = 'desc',
}

const normalizeToSingleValue = (
  query: Record<string, any>
): Record<string, number | string | undefined> => {
  return _.keys(query).reduce((acc, key) => {
    const item = query[key]
    acc[key] = firstValue(item)
    return acc
  }, {})
}

export class ListQueryFactory {
  private defaultOrderSearch: OrderSort = OrderSort.Descending

  public routeToParam(query: Route['query']): ListQuery {
    const normalizedQuery = normalizeToSingleValue(query)

    // @todo query validation
    const required = {
      per_page: 100,
      page: Number(normalizedQuery.page!) ? Number(normalizedQuery.page!) : 1,
    }

    const optional = {
      product_status: normalizedQuery.product_status as ProductStatusFilterType,
      search_type: normalizedQuery.search_type as SearchType,
      order_by: normalizedQuery.order_by
        ? normalizedQuery.order_by
        : this.defaultOrderSearch,
      item_name: normalizedQuery.item_name,
      brand_id: normalizedQuery.brand_id,
      item_ids: normalizedQuery.item_ids,
      upcs: normalizedQuery.upcs,
      barcodes: normalizedQuery.barcodes,
      vendor_item_ids: normalizedQuery.vendor_item_ids,
      setting_launch_log_id: normalizedQuery.setting_launch_log_id,
      order_by_column: normalizedQuery.order_by_column,
      order_by_direction: normalizedQuery.order_by_direction,
      order_sort: normalizedQuery.order_sort
        ? normalizedQuery.order_sort
        : this.defaultOrderSearch,
      item_type: normalizedQuery.item_type,
    }

    const dto: ListQueryDto = {
      ..._.pickBy(optional, _.identity),
      ...required,
    }

    return new ListQuery(dto)
  }
}
