import _ from 'lodash'
import { reactive, set } from '@vue/composition-api'
import { SupplyStatus, Stock } from '@/legacy/api/stock/stock.type'
import { RuleItem } from '@/legacy/utils/validate/models/rule-item'

interface StockItem {
  supply_status: SupplyStatus
  quantity: number
  end_date: string
}

type FormRules = Partial<Record<string, RuleItem | RuleItem[]>>

type FormData = {
  [stockItemId: string]: StockItem
}

interface Form {
  rules: FormRules
  formData: FormData
  initForm(stocks: Stock[]): void
  createPayload(stocks: Stock[]): Record<string, any>
  updateFormData(stockItemId: string, key: keyof StockItem, val: any): void
}

export default class StockForm implements Form {
  formData = reactive<FormData>({})
  rules = reactive<FormRules>({})

  public initForm(stocks: Stock[]) {
    this.initFormData(stocks)
    this.initRules(stocks)
  }

  public updateFormData(stockItemId: string, key: keyof StockItem, val: any) {
    set(this.formData[stockItemId], key, val)
  }

  public createPayload(stocks: Stock[]) {
    const payload = _.reduce(
      this.formData,
      (result, stockItem, key) => {
        const stockIndex = stocks.findIndex((stock) => stock.item_id === key)

        if (stockIndex < 0) return result
        const isStatusNormal = stockItem.supply_status === SupplyStatus.Normal

        const isStatusChanged =
          stocks[stockIndex].supply_status !== stockItem.supply_status
        const isQuantityChanged =
          stocks[stockIndex].supply_quantity !== stockItem.quantity
        const isEndDateChanged =
          stocks[stockIndex].end_date !== stockItem.end_date

        if (isStatusChanged || isQuantityChanged || isEndDateChanged) {
          result[key] = {
            end_date: stockItem.end_date ?? '',
            supply_status: stockItem.supply_status,
            quantity: isStatusNormal ? stockItem.quantity : 0,
          }
        }
        return result
      },
      {}
    )

    return payload
  }

  private initFormData(stocks: Stock[]) {
    this.formData = {}
    const formData = _.reduce(
      stocks,
      (acc, stock) => {
        acc[stock.item_id] = {
          quantity: stock.supply_quantity,
          end_date: stock.end_date || '',
          supply_status: stock.supply_status || SupplyStatus.Normal,
        }

        return acc
      },
      {}
    )
    this.formData = Object.assign({}, formData)
  }

  private initRules(stocks: Stock[]) {
    this.rules = {}
    _.forEach(stocks, (stock) => {
      const quantityValidator = (rule, value, callback) => {
        const isStatusNormal =
          this.formData[stock.item_id].supply_status === SupplyStatus.Normal

        const minimum = stock.supply_quantity_bottom_line || 0

        if (isStatusNormal && value < minimum) {
          callback(new Error(`不得低於 ${minimum}`))
        } else {
          callback()
        }
      }

      set(this.rules, stock.item_id, {
        quantity: [
          {
            validator: quantityValidator,
            trigger: ['blur', 'change'],
          },
          {
            type: 'number',
            max: 2000,
            message: '備貨量超過 2000',
            trigger: ['blur', 'change'],
          },
        ],
      })
    })
  }
}
