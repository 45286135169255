<template>
  <div class="container">
    <div class="info-container">
      <div class="grid-x">
        <div class="medium-2 label-head">商品名稱</div>
        <div class="medium-10 content">{{ item.full_name }}</div>
      </div>
      <div class="grid-x">
        <div class="medium-2 label-head">媽咪愛商品編號</div>
        <div class="medium-10 content">{{ item.item_id }}</div>
      </div>
      <div v-if="item.upc != ''" class="grid-x">
        <div class="medium-2 label-head">商品貨號</div>
        <div class="medium-10 content">{{ item.upc }}</div>
      </div>
      <div v-if="item.barcode != ''" class="grid-x">
        <div class="medium-2 label-head">Barcode</div>
        <div class="medium-10 content">{{ item.barcode }}</div>
      </div>
    </div>
    <div class="record-container">
      <table class="table-striped table-unborder box">
        <thead>
          <tr>
            <th width="40%">{{ typeText }}時間</th>
            <th width="40%">{{ typeText }}單號</th>
            <th width="20%">{{ typeText }}數量</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) in records" :key="`record-${index}`">
            <td>{{ record.time }}</td>
            <td>{{ record.order_number }}</td>
            <td>{{ record.number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import request from '@/legacy/utils/request'

export default {
  props: {
    searchConditions: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      records: [],
      item: this.searchConditions.recordItem,
      type: this.searchConditions.recordType, // arrival, refund
      searchType: this.searchConditions.searchType, // all, arrival_date
      range: this.searchConditions.range, // {start_date, end_date}
      searchOrderNumbers: this.searchConditions.searchOrderNumbers,
    }
  },

  computed: {
    typeText() {
      switch (this.type) {
        case 'arrival':
          return '入倉'
        case 'refund':
          return '退倉'
      }
      return ''
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.$store.dispatch('startLoading')
      request
        .get(this.getFetchUrl(), {
          params: {
            item_id: this.item.item_id,
            search_type: this.searchType,
            start_date: this.range.startDate.format('YYYY-MM-DD'),
            end_date: this.range.endDate.format('YYYY-MM-DD'),
            order_numbers: this.searchOrderNumbers,
          },
        })
        .then(({ data }) => {
          this.records = data.data
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    getFetchUrl() {
      switch (this.type) {
        case 'arrival':
          return 'stock/consignment_arrival_records'
        case 'refund':
          return 'stock/consignment_refund_records'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

td,
th {
  padding-left: 40px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .info-container {
    padding: 25px 40px 25px 40px;
    font-size: 0.875rem;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    line-height: 1.7rem;

    .label-head {
      color: #333333;
      opacity: 0.6;
      text-align: right;
    }

    .content {
      padding-left: 20px;
    }
  }

  .record-container {
    flex: 1;
    overflow-y: scroll;
  }
}
</style>
