import _ from 'lodash'
import Vue from 'vue'
import VueRouter, { NavigationGuard, Route, RawLocation } from 'vue-router'

export const multiguard = (guards: NavigationGuard[]): NavigationGuard => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  const evaluateGuards = (
    guards: NavigationGuard[],
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) => {
    const guardsLeft = guards.slice(0)
    const nextGuard = guardsLeft.shift()

    if (_.isUndefined(nextGuard)) {
      next()
      return
    }

    nextGuard(to, from, (nextArg) => {
      if (_.isUndefined(nextArg)) {
        evaluateGuards(guardsLeft, to, from, next)
        return
      }

      next(nextArg)
    })
  }

  return (to, from, next) => {
    return evaluateGuards(guards, to, from, next)
  }
}

export const backWithFallback = (
  $router: VueRouter,
  fallback: RawLocation = '/'
) => {
  const hasPrevPage = window.history.length - 1 > 0

  if (!hasPrevPage) {
    $router.push(fallback)
  } else {
    $router.back()
  }
}
