import request from '@/legacy/utils/request'
import {
  RequestLoginPayload,
  ResponseLoginData,
  ResponsePermissionListData,
} from './auth.types'

export const login = (email: string, password: string) => {
  const payload: RequestLoginPayload = {
    email,
    password,
  }

  return request
    .post<ResponseLoginData>('/login', payload)
    .then(({ data }) => data)
}

export const logout = () => {
  return request.post('/logout')
}

export const switchSupplier = (id: string) => {
  return request.get(`/auth/switch_to_supplier/${id}`)
}

export const fetchPermissionList = () => {
  return request
    .get<ResponsePermissionListData>('/permission')
    .then(({ data }) => data)
}
