import _ from 'lodash'
import { Module } from 'vuex'

import FormCoreMixin, {
  State as FormCoreState,
  ACTION_UPDATE_DATA_FIELD,
  PayloadActionUpdateDataField,
} from '@/legacy/stores/mixins/form-core'
import FormOriginalMixin, {
  State as FormOriginalState,
} from '@/legacy/stores/mixins/form-original'
import FormStatusMixin, {
  State as FormStatusState,
  MUTATION_DEL_STATUS_ITEM,
  ACTION_UPDATE_STATUS_ITEM,
  PayloadActionUpdateStatusItem,
  PayloadDeleteStatusItem,
} from '@/legacy/stores/mixins/form-status'
import FormValidateMixin, {
  State as FormValidateState,
  MUTATION_SET_VALIDATE_ITEM,
  PayloadSetValidateItem,
  ValidateMessage,
} from '@/legacy/stores/mixins/form-validate'

import {
  FormData,
  StatusItem,
  OrderItem as FormOrderItem,
  OrderMap as FormOrderMap,
  OrderItemWithIndex as FormOrderItemWithIndex,
  generateDefaultFormData,
  generateDefaultSubFormOrderItem,
  generateDefaultFormStatusItem,
  generateValidationListFromOrderItemIsChecked,
} from '@/legacy/views/ShipmentUpnStock/domain/shipping-list.form'
import { checkOrderItemOverdue } from '@/legacy/views/ShipmentUpnStock/domain/shipping.service'
import {
  validate,
  RuleItem,
  ValidateError,
} from '@/legacy/stores/mixins/form-validate'

export const NAME = 'SHIPMENT_UPN_STOCK_SHIPPING_LIST'

/** ACTION-新增子訂單 */
export const ACTION_ADD_SUB_ORDER_ITEM = 'addSubOrderItem'
/** ACTION-刪除子訂單 */
export const ACTION_DELETE_SUB_ORDER_ITEM = 'deleteSubOrderItem'
/** ACTION-更新訂單項目欄位 */
export const ACTION_UPDATE_ORDER_ITEM_FIELD = 'updateOrderItemField'
/** ACTION-更新訂單勾選狀態 */
export const ACTION_UPDATE_ORDER_ITEM_CHECKED_STATUS =
  'updateOrderItemCheckedStatus'
/** ACTION-更新訂單合併狀態 */
export const ACTION_UPDATE_ORDER_ITEM_MERGE_STATUS =
  'updateOrderItemMergeStatus'
/** ACTION-更新訂單過期狀態 */
export const ACTION_UPDATE_ORDER_ITEM_OVERDUE_STATUS =
  'updateOrderItemOverdueStatus'
/** ACTION-檢查訂單預計到貨 */
export const ACTION_CHECK_ORDER_ITEM_ESTIMATE_OVERDUE =
  'checkOrderItemEstimateOverdue'
/** ACTION-驗證訂單項目 */
export const ACTION_VALIDATE_ORDER_ITEM_FIELD = 'validateOrderItemField'
/** ACTION-驗證全部已被勾選的欄位 */
export const ACTION_VALIDATE_ORDER_ITEM_FOR_CHECKED = 'validateAllForChecked'
/** ACTION-更新全部的勾選狀態 */
export const ACTION_UPDATE_All_ORDER_ITEM_CHECKED_STATUS =
  'updateAllOrderItemCheckedStatus'

/** MUTATION-添加訂單 */
export const MUTATION_APPEND_ORDER_ITEM = 'APPEND_ORDER_ITEM'
/** MUTATION-插入訂單 */
export const MUTATION_INSERT_ORDER_ITEM = 'INSERT_ORDER_ITEM'
/** MUTATION-刪除訂單 */
export const MUTATION_REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM'

const transformOrdersNormalizePath2KeyField = (path: string) => {
  const matches = path.match(/([^\.]+)\.(\S+)/)
  if (!matches) return null
  return {
    key: matches[1],
    field: matches[2],
  }
}

const main = {
  namespaced: true,

  mutations: {
    [MUTATION_INSERT_ORDER_ITEM](
      state,
      { index, order, next }: PayloadInsertOrderItem
    ) {
      const _index = next === true ? index + 1 : index
      state.data.orders.splice(_index, 0, order)
    },

    [MUTATION_REMOVE_ORDER_ITEM](state, { index }: PayloadRemoveOrderItem) {
      state.data.orders.splice(index, 1)
    },

    [MUTATION_APPEND_ORDER_ITEM](state, { order }: PayloadAppendOrderItem) {
      state.data.orders.push(order)
    },
  },

  actions: {
    [ACTION_ADD_SUB_ORDER_ITEM](
      { dispatch, commit, state, getters },
      { targetKey }: PayloadActionAddSubOrderItem
    ) {
      const orders: FormOrderMap = getters.normalizedOrderList
      // eslint-disable-next-line no-prototype-builtins
      if (!orders.hasOwnProperty(targetKey)) {
        // orders 沒有 key
        return
      }

      const targetOriginIndex = _.findIndex(state.original.orders, [
        'key',
        targetKey,
      ])
      if (targetOriginIndex < 0) {
        // original 沒有此 index 的 order
        return
      }

      const targetIndex = orders[targetKey].index
      if (targetIndex < 0) {
        // orders.targetKey 沒有此 index;
        return
      }

      const targetOriginOrder = state.original.orders[targetOriginIndex]
      const subOrder = generateDefaultSubFormOrderItem(targetOriginOrder)

      // 插入訂單
      commit(MUTATION_INSERT_ORDER_ITEM, {
        index: targetIndex,
        order: subOrder,
        next: true,
      } as PayloadInsertOrderItem)

      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key: subOrder.key,
        path: 'parent',
        value: targetOriginOrder.key,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_DELETE_SUB_ORDER_ITEM](
      { commit, getters },
      { targetKey }: PayloadActionDeleteSubOrderItem
    ) {
      const orders: FormOrderMap = getters.normalizedOrderList
      // eslint-disable-next-line no-prototype-builtins
      if (!orders.hasOwnProperty(targetKey)) {
        // orders 沒有 targetKey
        return
      }

      const targetIndex = orders[targetKey].index
      if (targetIndex < 0) return

      // 刪除訂單
      commit(MUTATION_REMOVE_ORDER_ITEM, {
        index: targetIndex,
      } as PayloadRemoveOrderItem)

      // 刪除遺留的狀態
      commit(MUTATION_DEL_STATUS_ITEM, {
        key: targetKey,
      } as PayloadDeleteStatusItem)
    },

    [ACTION_UPDATE_ORDER_ITEM_FIELD](
      { dispatch, getters },
      { path, value }: PayloadActionUpdateOrderItemField
    ) {
      const normalizedPath = transformOrdersNormalizePath2KeyField(path)
      if (!normalizedPath) return
      const { key, field } = normalizedPath
      const orders: FormOrderMap = getters.normalizedOrderList
      // eslint-disable-next-line no-prototype-builtins
      if (!orders.hasOwnProperty(key)) return

      const index = orders[key].index
      if (index < 0) return

      // 更新欄位
      dispatch(ACTION_UPDATE_DATA_FIELD, {
        path: `orders[${index}].${field}`,
        value,
      } as PayloadActionUpdateDataField)
    },

    [ACTION_UPDATE_ORDER_ITEM_CHECKED_STATUS](
      { dispatch },
      { key, value }: PayloadActionUpdateOrderItemCheckedStatus
    ) {
      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key,
        path: 'checked',
        value,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_UPDATE_ORDER_ITEM_MERGE_STATUS](
      { dispatch },
      { key, value }: PayloadActionUpdateOrderItemMergeStatus
    ) {
      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key,
        path: 'merge',
        value,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_UPDATE_ORDER_ITEM_OVERDUE_STATUS](
      { dispatch },
      { key, value }: PayloadActionUpdateOrderItemOverdueStatus
    ) {
      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key,
        path: 'overdued',
        value,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_CHECK_ORDER_ITEM_ESTIMATE_OVERDUE](
      { dispatch, state, getters },
      { key }: PayloadActionCheckOrderItemEstimateOverdue
    ) {
      const orders: FormOrderMap = getters.normalizedOrderList
      // eslint-disable-next-line no-prototype-builtins
      if (!orders.hasOwnProperty(key)) return

      const targetOrder = orders[key]
      const originOrder = _.find(state.original.orders, [
        'code',
        targetOrder.code,
      ])
      if (_.isUndefined(originOrder)) return

      const isOverdue = checkOrderItemOverdue(originOrder, targetOrder)

      dispatch(ACTION_UPDATE_ORDER_ITEM_OVERDUE_STATUS, {
        key,
        value: isOverdue,
      } as PayloadActionUpdateOrderItemOverdueStatus)
    },

    [ACTION_UPDATE_All_ORDER_ITEM_CHECKED_STATUS](
      { dispatch, state },
      { checked }: PayloadActionUpdateAllOrderItemCheckedStatus
    ) {
      _.each(state.data.orders, (order) => {
        dispatch(ACTION_UPDATE_ORDER_ITEM_CHECKED_STATUS, {
          key: order.key,
          value: checked,
        } as PayloadActionUpdateOrderItemCheckedStatus)
      })
    },

    async [ACTION_VALIDATE_ORDER_ITEM_FIELD](
      { commit, getters },
      { path, rules }: PayloadActionValidateOrderItemField
    ) {
      const normalizedPath = transformOrdersNormalizePath2KeyField(path)
      if (!normalizedPath) return
      const { key, field } = normalizedPath
      const orders: FormOrderMap = getters.normalizedOrderList
      if (!_.has(orders, `${key}.${field}`)) return

      const validated = await validate(
        rules,
        _.get(orders, `${key}.${field}`),
        path
      )

      commit(MUTATION_SET_VALIDATE_ITEM, {
        key: path,
        message: validated,
      } as PayloadSetValidateItem)
    },

    async [ACTION_VALIDATE_ORDER_ITEM_FOR_CHECKED]({ commit, state, getters }) {
      const validations = generateValidationListFromOrderItemIsChecked(
        state.status,
        getters.normalizedOrderList
      )
      const errors: ValidateMessage[] = []

      const validateds = await Promise.all(
        _.map(validations, (v) => validate(v.rules, v.value, v.path))
      )

      _.each(validateds, (validated) => {
        if (validated.state === 'error') errors.push(validated)

        commit(MUTATION_SET_VALIDATE_ITEM, {
          key: validated.field,
          message: validated,
        } as PayloadSetValidateItem)
      })

      if (!_.isEmpty(errors)) throw new ValidateError()
    },
  },

  getters: {
    normalizedOrderList(state): FormOrderMap {
      return _.chain(state.data.orders)
        .map(
          (order, index) => _.assign(order, { index }) as FormOrderItemWithIndex
        )
        .keyBy('key')
        .value()
    },

    orderCount(state) {
      return state.data.orders.length
    },

    checkedOrderCount(state) {
      return _.reduce(
        state.status,
        (count, status) => {
          if (status.checked === true) {
            count += 1
          }

          return count
        },
        0
      )
    },
  },
} as Module<State, {}>

export default _.merge(
  FormCoreMixin(generateDefaultFormData()),
  FormOriginalMixin(generateDefaultFormData()),
  FormStatusMixin({}, generateDefaultFormStatusItem()),
  FormValidateMixin(),
  main
)

export interface State
  extends FormCoreState<FormData>,
    FormOriginalState<FormData>,
    FormStatusState<StatusItem>,
    FormValidateState<{}> {}

export interface PayloadInsertOrderItem {
  index: number
  order: FormOrderItem
  /** 插入至指定index的下一個 */
  next: boolean
}

export interface PayloadRemoveOrderItem {
  index: number
}

export interface PayloadAppendOrderItem {
  order: FormOrderItem
}

export interface PayloadActionAddSubOrderItem {
  targetKey: string
}

export interface PayloadActionDeleteSubOrderItem {
  targetKey: string
}

export interface PayloadActionUpdateOrderItemField {
  path: string
  value
}

export interface PayloadActionUpdateOrderItemCheckedStatus {
  key: string
  /** 是不是已勾選 */
  value: boolean
}

export interface PayloadActionUpdateOrderItemMergeStatus {
  key: string
  /** 合併的父Key */
  value: string
}

export interface PayloadActionUpdateOrderItemOverdueStatus {
  key: string
  /** 是不是已過期 */
  value: boolean
}

export interface PayloadActionUpdateAllOrderItemCheckedStatus {
  checked: boolean
}

export interface PayloadActionCheckOrderItemEstimateOverdue {
  key: string
}

export interface PayloadActionValidateOrderItemField {
  path: string
  rules: RuleItem[]
}
