import { Store } from 'vuex'
import { NavigationGuard } from 'vue-router'
import { AuthActionType } from '@/legacy/stores/modules/auth'
import Cookie, { URL_INTENDED } from '@/legacy/utils/cookie'
import { ROUTER_NAME_LOGIN_PAGE } from '../auth'

export default <S>(store: Store<S>): NavigationGuard =>
  async (to, from, next) => {
    await store.dispatch(`auth/${AuthActionType.Init}`)

    // 未登入
    if (!store.getters['auth/isLoggedIn']) {
      Cookie.set(URL_INTENDED, to.fullPath)
      next({ name: ROUTER_NAME_LOGIN_PAGE })
      return
    }

    // 已登入 && 有 url 轉跳
    if (Cookie.has(URL_INTENDED)) {
      next(Cookie.getOnce(URL_INTENDED))
      return
    }

    // 預設行為
    next()
  }
