import { RouteConfig } from 'vue-router'
import ShippingTab from '@/legacy/views/Shipping/tab.vue'
import Shipping from '@/legacy/views/Shipping/index.vue'
import ReadyToShipping from '@/legacy/views/Shipping/ReadyToShippingOrders.vue'
import PrepareDelivery from '@/legacy/views/Shipping/PrepareDeliverOrders.vue'
import DeliveredOrders from '@/legacy/views/Shipping/DeliveredOrders.vue'
import InfoMessageArea from '@/legacy/views/Shipping/InfoMessageArea.vue'

const shipping: RouteConfig[] = [
  {
    path: '/shipping',
    component: Shipping,
    meta: {
      pageTitle: '廠出出貨管理',
    },
    children: [
      {
        path: '',
        components: {
          tab: ShippingTab,
          default: ReadyToShipping,
        },
      },
      {
        path: 'prepare_delivery',
        components: {
          tab: ShippingTab,
          default: PrepareDelivery,
        },
      },
      {
        path: 'delivered_orders',
        components: {
          tab: ShippingTab,
          flash: InfoMessageArea,
          default: DeliveredOrders,
        },
      },
    ],
  },
]

export default shipping
