import _ from 'lodash'
import { Module } from 'vuex'
import AsyncValidator, { RuleItem as AsyncRuleItem } from 'async-validator'

export const MUTATION_SET_VALIDATE = 'SET_VALIDATE'
export const MUTATION_SET_VALIDATE_ITEM = 'SET_VALIDATE_ITEM'
export const MUTATION_DEL_VALIDATE_ITEM = 'DEL_VALIDATE_ITEM'
export const ACTION_VALIDATE_FIELD = 'validateField'

export const validate: Validate = (
  rules: RuleItem[] | RuleItem,
  value: any,
  path: string = ''
) =>
  new Promise<ValidateMessage>((resolve) => {
    const rule = {
      [path]: rules,
    }
    const model = {
      [path]: value,
    }
    const schema = new AsyncValidator(rule)
    schema.validate(
      model,
      {
        firstFields: true,
      },
      (errors) => {
        const success = !errors
        const state = success ? 'success' : 'error'
        const field = path
        const message = success ? '' : errors[0].message

        resolve({
          state,
          field,
          message,
        } as ValidateMessage)
      }
    )
  })

export class ValidateError extends Error {
  name = 'ValidateError'

  constructor(message?: string) {
    super(message)
  }
}

/** 驗證 */
export type Validate = (
  rules: RuleItem[] | RuleItem,
  value: any,
  field: string
) => Promise<ValidateMessage>

export type RuleItem = {
  trigger?: 'blur' | 'change' | ['blur', 'change']
} & AsyncRuleItem

export interface RuleMap {
  [key: string]: RuleItem[]
}

/** 驗證後的訊息 */
export interface ValidateMessage {
  state: 'success' | 'error' | ''
  message: string
  field?: string
}

export default <S extends State<VM>, RS, VM extends object>(
  defaultValidate?: VM
) =>
  ({
    state: {
      validate: _.cloneDeep(defaultValidate || {}),
    } as S,

    mutations: {
      [MUTATION_SET_VALIDATE](state, { data }: PayloadSetValidate) {
        state.validate = data
      },

      [MUTATION_SET_VALIDATE_ITEM](
        state,
        { key, message }: PayloadSetValidateItem
      ) {
        state.validate = {
          ...(state.validate || {}),
          [key]: {
            ...state.validate[key],
            ...message,
          },
        } as VM
      },

      [MUTATION_DEL_VALIDATE_ITEM](state, { key }: PayloadDeleteValidateItem) {
        delete state.validate[key]
      },
    },

    actions: {
      async [ACTION_VALIDATE_FIELD](
        { commit, state },
        { path, rules }: PayloadActionValidateField
      ) {
        if (!_.has(state, 'data')) return
        if (!_.has(state, `data.${path}`)) return

        const message = await validate(
          rules,
          _.get(state, `data.${path}`),
          path
        )

        commit(MUTATION_SET_VALIDATE_ITEM, {
          key: path,
          message,
        } as PayloadSetValidateItem)
      },
    },
  } as Module<S, RS>)

export interface State<VM extends object> {
  validate: VM
}

export interface PayloadSetValidate {
  data
}

export interface PayloadSetValidateItem {
  key: string
  message: ValidateMessage
}

export interface PayloadDeleteValidateItem {
  key: string
}

export interface PayloadActionValidateField {
  path: string
  rules: RuleItem[]
}
