<template>
  <div>
    <InfoMessage
      v-if="!readyShippingOrderList.length"
      message="目前沒有出貨中資料"
    ></InfoMessage>
    <div v-if="readyShippingOrderList.length" class="grid-x hide-for-print">
      <div class="medium-6 cell">
        <h4 class="hide-for-print table-title">出貨中訂單</h4>
        請於出貨當日上傳貨運編號、完成出貨作業，以利消費者追蹤物流配送進度。
      </div>
      <div class="button-ctrl medium-6 cell text-right">
        <button class="button hollow" type="button" @click="print">
          <i class="fa fa-print" aria-hidden="true"></i>
          列印
        </button>
        <button
          class="button hollow"
          type="button"
          @click="gotoPartialDownload"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          部份下載
        </button>
        <a
          class="button hollow"
          href="shipping/download_prepare_deliver_orders"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          下載
        </a>
      </div>
    </div>
    <FloatHeaderTable v-if="readyShippingOrderList.length" class="unstriped h6">
      <thead slot="thead">
        <tr>
          <th v-if="showCheckbox" class="center">
            <input type="checkbox" v-model="isAllChecked" />
          </th>
          <th
            class="center"
            :class="getSortClass('id')"
            @click="sortBy('id', 'number')"
          >
            訂單編號
          </th>
          <th v-if="isEditView" class="center">
            物流公司名稱
            <select v-model="allSelect">
              <option
                v-for="deliver in delivers"
                :key="`deliver-item--${deliver.key}`"
                :value="deliver.key"
              >
                {{ deliver.name }}
              </option>
            </select>
            <input v-if="allSelect === 'OLE'" type="text" v-model="allOle" />
          </th>
          <th v-if="isEditView" class="center deliver-column">貨運編號</th>
          <th class="center">媽咪愛商品編號</th>
          <th class="center">供應商商品貨號</th>
          <th class="center">商品資訊</th>
          <th class="center">數量</th>
          <th class="center">訂單備註</th>
          <th class="center">預購</th>
          <th
            class="center"
            :class="getSortClass('checked_time')"
            @click="sortBy('checked_time')"
          >
            訂單付款日
          </th>
          <th
            class="center"
            :class="getSortClass('estimated_delivery_time_text')"
            @click="sortBy('estimated_delivery_time_text')"
          >
            指定收貨日
          </th>
          <th
            class="center"
            :class="getSortClass('estimate_deliver_start_time')"
            @click="sortBy('estimate_deliver_start_time')"
          >
            出貨開始日
          </th>
          <th
            class="center"
            :class="getSortClass('estimate_deliver_end_time')"
            @click="sortBy('estimate_deliver_end_time')"
          >
            出貨到期日
          </th>
          <th class="center">收件人</th>
          <th class="center">轉單時間</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(order, oindex) in readyShippingOrderList">
          <tr
            v-for="(item, key) in order.items"
            :key="`order-${oindex}-item-${key}`"
          >
            <td
              class="center"
              v-if="showCheckbox && key == 0"
              :rowspan="order.items.length"
            >
              <input
                type="checkbox"
                :value="order.id"
                v-model="checkedOrdersIds"
              />
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.id }}
            </td>
            <td
              v-if="isEditView && key == 0"
              :rowspan="order.items.length"
              class="deliver-column center"
            >
              <select v-model="deliverList[order.id].deliver_company">
                <option
                  v-for="deliver in delivers"
                  :key="`deliver-item-${deliver.key}`"
                  :value="deliver.key"
                >
                  {{ deliver.name }}
                </option>
              </select>
              <input
                v-if="deliverList[order.id].deliver_company === 'OLE'"
                type="text"
                v-model="deliverList[order.id].custom_deliver_company"
              />
            </td>
            <td
              v-if="isEditView && key == 0"
              :rowspan="order.items.length"
              class="center"
            >
              <input type="text" v-model="deliverList[order.id].deliver_id" />
            </td>
            <td class="center">{{ item.item_id }}</td>
            <td class="center">{{ item.upc }}</td>
            <td>{{ item.name }}</td>
            <td class="center">{{ item.qty }}</td>
            <td v-if="key == 0" :rowspan="order.items.length">
              <p v-if="order.is_outlying_islands" class="red">
                外島訂單，請確認收到運費再出貨
              </p>
              {{ order.comment }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              <span v-if="order.is_preorder">預購</span>
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.checked_time | formatToLocalDate }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.estimated_delivery_time_text }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.estimate_deliver_start_time | formatToLocalDate }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.deliver_deadline.date | formatToLocalDate }}
              <span class="overdue" v-if="isOverdue(order.deadline_status)">
                <br />
                已逾期，請盡快出貨
              </span>
              <span class="duesoon" v-if="isDueSoon(order.deadline_status)">
                <br />
                即將逾期
              </span>
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.receiver.name }}
            </td>
            <td class="center" v-if="key == 0" :rowspan="order.items.length">
              {{ order.print_time.date | formatToLocalDateTime }}
            </td>
          </tr>
        </template>
      </tbody>
    </FloatHeaderTable>
    <div class="submit-container">
      <div v-if="isDefaultView" class="submit-area hide-for-print">
        <button class="button secondary large" type="button" @click="openModal">
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          出貨完成，上傳貨運編號
        </button>
      </div>
      <div v-if="isEditView" class="submit-area hide-for-print">
        <button
          class="button hollow back-button"
          type="button"
          @click="backToDefaultView"
        >
          返回
        </button>
        <button
          class="button warning submit-button"
          type="button"
          @click="submitForm"
        >
          送出
        </button>
      </div>
      <div v-if="isPartialDownloadView" class="submit-area hide-for-print">
        <button
          class="button hollow back-button"
          type="button"
          @click="backToDefaultView"
        >
          返回
        </button>
        <button
          class="button warning submit-button"
          type="button"
          @click="downloadPartial"
        >
          部份下載
        </button>
      </div>
    </div>

    <UploadModal
      v-if="showModal"
      @upload="uploadFile"
      @close="closeModal"
      @gotoEditForm="gotoEditForm"
    ></UploadModal>
  </div>
</template>

<script>
import UploadModal from './UploadModal.vue'
import InfoMessage from './components/InfoMessage.vue'
import request from '@/legacy/utils/request'
import FloatHeaderTable from '@/legacy/components/Table/FloatHeaderTable.vue'
import SorterMixin from '@/legacy/mixins/sorter'

export default {
  data() {
    return {
      readyShippingOrderList: [],
      checkedOrdersIds: [],
      isAllChecked: false,
      showModal: false,
      viewName: 'default',
      delivers: [],
      deliverList: {},
      allSelect: '',
      allOle: '',
    }
  },

  mounted() {
    this.fetchReadyShippingOrderList()
    this.initSortData('readyShippingOrderList', 'estimate_deliver_end_time')
  },

  mixins: [SorterMixin],

  computed: {
    countOverDueOrder() {
      return this.readyShippingOrderList.filter((order) =>
        this.isOverdue(order.deadline_status)
      ).length
    },

    countDueSoonOrder() {
      return this.readyShippingOrderList.filter((order) =>
        this.isDueSoon(order.deadline_status)
      ).length
    },

    items() {
      const items = {}
      this.readyShippingOrderList.forEach((order) => {
        order.items.forEach((item) => {
          if (!(item.item_id in items)) {
            items[item.item_id] = Object.assign({}, item)
            return
          }
          items[item.item_id].qty += item.qty
        })
      })

      return items
    },

    showCheckbox() {
      if (this.isEditView || this.isPartialDownloadView) {
        return true
      }

      return false
    },

    isDefaultView() {
      return this.viewName === 'default'
    },

    isEditView() {
      return this.viewName === 'uploadDeliver'
    },

    isPartialDownloadView() {
      return this.viewName === 'partialDownload'
    },
  },

  watch: {
    isAllChecked(value) {
      let checkIds = []

      if (value) {
        checkIds = this.readyShippingOrderList.map((order) => {
          return order.id
        })
      }

      this.checkedOrdersIds = checkIds
    },

    allSelect() {
      this.changeAllSelect()
    },

    allOle() {
      this.changeAllOle()
    },
  },

  methods: {
    fetchReadyShippingOrderList() {
      this.$store.dispatch('startLoading')

      Promise.all([
        request.get('shipping/prepare_delivery_orders'),
        request.get('shipping/deliver_companies'),
      ])
        .then(([orderData, deliverData]) => {
          this.readyShippingOrderList = orderData.data.data

          this.delivers = deliverData.data.data

          const selected = {}
          this.readyShippingOrderList.forEach((order) => {
            selected[order.id] = {
              order_id: order.id,
              deliver_company: this.delivers[0].key,
              custom_deliver_company: '',
              deliver_id: '',
            }
          })
          this.deliverList = selected
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    isOverdue(deadlineStatus) {
      return deadlineStatus === 'Overdue'
    },

    isDueSoon(deadlineStatus) {
      return deadlineStatus === 'DueSoon'
    },

    openModal() {
      this.showModal = true
    },

    closeModal() {
      this.showModal = false
    },

    uploadFile() {
      this.$store.dispatch('startLoading')

      const fileInput = document.querySelector('#import_file')
      const formData = new FormData()
      formData.append('import_file', fileInput.files[0])
      request
        .post('shipping/upload_deliver_info', formData)
        .then(({ data }) => {
          this.$store.dispatch('shipping/fetchOrderCount')
          this.$router.push('/shipping/delivered_orders', () => {
            this.$store.dispatch('flash', data.data)
          })
        })
        .catch(({ response }) => {
          this.$alert(response.data.error_message)
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    submitForm() {
      if (!this.checkedOrdersIds.length) {
        this.$alert('請勾選要更新貨運編號的訂單')
        return
      }

      const deliverList = this.checkedOrdersIds.map((orderId) => {
        return this.deliverList[orderId]
      })

      request
        .post('shipping/update_deliver_info', deliverList)
        .then(({ data }) => {
          this.$store.dispatch('shipping/fetchOrderCount')
          this.$router.push('/shipping/delivered_orders', () => {
            this.$store.dispatch('flash', data.data)
          })
        })
        .catch(({ response }) => {
          this.$alert(response.data.error_message)
        })
    },

    downloadPartial() {
      if (this.checkedOrdersIds.length === 0) {
        this.$alert('請勾選要下載的訂單')
        return
      }
      let orderIds = this.checkedOrdersIds.join(',')
      location.href =
        'shipping/download_prepare_deliver_orders?order_ids=' + orderIds
    },

    gotoEditForm() {
      this.viewName = 'uploadDeliver'
      this.closeModal()
    },

    gotoPartialDownload() {
      this.viewName = 'partialDownload'
    },

    backToDefaultView() {
      this.viewName = 'default'
    },

    print() {
      window.print()
    },

    changeAllSelect() {
      Object.keys(this.deliverList).forEach((orderId) => {
        this.deliverList[orderId].deliver_company = this.allSelect
      })
    },

    changeAllOle() {
      Object.keys(this.deliverList).forEach((orderId) => {
        this.deliverList[orderId].custom_deliver_company = this.allOle
      })
    },
  },
  components: {
    UploadModal,
    InfoMessage,
    FloatHeaderTable,
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';
$form-spacing: rem-calc(12);
$input-font-size: rem-calc(12);
$form-label-font-size: rem-calc(12);
@import '~foundation-sites/scss/foundation';
@include foundation-forms;
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

.table-title {
  font-weight: bold;
  margin: 0.3rem 0;
}

table {
  .deliver-column {
    width: 7rem;
  }
}
.submit-area {
  .button {
    margin-top: 17px;
  }

  .back-button {
    padding: 1em 3em;
  }

  .submit-button {
    padding: 1em 6em;
  }
}
.overdue {
  color: #ff0000;
}

.duesoon {
  color: #ff6600;
}

.red {
  color: red;
}

select,
input[type='text'],
input[type='checkbox'] {
  margin-bottom: 0;
}

.deliver-column {
  width: 150px;
}
</style>
