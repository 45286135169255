import _ from 'lodash'
import moment from 'moment'
import * as billboard from '@/legacy/api/billboard/post.ts'

const formateDateWeek = (date) => {
  return moment(date)
    .locale('zh-tw')
    .format('YYYY.MM.DD (dddd)')
    .replace('星期', '')
}

export default {
  namespaced: true,

  state: {
    list: {
      fetching: false,
      data: null,
      hasNewest: false,
    },
    detail: {
      fetching: false,
      data: null,
    },
  },

  mutations: {
    listFetching(state, isFetching) {
      state.list.fetching = isFetching
    },
    listData(state, payload) {
      state.list.data = payload.data
    },
    detailFetching(state, isFetching) {
      state.detail.fetching = isFetching
    },
    detailData(state, payload) {
      state.detail.data = payload.data
    },
    listNewest(state, hasNewest) {
      state.list.hasNewest = hasNewest
    },
    listReaded(state) {
      state.list.hasNewest = false
    },
  },

  actions: {
    GET_posts: async ({ commit, state }) => {
      try {
        commit('listFetching', true)
        const { data } = await billboard.list()

        if (_.isArray(state.list.data) && state.list.hasNewest === false) {
          const firstPostOld = _.first(state.list.data)
          const firstPostNew = _.first(data)
          const hasNewest = firstPostOld.id !== firstPostNew.id

          commit('listNewest', hasNewest)
          commit(
            'header/highlight',
            {
              key: 'root',
              value: hasNewest,
            },
            { root: true }
          )
        }

        _.each(data, (post) => {
          post.created_at = formateDateWeek(post.created_at)
          post.updated_at = formateDateWeek(post.updated_at)
        })

        commit('listData', { data })
        commit('listFetching', false)
      } catch (error) {
        commit('listData', { data: null })
        commit('listFetching', false)
      }
    },
    GET_post: async ({ commit }, { id }) => {
      try {
        commit('detailFetching', true)
        billboard.single(id).then(({ data }) => {
          data.created_at = formateDateWeek(data.created_at)
          data.updated_at = formateDateWeek(data.updated_at)
          commit('detailData', { data })
          commit('detailFetching', false)
        })
      } catch (error) {
        // eslint-disable-next-line no-undef
        commit('detailData', { data })
        commit('detailFetching', false)
      }
    },
    REST_post({ commit }) {
      commit('detailFetching', false)
      commit('detailData', { data: null })
    },
  },
}
