<template>
  <div class="login-form">
    <h3 class="title text-center" v-html="message"></h3>
  </div>
</template>

<script>
import request from '@/legacy/utils/request'

export default {
  data() {
    return {
      token: '',
      message: '帳號啟用中，請稍候...',
    }
  },
  watch: {
    $route() {
      this.setParam()
    },
  },
  mounted() {
    this.setParam()
    this.verifyEmail()
  },
  methods: {
    setParam() {
      this.token = this.$route.params.token
    },
    verifyEmail() {
      this.$store.dispatch('startLoading')
      request
        .post(`verify-email/${this.token}`)
        .then(({ data }) => {
          if (data.status === 'success') {
            this.success(data)
          } else if (data.status === 'error') {
            this.error(data)
          } else {
            this.$alert('未知錯誤! 請治系統管理員.')
          }
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },
    success(response) {
      this.$alert(
        `✅ ${response.data.email} ${response.message}<br>請設定您的密碼`,
        {
          dangerouslyUseHTMLString: true,
        }
      )
      this.message = `✅ ${response.data.email}<br>${response.message}`
      const encodeEmail = encodeURIComponent(response.data.email)
      this.$router.push(
        `/password/reset_form/${response.data.passwordToken}?email=${encodeEmail}`
      )
    },
    error(response) {
      this.message = `❌ ${response.message}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';

.login-form {
  .title {
    font-weight: bold;
    margin-bottom: 30px;
  }

  width: 367px;
  background-color: $light-gray;
  margin: 5rem auto;
  padding: 36px;
}
</style>
