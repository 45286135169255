import { render, staticRenderFns } from "./ReturnOrder.vue?vue&type=template&id=31a22bc8&scoped=true&"
import script from "./ReturnOrder.vue?vue&type=script&lang=ts&"
export * from "./ReturnOrder.vue?vue&type=script&lang=ts&"
import style0 from "./ReturnOrder.vue?vue&type=style&index=0&id=31a22bc8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a22bc8",
  null
  
)

export default component.exports