import _ from 'lodash'
import { Module } from 'vuex'

import FormCoreMixin, { State as FormCoreState } from '../../mixins/form-core'
import FormValidateMixin, {
  State as FormValidateState,
  MUTATION_SET_VALIDATE_ITEM,
  PayloadSetValidateItem,
  ValidateError,
  ValidateMessage,
} from '@/legacy/stores/mixins/form-validate'
import {
  FormData,
  validateAll,
} from '@/legacy/views/ShipmentUpnStock/domain/delivered-editor.form'

export const NAME = 'SHIPMENT_UPN_STOCK_EDITOR_DELIVERED_ORDER'

export const ACTION_VALIDATE_ALL = 'validateAll'

const main = {
  namespaced: true,

  actions: {
    async [ACTION_VALIDATE_ALL]({ commit, state }) {
      const errors: ValidateMessage[] = []
      const validateMessages = await validateAll(state.data)

      _.each(validateMessages, (message) => {
        if (message.state === 'error') errors.push(message)
        commit(MUTATION_SET_VALIDATE_ITEM, {
          key: message.field,
          message,
        } as PayloadSetValidateItem)
      })

      if (!_.isEmpty(errors)) throw new ValidateError()
    },
  },
} as Module<State, {}>

export default _.merge(FormCoreMixin(), FormValidateMixin(), main)

export interface State extends FormCoreState<FormData>, FormValidateState<{}> {}
