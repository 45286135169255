import { ref } from '@vue/composition-api'
import {
  SupplyStatus,
  Stock,
  UpdateStocksPayload,
} from '@/legacy/api/stock/stock.type'

import StockFormService from '../form/StockForm'

const statusDetailMap = {
  [SupplyStatus.Normal]: { variant: 'success', text: '供貨中' },
  [SupplyStatus.Suspend]: { variant: 'warning', text: '暫停供貨' },
  [SupplyStatus.Prohibited]: { variant: 'danger', text: '永久停供' },
}

export const useStockForm = () => {
  const showEditDateModal = ref(false)
  const editStockId = ref<string | null>(null)
  const isEdited = ref(false)

  const form = ref(new StockFormService())

  const initStockForm = (stocks: Stock[]) => {
    form.value.initForm(stocks)
    isEdited.value = false
  }

  const handleChangeStockStatus = (itemId: string, value: SupplyStatus) => {
    const actionMap = {
      [SupplyStatus.Normal]: () => {
        form.value.updateFormData(itemId, 'supply_status', value)
        form.value.updateFormData(itemId, 'end_date', '')
      },
      [SupplyStatus.Suspend]: (itemId) => {
        editStockId.value = itemId
        showEditDateModal.value = true
      },
      [SupplyStatus.Prohibited]: () => {
        form.value.updateFormData(itemId, 'supply_status', value)
        form.value.updateFormData(itemId, 'end_date', '')
        form.value.updateFormData(itemId, 'quantity', 0)
      },
    }

    actionMap[value](itemId)
    isEdited.value = true
  }

  const handleChangeToSuspend = ({ id, date }) => {
    if (!form.value.formData[id]) return

    form.value.updateFormData(id, 'supply_status', SupplyStatus.Suspend)
    form.value.updateFormData(id, 'end_date', date)
    form.value.updateFormData(id, 'quantity', 0)

    isEdited.value = true
  }

  const handleChangeQuantity = (itemId, value) => {
    const quantity = parseInt(value) || 0
    form.value.updateFormData(itemId, 'quantity', quantity)

    isEdited.value = true
  }

  const isQuantityDisabled = (itemId: string) => {
    return (
      form.value.formData[itemId]?.supply_status === SupplyStatus.Suspend ||
      form.value.formData[itemId]?.supply_status === SupplyStatus.Prohibited
    )
  }

  const isActionPunished = (stocks: Stock[], payload: UpdateStocksPayload) => {
    return stocks.some((stock) => {
      // need_order_count > 0 時，將商品改成暫停供貨/永久停供，會有懲罰廠商的罰則
      const setSuspendOrProhibited =
        payload[stock.item_id]?.supply_status === SupplyStatus.Suspend ||
        payload[stock.item_id]?.supply_status === SupplyStatus.Prohibited

      return stock.need_order_count > 0 && setSuspendOrProhibited
    })
  }

  const showQuantityPunishMessage = (row: Stock) => {
    const isStockSuspend =
      form.value.formData[row.item_id]?.supply_status === SupplyStatus.Suspend
    const isProhibited =
      form.value.formData[row.item_id]?.supply_status ===
      SupplyStatus.Prohibited

    return row.need_order_count > 0 && (isStockSuspend || isProhibited)
  }

  const getDropdownDetail = (id, type: 'class' | 'text') => {
    if (type === 'text') {
      return statusDetailMap[form.value.formData[id].supply_status].text
    }

    if (type === 'class') {
      return `badge--${
        statusDetailMap[form.value.formData[id].supply_status].variant
      }`
    }
  }

  return {
    form,
    showEditDateModal,
    editStockId,
    isEdited,
    initStockForm,
    handleChangeStockStatus,
    handleChangeToSuspend,
    handleChangeQuantity,
    isQuantityDisabled,
    isActionPunished,
    showQuantityPunishMessage,
    getDropdownDetail,
  }
}
