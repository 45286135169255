import _ from 'lodash'
import { Module } from 'vuex'

import {
  MUTATION_SET_ORIGINAL_DATA,
  PayloadSetOriginalData,
} from './form-original'
import { MUTATION_SET_STATUS, PayloadSetStatus } from './form-status'
import { MUTATION_SET_VALIDATE, PayloadSetValidate } from './form-validate'

export const MUTATION_SET_DATA = 'SET_DATA'
export const MUTATION_SET_DATA_FIELD = 'SET_DATA_FIELD'
export const ACTION_UPDATE_DATA_FIELD = 'updateDataField'
export const ACTION_CLEAR = 'clear'
export const ACTION_RESET = 'reset'
export const ACTION_INITIAL = 'initial'

export default <S extends State<D>, RS, D extends object>(defaultData?: D) =>
  ({
    state: {
      data: _.cloneDeep(defaultData || {}),
    } as S,

    mutations: {
      [MUTATION_SET_DATA](state, { data }: PayloadSetData) {
        state.data = data
      },

      [MUTATION_SET_DATA_FIELD](state, { path, value }: PayloadSetDataField) {
        _.set(state.data, path, value)
      },
    },

    actions: {
      [ACTION_UPDATE_DATA_FIELD](
        { commit, state },
        { path, value }: PayloadActionUpdateDataField
      ) {
        if (!_.has(state.data, path)) return

        commit(MUTATION_SET_DATA_FIELD, {
          path,
          value,
        } as PayloadSetDataField)
      },

      [ACTION_CLEAR]({ commit, state }) {
        commit(MUTATION_SET_DATA, {
          data: _.cloneDeep(defaultData),
        } as PayloadSetData)

        _.has(state, 'original') &&
          commit(MUTATION_SET_ORIGINAL_DATA, {
            data: _.cloneDeep(defaultData),
          } as PayloadSetOriginalData)

        _.has(state, 'validate') &&
          commit(MUTATION_SET_VALIDATE, {
            data: {},
          } as PayloadSetValidate)

        _.has(state, 'status') &&
          commit(MUTATION_SET_STATUS, {
            data: {},
          } as PayloadSetStatus)
      },

      [ACTION_RESET]({ commit, state }) {
        _.has(state, 'original') &&
          commit(MUTATION_SET_DATA, {
            data: _.get(state, 'original', {}),
          } as PayloadSetData)

        _.has(state, 'validate') &&
          commit(MUTATION_SET_VALIDATE, {
            data: {},
          } as PayloadSetValidate)

        _.has(state, 'status') &&
          commit(MUTATION_SET_STATUS, {
            data: {},
          } as PayloadSetStatus)
      },

      async [ACTION_INITIAL](
        { commit, state },
        { data }: PayloadActionInitial
      ) {
        commit(MUTATION_SET_DATA, {
          data: _.cloneDeep(data),
        } as PayloadSetData)

        _.has(state, 'original') &&
          commit(MUTATION_SET_ORIGINAL_DATA, {
            data: _.cloneDeep(data),
          } as PayloadSetOriginalData)
      },
    },
  } as Module<S, RS>)

export interface State<D> {
  data: D
}

export interface PayloadSetData {
  data
}

export interface PayloadSetDataField {
  path: string
  value: any
}

export interface PayloadActionUpdateDataField {
  path: string
  value: any
}

export interface PayloadActionInitial {
  data
}
