import _ from 'lodash'
import { Route } from 'vue-router'
import { computed, ref } from '@vue/composition-api'
import { useRoute, useRouter, useStore } from '@/composition-api'
import { SupplyStatus, Stock } from '@/legacy/api/stock/stock.type'
import { Pagination } from '@/api/objects/Pagination.types'
import { useMessageBox } from '@/composition-api'
import * as apiStock from '@/legacy/api/stock/stock'

interface StatusOption {
  text: string
  value: SupplyStatus
  variant: string
}

const enum ShippingType {
  Vendor = 'vendor',
  Mamilove = 'mamilove',
}

interface useStocksDataConfig {
  productOrderType?: string
}

export const useStocksData = ({
  productOrderType,
}: useStocksDataConfig = {}) => {
  const route = useRoute()
  const router = useRouter()
  const store = useStore<any>()
  const { $alert } = useMessageBox()

  const stocks = ref<Stock[]>([])
  const shippingByVendorCount = ref(0)
  const shippingByUpnCount = ref(0)

  const shippingType = computed<ShippingType>(() =>
    route.value.query.is_shipping_by_vendor === '1'
      ? ShippingType.Vendor
      : ShippingType.Mamilove
  )

  const tabs = computed(() => [
    {
      text: `媽咪愛出貨 (${shippingByUpnCount.value})`,
      value: ShippingType.Mamilove,
    },
    {
      text: `廠商自行出貨 (${shippingByVendorCount.value})`,
      value: ShippingType.Vendor,
    },
  ])

  const statusOptions = computed<StatusOption[]>(() => {
    return [
      { text: '供貨中', value: SupplyStatus.Normal, variant: 'success' },
      { text: '暫停供貨', value: SupplyStatus.Suspend, variant: 'warning' },
      { text: '永久停供', value: SupplyStatus.Prohibited, variant: 'danger' },
    ]
  })
  const searched = computed(() => !_.isEmpty(route.value.query))
  const pagination = ref<Pagination | null>(null)
  const totalRow = computed(() => pagination.value?.total || 0)
  const rowInfo = computed(() => {
    if (!pagination.value) return '0'
    if (!pagination.value.from && !pagination.value.to) return '0' // 搜尋結果為空陣列時，from, to 會是 null

    return `${pagination.value.from}-${pagination.value.to}`
  })
  const changeShippingType = (tab) => {
    // 切換出貨狀態時，回到第一頁
    router.push({
      query: {
        ...route.value.query,
        is_shipping_by_vendor: tab.name === ShippingType.Vendor ? '1' : '0',
        page: '1',
      },
    })
  }

  const getQuantityInputErrorTooltip = (row: Stock) => {
    return row.is_shipping_by_vendor
      ? '備貨量不可低於客訂數'
      : '架上庫存+在途數+備貨量，不可低於客訂數'
  }

  const getStockList = async (query: Route['query']) => {
    try {
      store.dispatch('startLoading')
      const response = await apiStock.getStocks(query, productOrderType)
      stocks.value = response.data
      shippingByUpnCount.value = response.meta.shipping_by_upn_count
      shippingByVendorCount.value = response.meta.shipping_by_vendor_count
      pagination.value = response.meta.pagination
    } catch (error) {
      // @ts-ignore
      $alert(error.error_message)
    } finally {
      store.dispatch('endLoading')
    }
  }

  const isStockProhibited = (stock: Stock | any) => {
    return stock.supply_status === SupplyStatus.Prohibited
  }
  const isStockSuspend = (stock: Stock | any) => {
    return stock.supply_status === SupplyStatus.Suspend
  }

  return {
    stocks,
    shippingType,
    tabs,
    statusOptions,
    searched,
    pagination,
    totalRow,
    rowInfo,
    changeShippingType,
    getStockList,
    isStockProhibited,
    isStockSuspend,
    getQuantityInputErrorTooltip,
  }
}
