import { isNil } from 'lodash'
import { Module } from 'vuex'

import * as ApiBrand from '@/legacy/api/commons/brand'
import { BrandItem } from '@/legacy/api/commons/brand.types'

export const NAME = 'BRAND_LIST'
export const MUTATION_SET_DATA = 'SET_DATA'
export const MUTATION_SET_FETCHING = 'SET_FETCHING'
export const ACTION_FETCH_BRAND_LIST = 'fetchBrandList'

export default {
  namespaced: true,

  state: {
    fetching: false,
    data: null,
  },

  mutations: {
    [MUTATION_SET_DATA](state, { data }: PayloadSetData) {
      state.data = data
    },

    [MUTATION_SET_FETCHING](state, { value }: PayloadSetFetching) {
      state.fetching = value
    },
  },

  actions: {
    async [ACTION_FETCH_BRAND_LIST](
      { commit, state },
      payload: PayloadFetchData
    ) {
      const forced = payload ? payload.forced : false
      if (forced === false && (state.fetching === true || !isNil(state.data))) {
        return
      }

      commit(MUTATION_SET_FETCHING, {
        value: true,
      } as PayloadSetFetching)

      const { data } = await ApiBrand.list()

      commit(MUTATION_SET_DATA, {
        data,
      } as PayloadSetData)

      commit(MUTATION_SET_FETCHING, {
        value: false,
      } as PayloadSetFetching)
    },
  },
} as Module<State, {}>

export interface State {
  fetching: boolean
  data: BrandItem[] | null
}

export interface PayloadSetData {
  data: BrandItem[]
}

export interface PayloadSetFetching {
  value: boolean
}

export interface PayloadFetchData {
  forced: boolean
}
