<template>
  <div class="PostItem">
    <div class="PostItem__status">
      <svg
        v-if="top"
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="19"
        viewBox="0 0 13 19"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#F6798E"
          stroke-linecap="round"
          stroke-width="2"
        >
          <path
            d="M4.636 13.143H1.404A.405.405 0 0 1 1 12.738V6.262c0-.224.181-.405.404-.405h3.232c.223 0 .404.181.404.405v6.476a.405.405 0 0 1-.404.405z"
          />
          <path
            stroke-linejoin="round"
            d="M5.445 5.496L11.91 1v17l-6.465-4.496"
          />
        </g>
      </svg>
    </div>
    <div class="PostItem__content" :class="{ 'is-top': top }">
      <div class="date-container">{{ date }}</div>
      <div class="title-container">{{ title }}</div>
      <div class="content-container">
        <div ref="content-text" class="content-text overflow">
          <span>{{ content }}</span>
        </div>
        <span v-if="isContentOverflow" class="overflow-dot">...</span>
        <button
          v-if="isContentOverflow"
          class="append-button"
          @click="handleClickContainer"
        >
          展開
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
      </div>

      <div class="attaches-container">
        <a
          v-for="(attach, index) in attaches"
          :key="`attach-item-${index}`"
          class="attach-item"
          target="_blank"
          :href="attach.url"
          @click.stop="() => {}"
        >
          {{ attach.title }}
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    top: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    attaches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isContentOverflow: false,
    }
  },
  mounted() {
    this.isContentOverflow = this.checkContentOverflow()
    window.addEventListener('resize', this.handleWindowResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    checkContentOverflow() {
      const $contentContainer = this.$refs['content-text']
      return (
        $contentContainer.scrollHeight > $contentContainer.clientHeight ||
        $contentContainer.scrollWidth > $contentContainer.clientWidth
      )
    },
    handleClickContainer() {
      this.$emit('click', this.id)
    },
    handleWindowResize() {
      this.isContentOverflow = this.checkContentOverflow()
    },
  },
}
</script>

<style lang="scss" scoped>
$text-color: #414141;
$date-text-color: lighten(#333, 30%);
$hover-background-color: rgba(250, 250, 250, 1);
$primary-color: #f6798e;
$link-color: #0076ff;

.PostItem {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  padding-left: 0;
  padding-right: 80px;
  flex: 1;
  background-color: #fff;

  &__status {
    align-self: center;
    width: 80px;
    font-size: 26px;
    color: $primary-color;
    text-align: center;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.is-top {
      .title-container {
        color: $primary-color;
      }
      .content-container {
        .content-text {
          color: $primary-color;
        }
        .overflow-dot {
          color: $primary-color;
        }
      }
    }

    .date-container {
      font-size: 12px;
      color: $date-text-color;
      line-height: 1.5em;
    }

    .title-container {
      padding: 6px 0;
      font-size: 18px;
      color: $text-color;
      font-weight: bold;
    }

    .content-container {
      font-size: 14px;
      color: $text-color;
      line-height: 1.5em;
      max-height: 1.5em;
      display: flex;
      flex-direction: row;

      .content-text {
        flex: 1;
        position: relative;

        &.overflow {
          overflow: hidden;
        }
      }

      .append-button {
        display: inline-block;
        font-size: 14px;
        color: $link-color;
        padding-right: 10px;
        cursor: pointer;

        &:hover {
          color: lighten($link-color, 30);
        }
      }

      .overflow-dot {
        margin-right: 5px;
      }
    }

    .attaches-container {
      .attach-item {
        display: inline-block;
        font-size: 14px;
        color: $link-color;
        padding-right: 10px;

        &:hover {
          color: lighten($link-color, 30);
        }
      }
    }
  }

  &:hover {
    background-color: $hover-background-color;
  }
}
</style>
