<template>
  <el-card class="BillboardCard" :body-style="{ padding: 0 }">
    <div slot="header" class="BillboardCard__header-container">
      <span class="text-title">最新公告</span>
      <span class="text-count" v-if="!loading">共 {{ count }} 則</span>
    </div>
    <div
      ref="posts-container"
      class="BillboardCard__posts-container"
      v-loading="loading"
    >
      <div
        :class="{
          'top-item-container': true,
          'is-not-scroll-reach-top': !isPostContainerReachTop,
        }"
      >
        <billboard-post-item
          v-for="post in data.tops"
          :key="`post-top-item-${post.id}`"
          :id="`${post.id}`"
          :title="post.subject"
          :content="post.content"
          :date="post.created_at"
          :top="post.top"
          :attaches="post.attaches"
          @click="() => handleClickItem(post.id)"
        />
      </div>
      <billboard-post-item
        v-for="post in data.posts"
        :key="`post-item-${post.id}`"
        :id="`${post.id}`"
        :title="post.subject"
        :content="post.content"
        :date="post.created_at"
        :attaches="post.attaches"
        @click="() => handleClickItem(post.id)"
      />
    </div>
  </el-card>
</template>

<script>
import _ from 'lodash'

import BillboardPostItem from './BillboardPostItem'

export default {
  components: {
    BillboardPostItem,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      postContainerScrollReach: 'top',
    }
  },

  computed: {
    data() {
      const tops = []
      const posts = []
      _.each(this.posts, (post) => {
        if (post.top) {
          tops.push(post)
        } else {
          posts.push(post)
        }
      })
      return { tops, posts }
    },
    count() {
      return this.posts ? this.posts.length : 0
    },
    isPostContainerReachTop() {
      return this.postContainerScrollReach === 'top'
    },
    isPostContainerReachBottom() {
      return this.postContainerScrollReach === 'bottom'
    },
  },

  mounted() {
    const $postContainer = this.$refs['posts-container']
    if ($postContainer) {
      $postContainer.addEventListener('scroll', this.handlePostContainerScroll)
    }
  },

  beforeDestroy() {
    const $postContainer = this.$refs['posts-container']
    if ($postContainer) {
      $postContainer.removeEventListener(
        'scroll',
        this.handlePostContainerScroll
      )
    }
  },

  methods: {
    handleClickItem(id) {
      this.$emit('click-item', id)
    },
    handlePostContainerScroll(e) {
      if (e.target.scrollTop === 0) {
        this.postContainerScrollReach = 'top'
      } else {
        this.postContainerScrollReach = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$background-color: #fff;

.BillboardCard {
  &__header-container {
    margin: -8px 0;

    span.text-title {
      font-size: 14px;
      color: #0076ff;
      line-height: 24px;
    }
    span.text-count {
      float: right;
      text-align: right;
      font-size: 12px;
      color: rgba(51, 51, 51, 0.4);
      line-height: 24px;
    }
  }

  &__posts-container {
    min-height: 100px;
    max-height: 392px;
    overflow-y: scroll;
    overflow-x: hidden;

    @supports ((position: sticky)) {
      .top-item-container {
        z-index: 1;
        position: sticky;
        top: 0;

        &.is-not-scroll-reach-top {
          box-shadow: 0px 15px 10px -18px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
