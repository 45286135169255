<template>
  <div class="grid-container">
    <header class="header top-bar grid-x">
      <div class="header-logo cell">
        <h2 class="title">媽咪愛廠商後台管理系統</h2>
      </div>
    </header>
    <router-view />
    <Spinner />
  </div>
</template>

<script>
import Spinner from '@/legacy/components/Spinner/Spinner.vue'

export default {
  components: {
    Spinner,
  },
}
</script>

<style lang="scss" scoped>
h2.title {
  text-align: center;
}
</style>
