<template>
  <transition name="popup">
    <div class="popup-mask" @click="$emit('close')">
      <div class="popup-wrapper">
        <div class="popup-container" @click.stop>
          <div class="popup-header grid-x">
            <div class="medium-10 title">{{ title }}</div>
            <div class="medium-2 close">
              <i
                class="fa fa-times"
                aria-hidden="true"
                @click="$emit('close')"
              ></i>
            </div>
          </div>
          <div class="popup-body">
            <slot></slot>
          </div>
          <div class="popup-footer grid-x">
            <div class="medium-12 text-right">
              <button
                class="button secondary radius small"
                @click="$emit('close')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.popup-wrapper {
  width: 100%;
  height: 100%;
  padding: 3%;
}

.popup-container {
  width: 710px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);

  .popup-header {
    padding: 24px 40px;
    background-color: #fbfbfb;

    .title {
      font-size: 2rem;
      font-weight: bold;
    }

    .close {
      margin: auto;
      text-align: right;
    }

    i {
      cursor: pointer;
    }
  }

  .popup-body {
    flex: 1;
    overflow-y: auto;
    box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.16);
  }

  .popup-footer {
    padding: 20px 40px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
}

.popup-enter,
.popup-leave-active {
  opacity: 0;
}

.popup-enter .popup-container,
.popup-leave-active .popup-container {
  transform: scale(1.1);
}
</style>
