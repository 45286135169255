

















































import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    stockId: {
      type: String,
      default: '',
    },
    defaultDate: {
      type: String,
      default: '',
    },
  },
  setup(props, ctx) {
    const date = ref('')
    const deliveryOption = ref('')

    watch(
      () => props.defaultDate,
      (val) => {
        date.value = val
      },
      { immediate: true }
    )

    watch(
      () => props.visible,
      (val) => {
        if (val !== true) {
          return
        }
        if (props.defaultDate === null) {
          deliveryOption.value = 'unknownDate'
        } else {
          deliveryOption.value = 'selectDate'
        }
      },
      { immediate: true }
    )

    const handleClose = () => {
      date.value = ''
      deliveryOption.value = ''
      ctx.emit('update:visible', false)
      ctx.emit('update:stockId', '')
    }

    const handleConfirm = () => {
      if (
        deliveryOption.value === 'selectDate' &&
        props.defaultDate !== date.value
      ) {
        ctx.emit('success', { id: props.stockId, date: date.value })
      }
      if (deliveryOption.value === 'unknownDate') {
        ctx.emit('success', { id: props.stockId, date: null })
      }
      handleClose()
    }

    const pickerOptions = {
      disabledDate(time) {
        return time.getTime() < Date.now()
      },
    }

    return {
      date,
      deliveryOption,
      pickerOptions,
      handleConfirm,
      handleClose,
    }
  },
})
