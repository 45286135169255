import _ from 'lodash'
import cuid from 'cuid'
import { RuleMap, RuleItem } from '@/legacy/stores/mixins/form-validate'
import { StatusMap } from '@/legacy/stores/mixins/form-status'
import { generateOrderGroupMergeArrived } from './resend.service'
import { transformOrderMergedGroup2PreviewOrderList } from './preview-order.transformer'

export const validateRules: RuleMap = {
  code: [
    {
      required: true,
      message: '貨運編號 必填',
    },
  ],
  forwarder: [
    {
      required: true,
      message: '物流公司 必填',
    },
  ],
  shipmentId: [
    {
      required: true,
      message: '貨運編號 必填',
    },
    {
      pattern: /^\w+$/,
      message: '勿填 -/.@* 等特殊符號',
    },
  ],
  boxCount: [
    {
      required: true,
      message: '箱數 必填',
    },
    {
      type: 'number',
      message: '箱數 為數字',
    },
  ],
  estimateDeliveryDate: [
    {
      type: 'date',
      required: true,
      message: '預計到貨日 必填',
      trigger: 'change',
    },
  ],
}

export const generateDefaultFormData = (): FormData => ({
  orders: [],
})

export const generateDefaultFormStatusItem = (): StatusItem => ({
  checked: false,
  merge: null,
})

export const generateDefaultOrderItem = (): OrderItem => ({
  key: cuid(),
  code: null,
  estimateDeliveryDate: null,
  forwarder: null,
  shipmentId: null,
  boxCount: null,
  comment: null,
})

export const generateOrderListByItemIsChecked = (
  orders: OrderItem[],
  status: StatusMap<StatusItem>
): OrderItem[] =>
  _.reduce<OrderItem, OrderItem[]>(
    orders,
    (all, order) => {
      const state = status[order.key]
      if (!state) return all
      if (!state.checked) return all

      all.push(order)
      return all
    },
    [] as OrderItem[]
  )

export const generatePreviewOrderList = (
  status: StatusMap<StatusItem>,
  orders: OrderItem[]
) =>
  _.flow(
    generateOrderListByItemIsChecked,
    generateOrderGroupMergeArrived,
    transformOrderMergedGroup2PreviewOrderList
  )(orders, status)

export const generateValidateOrderItemKey = (
  orderKey: string,
  validateField: string
) => `${orderKey}.${validateField}`

export const generateValidationListFromOrderItemIsChecked = (
  status: StatusMap<StatusItem>,
  orders: OrderMap
) => {
  return (
    _.chain(status)
      // 已勾選的訂單key
      .reduce((all, statue, key) => {
        if (statue.checked === true) {
          all.push(key)
        }
        return all
      }, [] as string[])

      // 已勾選的訂單項目
      .reduce((all, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (orders.hasOwnProperty(key)) {
          all.push(orders[key])
        }
        return all
      }, [] as OrderItemWithIndex[])

      // [驗證Promise[]]
      .map((order) => {
        // 當前訂單項目是合併的
        const isMergeArrived = !_.isEmpty(status[order.key].merge)
        // 廠商親自送貨
        const isForwarderVND = order.forwarder === 'VND'

        return _.reduce(
          validateRules,
          (all, rules, field) => {
            // 合併到貨的訂單不需要驗證的欄位
            if (
              isMergeArrived &&
              (field === 'boxCount' || field === 'estimateDeliveryDate')
            ) {
              return all
            }

            if (isForwarderVND && field === 'shipmentId') {
              return all
            }

            // 此驗證規則是否有對應得值
            if (!_.has(order, field)) return all

            const value = _.get(order, field)
            const path = generateValidateOrderItemKey(order.key, field)

            all.push({ rules, value, path })

            return all
          },
          [] as { rules: RuleItem[]; value: any; path: string }[]
        )
      })
      // 驗證Promise[]
      .flatten()
      .value()
  )
}

export interface OrderMap {
  [key: string]: OrderItemWithIndex
}

export interface OrderItemWithIndex extends OrderItem {
  index: number
}

export interface OrderItem {
  /** 唯一key */
  key: string

  /** 訂單編號 */
  code: string | null

  /** 預計到貨時間 */
  estimateDeliveryDate: Date | null

  /** 貨運商 key */
  forwarder: string | null

  /** 貨運編號 */
  shipmentId: string | null

  /** 箱數 */
  boxCount: number | null

  /** 備註 */
  comment: string | null
}

export interface StatusItem {
  checked: boolean
  merge: string | null
}

export interface FormData {
  orders: OrderItem[]
}
