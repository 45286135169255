




























import { defineComponent, ref, Ref } from '@vue/composition-api'
import moment from 'moment'
import DateRangePicker from '@/legacy/components/DateRangePicker.vue'

interface DateRangePickerInstance {
  dates: [moment.Moment, moment.Moment]
}

const defaultDateRange = {
  startDate: moment().subtract(30, 'days'),
  endDate: moment(),
}

export default defineComponent({
  components: {
    DateRangePicker,
  },
  setup(props, { emit }) {
    const range = ref(defaultDateRange)
    const pickerRef: Ref<DateRangePickerInstance | null> = ref(null)
    const daysDisabledStart = moment().add(1, 'days')
    const daysDisabledEnd = moment().subtract(91, 'days')

    const handleSearchClick = () => {
      emit(
        'submitDateRange',
        range.value.startDate.format('YYYY-MM-DD'),
        range.value.endDate.format('YYYY-MM-DD')
      )
    }

    const selectDate = (newRange) => {
      range.value = newRange
    }

    const handleResetClick = () => {
      if (pickerRef.value) {
        ;(pickerRef.value as DateRangePickerInstance).dates = [
          defaultDateRange.startDate,
          defaultDateRange.endDate,
        ]
      }
    }

    return {
      range,
      pickerRef,
      daysDisabledStart,
      daysDisabledEnd,
      handleSearchClick,
      selectDate,
      handleResetClick,
    }
  },
})
