<template>
  <div class="DateRangePicker">
    <span v-if="!!label">{{ label }}</span>
    <el-date-picker
      type="daterange"
      v-model="dates"
      range-separator="~"
      start-placeholder="開始日期"
      end-placeholder="結束日期"
      :clearable="false"
      @change="handleChange"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  props: {
    range: {
      type: Object, // { startDate: moment, endDate: moment }
      required: true,
    },
    label: {
      type: String,
    },
    daysDisabledStart: {
      type: Object, // moment
      default: null,
    },
    daysDisabledEnd: {
      type: Object, // moment
      default: null,
    },
  },
  data() {
    return {
      dates: [
        _.invoke(this.range, 'startDate.toDate') || moment().toDate(),
        _.invoke(this.range, 'endDate.toDate') || moment().toDate(),
      ],
      pickerOptions: {
        disabledDate: (date) => {
          let is_disabled = false
          if (this.isDayDisabledStartAreMoment) {
            is_disabled |= moment(date).isSameOrAfter(
              this.daysDisabledStart,
              'date'
            )
          }
          if (this.isDayDisabledEndAreMoment) {
            is_disabled |= moment(date).isSameOrBefore(
              this.daysDisabledEnd,
              'date'
            )
          }

          return is_disabled
        },
      },
    }
  },
  methods: {
    handleChange() {
      this.$emit('callback', {
        startDate: moment(this.dates[0]),
        endDate: moment(this.dates[1]),
      })
    },
  },
  computed: {
    isDayDisabledStartAreMoment() {
      return moment.isMoment(this.daysDisabledStart)
    },
    isDayDisabledEndAreMoment() {
      return moment.isMoment(this.daysDisabledEnd)
    },
  },
}
</script>

<style lang="scss" scoped></style>
