import _ from 'lodash'
import { Module } from 'vuex'

export const MUTATION_SET_STATUS = 'SET_STATUS'
export const MUTATION_SET_STATUS_ITEM = 'SET_STATUS_ITEM'
export const MUTATION_DEL_STATUS_ITEM = 'DEL_STATUS_ITEM'

export const ACTION_UPDATE_STATUS_ITEM = 'updateStatusItem'

export default <S extends State<SI>, RS, SI extends object>(
  defaultStatus?: StatusMap<SI>,
  defaultStatusItem?: SI
) =>
  ({
    state: {
      status: _.cloneDeep(defaultStatus || {}),
    } as S,

    mutations: {
      [MUTATION_SET_STATUS](state, { data }: PayloadSetStatus) {
        state.status = data
      },

      [MUTATION_SET_STATUS_ITEM](state, { key, value }: PayloadSetStatusItem) {
        state.status = {
          ...(state.status || {}),
          [key]: {
            ...(state.status[key] || {}),
            ...value,
          },
        }
      },

      [MUTATION_DEL_STATUS_ITEM](state, { key }: PayloadDeleteStatusItem) {
        delete state.status[key]
      },
    },

    actions: {
      [ACTION_UPDATE_STATUS_ITEM]({ commit, state }, { key, path, value }) {
        commit(MUTATION_SET_STATUS_ITEM, {
          key,
          value: {
            ...(defaultStatusItem || {}),
            ...(state.status[key] || {}),
            [path]: value,
          },
        } as PayloadSetStatusItem)
      },
    },
  } as Module<S, RS>)

export interface State<SI extends object> {
  status: StatusMap<SI>
}

export interface StatusMap<SI extends object> {
  [key: string]: SI
}

export interface PayloadSetStatus {
  data
}

export interface PayloadSetStatusItem {
  key: string
  value
}

export interface PayloadDeleteStatusItem {
  key: string
}

export interface PayloadActionUpdateStatusItem {
  key: string
  path: string
  value
}
