



























import { defineComponent, PropType } from '@vue/composition-api'
import { ReturnOrderItem } from '../api/vendor-order-refund.types'

export default defineComponent({
  props: {
    itemList: {
      type: Array as PropType<ReturnOrderItem[]>,
      required: true,
    },
  },
  computed: {
    totalNumber() {
      return this.itemList.reduce(
        (accumulator: number, currentValue: ReturnOrderItem) => {
          return accumulator + Number(currentValue.number)
        },
        0
      )
    },
  },
})
