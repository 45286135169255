<template>
  <div>
    <div class="middle-header">
      <page-title></page-title>
      <hr />
      <item-filter @callback="searchItem"></item-filter>
      <hr />
      <div class="bar">
        <date-range-picker
          :range="range"
          label="銷售時間："
          class="date-filter"
          :days-disabled-start="daysDisabledStart"
          @callback="selectDate"
        ></date-range-picker>
        <span class="btn-download">
          <button
            class="button hollow small"
            type="button"
            @click="download"
            :disabled="disableDownloadButton"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
            下載
          </button>
        </span>
      </div>
    </div>
    <div class="page-content">
      <template v-if="!fetchError">
        <div v-if="!searched" class="page-info">
          顯示
          <span class="page-number">{{ rowInfo }}</span>
          項 ( 共 {{ totalRow }} 項 )
        </div>
        <div v-else class="page-info">
          顯示
          <span class="page-number">{{ rowInfo }}</span>
          項 ( 搜尋結果共 {{ totalRow }} 項 )
          <button
            class="button hollow small"
            type="button"
            @click="cleanSearch"
          >
            回到所有商品列表
          </button>
        </div>
      </template>

      <no-search-result v-if="!displayTable"></no-search-result>
      <no-search-result
        v-else-if="fetchError"
        text="系統異常，銷售區間請在三個月以內"
      ></no-search-result>

      <floating-head-table
        v-show="displayTable && !fetchError"
        class="table-striped table-unborder"
      >
        <thead slot="thead">
          <tr class="group-head">
            <th colspan="3">商品資訊</th>
            <th></th>
            <th></th>
            <th colspan="3">單位：新台幣元</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th :class="getSortClass('full_name')" @click="sortBy('full_name')">
              商品名稱
            </th>
            <th
              width="85"
              :class="getSortClass('item_id')"
              @click="sortBy('item_id', 'number')"
            >
              媽咪愛
              <br />
              商品編號
            </th>
            <th width="100" :class="getSortClass('upc')" @click="sortBy('upc')">
              商品貨號
            </th>
            <th width="95">
              前台
              <br />
              銷售方式
            </th>
            <th
              width="85"
              :class="getSortClass('sold_total_count')"
              @click="sortBy('sold_total_count', 'number')"
            >
              <span class="has-tip" v-tooltip="'消費者已下單且付款之商品數量'">
                銷售數量
              </span>
            </th>
            <th width="85">售價</th>
            <th width="85">進價</th>
            <th width="99">
              <span class="has-tip" v-tooltip="'銷售數量 x 售價'">
                銷售額小計
              </span>
            </th>
            <th
              width="85"
              :class="getSortClass('sold_total_amount')"
              @click="sortBy('sold_total_amount', 'number')"
            >
              銷售總額
            </th>
            <th
              width="85"
              :class="getSortClass('sellable_amount')"
              @click="sortBy('sellable_amount', 'number')"
            >
              消費者
              <br />
              可購買量
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pageItems" :key="item.item_id">
            <td>
              <div class="cell-data rowspan">{{ item.full_name }}</div>
            </td>
            <td>
              <div class="cell-data rowspan">{{ item.item_id }}</div>
            </td>
            <td>
              <div class="cell-data rowspan word-break">{{ item.upc }}</div>
            </td>
            <td>
              <div
                class="cell-data"
                v-for="(sale, index) in item.sale_data"
                :key="`sale-date-${index}`"
              >
                <div v-if="sale.promotion_date !== ''">
                  {{ sale.promotion_date }}
                </div>
                <div>
                  <span
                    v-if="sale.campaign_name == '一般'"
                    class="has-tip"
                    v-tooltip="'非活動期間'"
                  >
                    {{ sale.campaign_name }}
                  </span>
                  <span v-else>{{ sale.campaign_name }}</span>
                </div>
              </div>
            </td>
            <td>
              <div
                class="cell-data"
                v-for="(sale, index) in item.sale_data"
                :key="`total-count-${index}`"
              >
                {{ sale.total_count | thousandSign }}
              </div>
            </td>
            <td>
              <div
                class="cell-data"
                v-for="(sale, index) in item.sale_data"
                :key="`unit-price-${index}`"
              >
                {{ sale.unit_price | thousandSign }}
              </div>
            </td>
            <td>
              <div
                class="cell-data"
                v-for="(sale, index) in item.sale_data"
                :key="`unit-cost-${index}`"
              >
                {{ sale.unit_cost | thousandSign }}
              </div>
            </td>
            <td>
              <div
                class="cell-data"
                v-for="(sale, index) in item.sale_data"
                :key="`total-amount-${index}`"
              >
                {{ sale.total_amount | thousandSign }}
              </div>
            </td>
            <td>
              <div class="cell-data rowspan">
                {{ item.sold_total_amount | thousandSign }}
              </div>
            </td>
            <td>
              <div class="cell-data rowspan">
                {{ item.sellable_amount | thousandSign }}
              </div>
            </td>
          </tr>
        </tbody>
      </floating-head-table>

      <pagination
        v-if="!fetchError"
        :key="paginationKey"
        :totalRow="this.totalRow"
        @setPage="setPage"
      ></pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import request from '@/legacy/utils/request'
import PageTitle from '@/legacy/components/PageTitle/PageTitle.vue'
import ItemFilter from '@/legacy/components/ItemFilter.vue'
import DateRangePicker from '@/legacy/components/DateRangePicker.vue'
import NoSearchResult from '@/legacy/components/NoSearchResult.vue'
import Pagination from '@/legacy/components/Pagination.vue'
import SorterMixin from '@/legacy/mixins/sorter'
import FloatingHeadTable from '@/legacy/components/Table/FloatHeaderTable.vue'
import * as FileHelper from '@/legacy/utils/helper/file'

export default {
  data() {
    return {
      range: {
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'days'),
      },
      daysDisabledStart: moment().add(1, 'days'),
      items: [],
      searchedItemIds: [],
      searched: false,
      pageFirstRow: 0,
      pageLastRow: 0,
      paginationKey: 0, // change to re-render pagination
      disableDownloadButton: false,
      fetchError: false,
    }
  },

  mounted() {
    this.initSortData(
      'items',
      'sold_total_count',
      'desc',
      'number',
      'full_name'
    )
    this.fetchData()
  },

  mixins: [SorterMixin],
  watch: {
    items() {
      this.paginationKey += 1
    },
  },

  computed: {
    totalRow() {
      return this.items.length
    },
    rowInfo() {
      var text = this.pageFirstRow
      if (this.pageLastRow > 0) {
        text += ' - ' + this.pageLastRow
      }
      return text
    },
    pageItems() {
      return this.items.slice(this.pageFirstRow - 1, this.pageLastRow)
    },
    displayTable() {
      return !this.searched || this.totalRow > 0
    },
  },

  methods: {
    fetchData() {
      this.$store.dispatch('startLoading')

      request
        .post('report/sale', this.queryParams())
        .then(({ data }) => {
          this.items = data.data
          this.sortItems()
          this.fetchError = false
        })
        .catch(() => {
          this.fetchError = true
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    selectDate(range) {
      this.range = range
      this.fetchData()
    },

    searchItem(item_ids) {
      this.searched = true

      if (item_ids.length == 0) {
        this.items = []
        this.$store.dispatch('endLoading')
        return
      }

      this.searchedItemIds = item_ids
      this.fetchData()
    },

    cleanSearch() {
      this.searched = false
      this.searchedItemIds = []
      this.fetchData()
    },

    setPage(firstRow, lastRow) {
      this.pageFirstRow = firstRow
      this.pageLastRow = lastRow
    },

    queryParams() {
      return {
        item_ids: this.searchedItemIds,
        start_date: this.range.startDate.format('YYYY-MM-DD'),
        end_date: this.range.endDate.format('YYYY-MM-DD'),
      }
    },

    download() {
      this.disableDownloadButton = true

      // 4秒後恢復
      setTimeout(() => {
        this.disableDownloadButton = false
      }, 4000)

      request
        .post('report/sale_report_download', this.queryParams(), {
          responseType: 'blob',
        })
        .then((response) => {
          FileHelper.donwload(response)
        })
    },
  },

  components: {
    PageTitle,
    ItemFilter,
    NoSearchResult,
    Pagination,
    DateRangePicker,
    FloatingHeadTable,
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

table.table-unborder {
  .last-cell {
    border-right: 1px solid #d8d8d8;
  }

  tr.group-head > th {
    @extend .last-cell;

    &:last-child {
      border-right: 0;
    }
  }

  tr > td,
  th {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(8),
    &:nth-child(9) {
      @extend .last-cell;
    }
  }

  td {
    vertical-align: top;
  }

  div.cell-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 64px;

    &.rowspan {
      height: auto;
      min-height: 64px;
    }
    &.word-break {
      word-break: break-all;
    }
  }
}

.date-filter {
  display: inline;
}

.btn-download {
  float: right;
}
</style>
