<template>
  <div class="loading-wrapper" :class="{ hidden: !isLoading }">
    <Loader
      :loading="isLoading"
      :color="loaderColor"
      :size="loaderSize"
    ></Loader>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Loader from './Loader.vue'

export default {
  data() {
    return {
      loaderColor: '#FFF',
      loaderSize: '8px',
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  components: {
    Loader,
  },
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2100;
  background: rgba(248, 248, 248, 0.5);

  .v-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.hidden {
  display: none;
}
</style>
