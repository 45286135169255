import _, { Dictionary } from 'lodash'
import moment from 'moment'

import {
  FormData,
  OrderItem as FormOrderItem,
  generateOrderListByItemIsChecked,
  StatusItem,
} from './resend-list.form'
import { Data as ShippingMarkData } from '@/legacy/utils/print/render/shipping-mark'
import { ConvertToDeliveredOrderItem as ApiConvertToDeliveredOrderItem } from '@/legacy/api/shipment-manager-upn-stock/shipping.types'
import { generateOrderGroupMergeArrived } from './resend.service'
import { StatusMap } from '@/legacy/stores/mixins/form-status'

export const transformOrderMergedGroup2PreviewOrderList = (
  orderGroup: OrderGroup,
  venderName: string
) =>
  _.map(orderGroup, (orders) => {
    const { boxCount } = _.first(orders)!

    const codes = _.map(orders, 'code')

    return {
      venderName,
      orderCodes: codes,
      boxCount,
      isResend: true,
    } as ShippingMarkData
  })

export const transformFormData2PrintShippingMarkData = (
  formData: FormData,
  formStatus: StatusMap<StatusItem>,
  venderName: string
): ShippingMarkData[] => {
  const orderGroup = _.flow(
    generateOrderListByItemIsChecked,
    generateOrderGroupMergeArrived
  )(formData.orders, formStatus)

  return transformOrderMergedGroup2PreviewOrderList(orderGroup, venderName)
}

export const transformFormOrderList2ApiConvertToDeliveredOrderList = (
  orders: FormOrderItem[]
): ApiConvertToDeliveredOrderItem[] => {
  return _.map(
    orders,
    (order) =>
      ({
        order_number: order.code,
        company: order.forwarder,
        deliver_number: order.shipmentId,
        boxes_count: order.boxCount,
        estimated_arrival_date:
          order.estimateDeliveryDate &&
          moment(order.estimateDeliveryDate).format('YYYY-MM-DD').toString(),
        comment: order.comment,
        is_resend: '1',
      } as ApiConvertToDeliveredOrderItem)
  )
}

interface OrderGroup extends Dictionary<Array<FormOrderItem>> {}
