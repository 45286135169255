<template>
  <div>
    <form class="bar item-search-bar">
      <i class="fa fa-search"></i>
      &nbsp;&nbsp;依
      <select id="searchType" v-model="formData.search_type">
        <option value="item_name">品牌品名</option>
        <option value="upc">商品貨號</option>
        <option value="barcode">barcode</option>
        <option value="item_id">媽咪愛商品編號</option>
      </select>
      搜尋：
      <span v-if="searchType == 'item_name'">
        <select id="brand" v-model="formData.brand">
          <option value>請選擇品牌名稱</option>
          <option
            v-for="(brand, index) in this.brands"
            :key="`brand-${index}`"
            :value="brand"
          >
            {{ brand }}
          </option>
        </select>
        &nbsp;
        <input
          type="input"
          v-model="formData.item_name"
          placeholder="輸入商品品名或規格"
        />
      </span>
      <span v-if="searchType == 'upc'">
        <input
          type="input"
          v-model="formData.upc"
          placeholder="EX：98729 , 98217 , 19283"
        />
      </span>
      <span v-if="searchType == 'barcode'">
        <input
          type="input"
          v-model="formData.barcode"
          placeholder="EX：9319064118202 , 9319064115689"
        />
      </span>
      <span v-if="searchType == 'item_id'">
        <input
          type="input"
          v-model="formData.item_id"
          placeholder="EX：13729 , 13730 , 13731"
        />
      </span>
      <button class="button primary small" type="button" @click="search">
        查詢
      </button>
    </form>
  </div>
</template>

<script>
import * as StringHelper from '@/legacy/utils/helper/string'
import request from '@/legacy/utils/request'
import Cookie from '@/legacy/utils/cookie'

export default {
  props: {
    consignmentOnly: {
      type: Boolean,
      default: false,
    },
    isGetFilterData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {
        search_type: Cookie.get('preference_item_search_type') || 'item_name',
        consignment_only: this.consignmentOnly ? 1 : 0,
        brand: '',
        item_name: '',
        upc: '',
        barcode: '',
        item_id: '',
      },
      resultItemIds: [],
      brands: [],
    }
  },

  computed: {
    searchType() {
      return this.formData.search_type
    },

    filterData() {
      const filter = {
        search_type: this.formData.search_type,
        consignment_only: this.formData.consignment_only,
      }

      switch (this.searchType) {
        case 'item_name':
          filter.brand = this.formData.brand
          filter.item_name = this.formData.item_name
          break
        case 'upc':
          filter.upcs = StringHelper.toIds(this.formData.upc)
          break
        case 'barcode':
          filter.barcodes = StringHelper.toIds(this.formData.barcode)
          break
        case 'item_id':
          filter.item_ids = StringHelper.toIds(this.formData.item_id)
          break
      }

      return filter
    },
  },

  mounted() {
    this.fetchBrands()
  },

  watch: {
    searchType(value) {
      Cookie.set('preference_item_search_type', value, {
        expires: 90,
      })
    },
  },

  methods: {
    search() {
      if (this.isGetFilterData) {
        this.$emit('callback', this.filterData)
        return
      }

      this.$store.dispatch('startLoading')
      request
        .get('product/search', {
          params: this.filterData,
        })
        .then(({ data }) => {
          this.resultItemIds = data.data
          this.$emit('callback', this.resultItemIds)
        })
    },

    fetchBrands() {
      var url = this.consignmentOnly
        ? 'product/getConsignmentBrands'
        : 'product/getBrands'
      request.get(url).then(({ data }) => {
        this.brands = data.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.item-search-bar {
  font-size: 0.875rem;
}

input {
  width: 240px;
  height: 32px;
  vertical-align: bottom;
}

#searchType {
  width: auto;
  border-style: none;
  font-weight: 600;
}

#brand {
  width: auto;
  min-width: 240px;
}
</style>
