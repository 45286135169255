import _ from 'lodash'
import { Route } from 'vue-router'
import { ProductStatusFilterType } from '../product-status-filter'

export class ListQuery {
  constructor(private dto: ListQueryDto) {}

  public toApi = (): ListQueryDto => {
    return this.dto
  }

  public updatePage = (page: number) => {
    this.dto.page = page
  }

  public convertToRouteQuery = (): Route['query'] => {
    const listQueryDto = this.toApi()

    return _.keys(listQueryDto).reduce((acc, key) => {
      const value = listQueryDto[key]
      if (!_.isNil(value)) {
        acc[key] = value.toString()
      }
      return acc
    }, {})
  }
}

export interface ListQueryDto {
  // 請求第幾頁
  page: number

  // 一頁有多少資料
  per_page: number

  product_status?: ProductStatusFilterType

  // 搜尋 方式
  search_type?: SearchType

  // 搜尋 商品名稱
  item_name?: string

  // 搜尋 品牌 ID
  brand_id?: string

  // 搜尋 商品ID (多個使用英文逗號, 空白分開)
  item_ids?: string

  // 搜尋 廠商貨號 (多個使用英文逗號, 空白分開)
  upcs?: string

  // 搜尋 商品(國際)條碼 (多個使用英文逗號, 空白分開)
  barcodes?: string

  // 廠商 商品ID (多個使用英文逗號, 空白分開)
  vendor_item_ids?: string

  // 開團紀錄 setting ID
  setting_launch_log_id?: string

  // 排序 欄位
  // [item_id, barcode, brand_name, name, style, color, spec, size, upc, original_price, price, cost, is_tax_free, is_deliver_by_upn, has_expiry_date, shipping_fee, is_free_shipping, length, width, height, has_battery, is_liquid]
  order_by_column?: string

  // 排序 方向
  order_by_direction?: 'asc' | 'desc'

  // 商品類型
  item_type?: string
}

export enum SearchType {
  Name = 'item_name_and_brand',
  Code = 'upc',
  Barcode = 'barcode',
  MamiloveCode = 'item_id',
  VendorItemId = 'vendor_item_id',
  Group = 'campaign',
  ItemType = 'item_type',
}
