import { isNil, isEmpty, cloneDeep, filter, find } from 'lodash'
import { NavItem } from '../models/nav-item'
import { PermissionItem } from '@/legacy/api/auth/auth.types'

export class NavbarService {
  private _navs: NavItem[] = [
    {
      key: 'root',
      icon: 'fa fa-bullhorn',
      title: '最新消息',
      to: '/',
    },
    {
      key: 'shipping',
      title: '出貨管理',
      icon: 'el-icon-truck',
      items: [
        {
          key: 'shipping--root',
          title: '廠出出貨管理',
          to: '/shipping',
          rules: ['shipping.shipping-order'],
        },
        {
          key: 'shipment-upn',
          title: '出貨至媽咪愛倉庫',
          to: '/shipment-manager/upn-stock',
          rules: ['shipping.shipping-to-upn'],
        },
      ],
    },
    {
      key: 'stock',
      title: '庫存管理',
      icon: 'el-icon-takeaway-box',
      items: [
        {
          key: 'stock--virtual-stock-manage',
          title: '供貨管理',
          to: '/stock/virtual_stock_manage',
          rules: ['stock.change-virtual-stock'],
        },
        {
          key: 'stock--consignment-stock',
          title: '寄倉庫存表',
          to: '/stock/consignment_stock',
          rules: ['stock.consignment-stock'],
        },
      ],
    },
    {
      key: 'sales',
      title: '銷售狀況',
      icon: 'el-icon-goods',
      items: [
        {
          key: 'sales--sales-report',
          title: '銷售報表',
          to: '/sales/sales_report',
          rules: ['sales-report'],
        },
      ],
    },
    {
      key: 'billing',
      title: '寄倉出貨對帳表',
      icon: 'el-icon-document',
      items: [
        {
          key: 'billing--consignment-report',
          title: '寄倉出貨對帳表',
          to: '/billing/consignment_report',
          rules: ['billing.consignment-report'],
        },
      ],
    },
    {
      key: 'product-and-active-manager',
      title: '商品與活動管理',
      icon: 'el-icon-price-tag',
      items: [
        {
          key: 'product-declaration-list',
          title: '商品提報與進度',
          to: '/product-manager/product-declaration-list',
          rules: ['product.product-list'],
        },
        {
          key: 'product-manager',
          title: '商品管理',
          to: '/product-manager/product-list',
          rules: ['product.product-list'],
        },
      ],
    },
    {
      key: 'vendor_refund',
      title: '退倉管理',
      icon: 'el-icon-box',
      items: [
        {
          key: 'refund-list',
          title: '檢視退倉單',
          to: '/vendor_refund/refund-list',
          rules: ['vendor_refund.refund-list'],
        },
      ],
    },
  ]

  getNavListByPermissions(permissions: PermissionItem[]): NavItem[] | null {
    if (isNil(permissions)) {
      return null
    }

    return this.generateNavListByPermission(this._navs, permissions)
  }

  private generateNavListByPermission(
    navs: NavItem[],
    permissions: PermissionItem[]
  ): NavItem[] {
    return navs.reduce((all, _nav) => {
      const passed = this.checkNavItemPassed(_nav, permissions)
      if (!passed) {
        return all
      }

      const nav = cloneDeep(_nav)
      const isEmptyNavTo = isEmpty(nav.to)

      // 沒有子項目
      if (isEmpty(nav.items)) {
        delete nav.items
        all.push(nav)
        return all
      }

      nav.items = this.generateNavListByPermission(nav.items!, permissions)

      // 驗證過子項目後沒有子項目
      if (isEmpty(nav.items)) {
        // 有設定連結
        if (!isEmptyNavTo) {
          all.push(nav)
        }
        return all
      }

      all.push(nav)
      return all
    }, [] as NavItem[])
  }

  private checkNavItemPassed(
    nav: NavItem,
    permissions: PermissionItem[]
  ): boolean {
    if (isNil(nav.rules)) {
      return true
    }

    return (
      filter(nav.rules, (rule) => {
        const permission = find(permissions, ['name', rule])
        return !isNil(permission)
      }).length > 0
    )
  }
}
