import _ from 'lodash'
import { Module } from 'vuex'
import * as ApiAuth from '@/legacy/api/auth/auth'
import { User, Supplier, PermissionItem } from '@/legacy/api/auth/auth.types'
import { State } from './state'
import { MutationType, MutationPayload } from './mutation'
import { ActionType, ActionPayload } from './action'

export default {
  namespaced: true,

  state: {
    user: {
      data: null,
      fetching: false,
    },
    supplier: {
      current: null,
      list: null,
    },
    permissions: {
      data: null,
      fetching: false,
    },
  },

  getters: {
    user: (state): User | null => {
      return state.user.data
    },
    supplier: (state): Supplier | null => {
      return state.supplier.current
    },
    permissions: (state): PermissionItem[] | null => {
      return state.permissions.data
    },
    isLoggedIn: (state): boolean => {
      if (_.isNil(state.user.data)) return false
      return !_.isNil(state.user.data.id)
    },
  },

  mutations: {
    [MutationType.SetUserData](
      state,
      { value }: MutationPayload[MutationType.SetUserData]
    ) {
      state.user.data = value
    },
    [MutationType.SetUserFetching](
      state,
      { value }: MutationPayload[MutationType.SetUserFetching]
    ) {
      state.user.fetching = value
    },
    [MutationType.SetCurrentSupplier](
      state,
      { value }: MutationPayload[MutationType.SetCurrentSupplier]
    ) {
      state.supplier.current = value
    },
    [MutationType.SetSupplierList](
      state,
      { value }: MutationPayload[MutationType.SetSupplierList]
    ) {
      state.supplier.list = value
    },
    [MutationType.SetPermissionList](
      state,
      { value }: MutationPayload[MutationType.SetPermissionList]
    ) {
      state.permissions.data = value
    },
    [MutationType.SetPermissionFetching](
      state,
      { value }: MutationPayload[MutationType.SetPermissionFetching]
    ) {
      state.permissions.fetching = value
    },
  },

  actions: {
    async [ActionType.Init]({ commit, state }) {
      const hasUser = _.isNil(state.user.data) && !_.isNil(window.ML.user)
      const hasSupplier =
        _.isNil(state.supplier.current) && !_.isNil(window.ML.supplier)
      const hasSupplierList =
        _.isNil(state.supplier.list) && !_.isNil(window.ML.suppliers)

      hasUser &&
        commit(MutationType.SetUserData, {
          value: _.cloneDeep(window.ML.user),
        })

      hasUser &&
        hasSupplier &&
        commit(MutationType.SetCurrentSupplier, {
          value: _.cloneDeep(window.ML.supplier),
        })

      hasUser &&
        hasSupplier &&
        hasSupplierList &&
        commit(MutationType.SetSupplierList, {
          value: _.cloneDeep(window.ML.suppliers),
        })
    },
    async [ActionType.Login](
      { commit },
      { email, password }: ActionPayload[ActionType.Login]
    ) {
      try {
        commit(MutationType.SetUserFetching, {
          value: true,
        })

        const { data } = await ApiAuth.login(email, password)
        const { user, supplier } = data

        commit(MutationType.SetUserData, {
          value: user,
        })
        commit(MutationType.SetCurrentSupplier, {
          value: supplier,
        })
        commit(MutationType.SetSupplierList, {
          value: null,
        })
      } finally {
        commit(MutationType.SetUserFetching, {
          value: false,
        })
      }
    },
    async [ActionType.Logout]({ commit }) {
      try {
        commit(MutationType.SetUserFetching, {
          value: true,
        })

        window.ML.supplier = null
        commit(MutationType.SetCurrentSupplier, {
          value: null,
        })

        window.ML.suppliers = null
        commit(MutationType.SetSupplierList, {
          value: null,
        })

        window.ML.user = null
        commit(MutationType.SetUserData, {
          value: null,
        })

        await ApiAuth.logout()
      } finally {
        commit(MutationType.SetUserFetching, {
          value: false,
        })
      }
    },
    async [ActionType.SwitchSupplier](
      { commit, state },
      { id }: ActionPayload[ActionType.SwitchSupplier]
    ) {
      const supplier = _.find(state.supplier.list, ['id', id])
      if (_.isNil(supplier)) return

      await ApiAuth.switchSupplier(supplier.id)

      commit(MutationType.SetCurrentSupplier, {
        value: supplier,
      })
    },
    async [ActionType.FetchPermissions]({ commit }) {
      try {
        commit(MutationType.SetPermissionFetching, {
          value: true,
        })

        const { permission } = await ApiAuth.fetchPermissionList()
        commit(MutationType.SetPermissionList, {
          value: permission,
        })
      } finally {
        commit(MutationType.SetPermissionFetching, {
          value: false,
        })
      }
    },
  },
} as Module<State, any>

export { State as AuthState } from './state'
export {
  ActionType as AuthActionType,
  ActionPayload as AuthActionPayload,
} from './action'
export {
  MutationType as AuthMutationType,
  MutationPayload as AuthMutationPayload,
} from './mutation'
