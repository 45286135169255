import { Pagination } from '@/api/objects/Pagination.types'

export const enum SupplyStatus {
  Normal = 'normal',
  Suspend = 'suspend',
  Prohibited = 'prohibited',
}

export interface StockInfo {
  supply_status: SupplyStatus
  quantity: number
  end_date: string
}

export const enum OrderColumn {
  ItemId = 'item_id',
  Upc = 'upc',
  SalableQuantity = 'salable_quantity',
  WaitingDeliverCount = 'waiting_deliver_count',
  InStockCount = 'in_stock_count',
  SupplierWaitingDeliverCount = 'supplier_waiting_deliver_count',
  SupplierReadyToDeliverCount = 'supplier_ready_to_deliver_count',
  OnTheWayCount = 'on_the_way_count',
  Sales_14_Days = 'sales_14_days',
}

export const enum ProductSaleStatus {
  OnOffer = 'on_offer',
  InActivity = 'in_activity',
  OverSoon = 'over_soon',
  Suspend = 'suspend',
  Prohibited = 'prohibited',
}

export const enum SearchType {
  ItemNameAndBrand = 'item_name_and_brand',
  Upc = 'upc',
  Barcode = 'barcode',
  ItemId = 'item_id',
}

export interface Stock {
  full_name: string
  sales_14_days: number
  in_stock_count: number
  supply_status: SupplyStatus
  is_under_safety_stock: boolean
  is_shipping_by_vendor: boolean
  need_order_count: number
  end_date: string
  item_id: string
  notify_me_count: number
  on_the_way_count: number
  purchase_numbers: string[]
  safety_stock: number
  salable_count: number
  status_in_activity: boolean
  status_on_offer: boolean
  status_over_soon: boolean
  status_visible: boolean
  supplier_ready_to_deliver_count: number
  supply_quantity: number
  supply_quantity_bottom_line: number
  upc: string
  waiting_deliver_count: number
}

export interface UpdateError {
  value: number
  message: string
}

export interface UpdateByExcelResponse {
  data: {
    success_count: number
    failure_count: number
    update_errors: Record<string, any>
    success_item_ids: string[]
  }
}

export interface GetStocksPayload {
  barcodes?: string
  brand_id?: number
  is_shipping_by_vendor?: 0 | 1
  item_ids?: string
  item_name?: string
  order_by_column?: OrderColumn
  order_by_direction?: 'asc' | 'desc'
  page?: number
  per_page?: number
  product_sale_status?: ProductSaleStatus
  search_type?: SearchType
  upcs?: string
}

export interface GetStockResponse {
  data: Stock[]
  meta: {
    shipping_by_vendor_count: number
    shipping_by_upn_count: number
    pagination: Pagination
  }
}

export type UpdateStocksPayload = Record<string, StockInfo>

export interface UpdateStocksResponse {
  data: {
    success_count: number
    failure_count: number
    update_errors: Record<string, UpdateError>
  }
}
