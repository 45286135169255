import { RouteConfig } from 'vue-router'
import BillingConsignmentReport from '@/legacy/views/Billing/ConsignmentReport.vue'

const routes: RouteConfig[] = [
  {
    path: '/billing/consignment_report',
    meta: {
      pageTitle: '寄倉出貨對帳表',
    },
    component: BillingConsignmentReport,
  },
]

export default routes
