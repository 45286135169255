import { RouteConfig } from 'vue-router'
import RefundList from '../views/Main.vue'

export const ROUTER_NAME_REFUND_LIST = 'refund-list'

const routes: RouteConfig[] = [
  {
    name: ROUTER_NAME_REFUND_LIST,
    path: '/vendor_refund/refund-list',
    meta: {
      pageTitle: '檢視退倉單',
      breadcrumbs: [{ name: '退倉管理' }, { name: '檢視退倉單' }],
    },
    component: RefundList,
  },
]

export default routes
