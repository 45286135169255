import { NAME as SHIPMENT_UPN_STOCK_PREPARE_DELIVERY } from './shipment-upn-stock-prepare-delivery'
import { NAME as SHIPMENT_UPN_STOCK_SHIPPING_LIST } from './shipment-upn-stock-shipping-list'
import { NAME as SHIPMENT_UPN_STOCK_RESEND_LIST_NAME } from './shipment-upn-stock-resend-list'
import { NAME as SHIPMENT_UPN_STOCK_EDITOR_DELIVERED_ORDER } from './shipment-upn-stock-editor-delivered-order'

export default {
  namespaced: true,

  modules: {
    [SHIPMENT_UPN_STOCK_PREPARE_DELIVERY]:
      require('./shipment-upn-stock-prepare-delivery').default,
    [SHIPMENT_UPN_STOCK_SHIPPING_LIST]:
      require('./shipment-upn-stock-shipping-list').default,
    [SHIPMENT_UPN_STOCK_RESEND_LIST_NAME]:
      require('./shipment-upn-stock-resend-list').default,
    [SHIPMENT_UPN_STOCK_EDITOR_DELIVERED_ORDER]:
      require('./shipment-upn-stock-editor-delivered-order').default,
  },

  state: {},
}
