import _ from 'lodash'
import * as apiShipmentUpnStockPrepareDelivery from '@/legacy/api/shipment-manager-upn-stock/shipping'
import { StatusMap } from '@/legacy/stores/mixins/form-status'
import {
  FormData,
  OrderItem as FormOrderItem,
  StatusItem as FormStatusItem,
  generateOrderListByItemIsChecked,
} from './resend-list.form'
import { transformFormOrderList2ApiConvertToDeliveredOrderList } from './resend.transformer'

/**
 * 上傳補寄單
 * @param formData 表單資訊
 * @param formStatus 表單狀態
 */
export const convertToDelivered = (
  formData: FormData,
  formStatus: StatusMap<FormStatusItem>
) => {
  const orderList = _.flow(
    generateOrderListByItemIsChecked,
    transformFormOrderList2ApiConvertToDeliveredOrderList
  )(formData.orders, formStatus)

  return apiShipmentUpnStockPrepareDelivery.convertToDelivered(orderList)
}

export const generateMergeKey = (order: FormOrderItem) =>
  `${order.forwarder}-${order.shipmentId}`

export const generateOrderGroupMergeArrived = (orders: FormOrderItem[]) =>
  _.chain(orders)
    .filter((o: FormOrderItem) => {
      const isForwarderVND = o.forwarder === 'VND'
      if (isForwarderVND) {
        return true
      }

      return !(_.isEmpty(o.shipmentId) || _.isEmpty(o.forwarder))
    })
    .groupBy((o) => generateMergeKey(o))
    .value()

/**
 * 檢查合併到貨
 * @param orders 訂單列表
 * @param iteratee 走訪器
 */
export const checkOrderListMergeArrived = (
  orders: FormOrderItem[],
  iteratee: (
    currentOrderKey: string,
    parentOrderKey: string,
    isMatched: boolean
  ) => void
) => {
  const groups = generateOrderGroupMergeArrived(orders)

  _.each(orders, (order) => {
    let parentCode
    let isMatched = false

    _.each(groups, (group) => {
      // 已經被合併了
      if (isMatched) return
      parentCode = _.first(group)!.code

      _.each(group, (item) => {
        if (isMatched) return
        if (parentCode !== undefined && parentCode === item.code) return
        isMatched = order.key === item.key
      })
    })

    iteratee(order.key, parentCode, isMatched)
  })
}

/**
 * 檢查合併到貨有無勾選
 * - 全部沒被勾選
 * - 全部被勾選
 * @param orders
 * @param statusMap
 */
export const checkOrderListMergeArrivedIsChecked = (
  orders: FormOrderItem[],
  statusMap: StatusMap<FormStatusItem>
) => {
  const groups = generateOrderGroupMergeArrived(orders)
  let checked = true

  _.each(groups, (orders) => {
    if (checked === false) {
      return false
    }

    const checkeds = _.filter(orders, (order: FormOrderItem) => {
      return _.get(statusMap, `${order.key}.checked`, false)
    })

    const isNoChooseOne = checkeds.length === 0
    const isChooseAll = checkeds.length === orders.length

    checked = isNoChooseOne || isChooseAll
  })

  return checked
}
