import { useToggle } from '@vueuse/core'

export const useDialog = () => {
  const [visible, toggle] = useToggle()

  return {
    visible,
    open: () => (visible.value = true),
    close: () => (visible.value = false),
    toggle,
  }
}
