































































































































































































import { defineComponent, ref, PropType } from '@vue/composition-api'
import { updateReturnOrder } from '../api/vendor-order-refund'
import {
  ReturnOrder,
  UpdateReturnOrderPayload,
} from '../api/vendor-order-refund.types'
import ConsignmentItemListTemplate from './ConsignmentItemList.vue'
import PurchaseItemListTemplate from './PurchaseItemList.vue'

export default defineComponent({
  components: {
    ConsignmentItemListTemplate,
    PurchaseItemListTemplate,
  },
  props: {
    returnOrder: {
      type: Object as PropType<ReturnOrder>,
      required: true,
    },
  },
  setup(props, ctx) {
    const order = ref(props.returnOrder)
    const submitLoading = ref(false)
    const submit = async () => {
      submitLoading.value = true
      try {
        const payload: UpdateReturnOrderPayload = {
          order_number: order.value.order_number,
          recipient_name: order.value.recipient_name,
          recipient_phone: order.value.recipient_phone,
          recipient_email: order.value.recipient_email,
          recipient_address: order.value.recipient_address,
          is_default_address: order.value.is_default_address,
          shipping_method: order.value.shipping_method,
        }

        order.value = (await updateReturnOrder(payload)) as ReturnOrder

        ctx.root.$message({
          message: '儲存成功',
          type: 'success',
        })
      } catch (error) {
        const errors = error?.response?.data?.errors

        ctx.root.$message.error(
          errors ? errors.join('; ') : '發生錯誤，請再試一次'
        )
      } finally {
        submitLoading.value = false
      }
    }
    const returnOrderEdited = () => {
      ctx.emit('returnOrderEdited')
    }

    return {
      submit,
      submitLoading,
      order,
      returnOrderEdited,
    }
  },
})
