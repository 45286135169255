<template functional>
  <div class="DefaultLogo">
    <img
      class="DefaultLogo__img"
      src="https://images.mamilove.com.tw/static/logo-white@2x.png"
      alt="scm-logo"
    />
    <div class="DefaultLogo__title">廠商後台管理系統</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.DefaultLogo {
  display: inline-flex;
  align-items: center;

  &__img {
    width: 111px;
  }

  &__title {
    color: #fff;
    margin: 0 16px;
    font-weight: 500;
    font-size: 18px;
  }
}
</style>
