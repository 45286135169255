<template>
  <div>
    <div class="middle-header">
      <page-title></page-title>
      <hr />
      <div v-if="searchType != 'order_number'">
        <item-filter
          @callback="searchItem"
          :consignmentOnly="true"
          :key="itemFilterKey"
        ></item-filter>
        <hr />
      </div>
      <div class="bar grid-x">
        <div class="medium-8 form">
          <div class="form-text">資料顯示依</div>
          <div style="width: 145px">
            <select v-model="searchType">
              <option value="all_not_checkout">所有未請款</option>
              <option value="deliver_date">訂單出貨時間</option>
              <option value="order_number">入倉單號</option>
            </select>
          </div>
          <div v-if="searchType == 'deliver_date'" style="width: 250px">
            <date-range-picker
              :range="range"
              :daysDisabledStart="daysDisabledStart"
              @callback="selectDate"
            ></date-range-picker>
          </div>
          <div v-if="searchType == 'order_number'">
            <input
              type="input"
              v-model="searchOrderNumbers"
              placeholder="EX：C20180401-001-1 , C20180405-001-1"
              style="width: 250px; height: 32px"
            />
            <button
              class="button primary small"
              type="button"
              @click="cleanSearch"
            >
              查詢
            </button>
          </div>
        </div>
        <div class="medium-4 text-right">
          <button
            class="button hollow small"
            type="button"
            @click="download"
            :disabled="disableDownloadButton"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
            下載
          </button>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div v-if="!searched" class="page-info">共 {{ totalRow }} 項</div>
      <div v-else class="page-info">
        搜尋結果共 {{ totalRow }} 項
        <button class="button hollow small" type="button" @click="cleanSearch">
          回到所有商品列表
        </button>
      </div>

      <no-search-result v-if="noResult"></no-search-result>
      <FloatHeaderTable v-show="!noResult" class="table-striped table-unborder">
        <thead slot="thead">
          <tr class="group-head">
            <th colspan="2">訂單資訊</th>
            <th colspan="3">商品資訊</th>
            <th colspan="5"></th>
            <th></th>
          </tr>
          <tr>
            <th width="100">
              <div
                :class="getSortClass('deliver_date')"
                @click="sortBy('deliver_date')"
                style="margin-bottom: 5px"
              >
                出貨日期
              </div>
              <div
                class="color2"
                :class="getSortClass('before_check_date')"
                @click="sortBy('before_check_date')"
              >
                下單日期
              </div>
            </th>
            <th
              width="100"
              :class="getSortClass('order_id')"
              @click="sortBy('order_id')"
            >
              訂單編號
            </th>
            <th :class="getSortClass('full_name')" @click="sortBy('full_name')">
              商品名稱
            </th>
            <th
              width="85"
              :class="getSortClass('item_id')"
              @click="sortBy('item_id', 'number')"
            >
              媽咪愛
              <br />
              商品編號
            </th>
            <th width="100" :class="getSortClass('upc')" @click="sortBy('upc')">
              商品貨號
            </th>
            <th width="100">
              前台
              <br />
              銷售方式
            </th>
            <th
              width="70"
              :class="getSortClass('number')"
              @click="sortBy('number', 'number')"
            >
              數量
            </th>
            <th
              width="90"
              :class="getSortClass('unit_cost')"
              @click="sortBy('unit_cost', 'number')"
            >
              進貨價
            </th>
            <th
              width="90"
              :class="getSortClass('cost')"
              @click="sortBy('cost', 'number')"
            >
              小計
            </th>
            <th
              width="135"
              :class="getSortClass('sorter_billing_status')"
              @click="sortBy('sorter_billing_status')"
            >
              目前請款狀態
            </th>
            <th
              width="138"
              :class="getSortClass('order_number')"
              @click="sortBy('order_number')"
            >
              入倉單號
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="`item-${index}`">
            <td>
              <div :class="sortKey == 'deliver_date' ? 'color3' : ''">
                {{ item.deliver_date }}
              </div>
              <div
                :class="sortKey == 'before_check_date' ? 'color3' : 'color2'"
              >
                {{ item.before_check_date }}
              </div>
            </td>
            <td>{{ item.order_id }}</td>
            <td>{{ item.full_name }}</td>
            <td>{{ item.item_id }}</td>
            <td class="word-break">{{ item.upc }}</td>
            <td>
              <div v-if="item.promotion_date !== ''">
                {{ item.promotion_date }}
              </div>
              <div>
                <span
                  class="has-tip"
                  v-tooltip="getCampaignTip(item.campaign_name)"
                >
                  {{ item.campaign_name }}
                </span>
              </div>
            </td>
            <td>{{ item.number | thousandSign }}</td>
            <td>{{ item.unit_cost | thousandSign }}</td>
            <td>{{ item.cost | thousandSign }}</td>
            <td>
              <div>{{ item.billing_status_text }}</div>
              <div v-if="item.billing_number !== ''">
                {{ item.billing_number }}
              </div>
            </td>
            <td>{{ item.order_number }}</td>
          </tr>
        </tbody>
      </FloatHeaderTable>
      <div v-show="!noResult" id="total_bar" class="grid-x">
        <div class="medium-5"></div>
        <div class="medium-1">
          <strong>出貨時間</strong>
        </div>
        <div class="medium-2">{{ searchDateText }}</div>
        <div class="medium-1">
          <strong>未請款總金額</strong>
        </div>
        <div class="medium-1">
          {{ totalCanBillingAmount | autoFix | thousandSign }} 元
        </div>
        <div class="medium-1">
          <strong>已請款總金額</strong>
        </div>
        <div class="medium-1">
          {{ totalBilledAmount | autoFix | thousandSign }} 元
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import qs from 'query-string'
import request from '@/legacy/utils/request'

import PageTitle from '@/legacy/components/PageTitle/PageTitle.vue'
import ItemFilter from '@/legacy/components/ItemFilter.vue'
import DateRangePicker from '@/legacy/components/DateRangePicker.vue'
import NoSearchResult from '@/legacy/components/NoSearchResult.vue'
import SorterMixin from '@/legacy/mixins/sorter'
import FloatHeaderTable from '@/legacy/components/Table/FloatHeaderTable.vue'

export default {
  data() {
    return {
      searchType: 'all_not_checkout',
      searchOrderNumbers: '',
      range: {
        startDate: moment().subtract(1, 'months').startOf('month'),
        endDate: moment().subtract(1, 'months').endOf('month'),
      },
      daysDisabledStart: moment().add(1, 'days'),
      items: [],
      itemFilterKey: 0,
      searchedItemIds: [],
      searched: false,
      disableDownloadButton: false,
    }
  },

  mounted() {
    this.initSortData(
      'items',
      'deliver_date',
      'asc',
      'string',
      'sorter_second_key'
    )
    this.fetchData()
  },

  mixins: [SorterMixin],

  watch: {
    searchType(value) {
      if (value != 'order_number') {
        this.fetchData()
      }
    },
  },

  computed: {
    totalRow() {
      return this.items.length
    },
    noResult() {
      return (
        this.totalRow == 0 &&
        (this.searched || this.searchType == 'deliver_date')
      )
    },
    totalCanBillingAmount() {
      return this.items.reduce(
        (acc, item) =>
          (acc += item.billing_status === 'can_billing' ? item.cost : 0),
        0
      )
    },
    totalBilledAmount() {
      return this.items.reduce(
        (acc, item) =>
          (acc += item.billing_status === 'billed' ? item.cost : 0),
        0
      )
    },
    searchDateText() {
      if (this.searchType == 'deliver_date') {
        return (
          this.range.startDate.format('YYYY/M/D') +
          '-' +
          this.range.endDate.format('YYYY/M/D')
        )
      } else {
        return '所有未請款'
      }
    },
  },

  methods: {
    fetchData() {
      this.$store.dispatch('startLoading')
      request
        .get('billing/consignment_detail', {
          params: this.queryParams(),
        })
        .then(({ data }) => {
          this.items = data.data
          this.sortItems()
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },

    selectDate(range) {
      this.range = range
      this.fetchData()
    },

    searchItem(item_ids) {
      this.searched = true

      if (item_ids.length == 0) {
        this.items = []
        this.$store.dispatch('endLoading')
        return
      }

      this.searchedItemIds = item_ids
      this.fetchData()
    },

    refreshItemFilter() {
      this.itemFilterKey += 1
    },

    cleanSearch() {
      this.searched = false
      this.searchedItemIds = []
      this.refreshItemFilter()
      this.fetchData()
    },

    getCampaignTip(campaign_name) {
      switch (campaign_name) {
        case '一般':
          return '出貨商品為非活動期間售出'
        case '異常':
          return '因貨運公司, 盤差及報廢等特殊因素產生之數量（30天後可向媽咪愛結帳）'
        case '促銷':
          return '出貨商品為活動期間售出'
        case '退貨':
          return '出貨商品已退回上恩'
        default:
          return ''
      }
    },

    queryParams() {
      return {
        item_ids: this.searchedItemIds,
        search_type: this.searchType,
        start_date: this.range.startDate.format('YYYY-MM-DD'),
        end_date: this.range.endDate.format('YYYY-MM-DD'),
        order_numbers: this.searchOrderNumbers,
      }
    },

    download() {
      this.disableDownloadButton = true

      // 4秒後恢復
      setTimeout(() => {
        this.disableDownloadButton = false
      }, 4000)

      const url = 'billing/consignment_detail_download'
      const query = qs.stringify(this.queryParams(), {
        arrayFormat: 'bracket',
      })

      location.href = `${url}?${query}`
    },
  },

  components: {
    PageTitle,
    ItemFilter,
    NoSearchResult,
    DateRangePicker,
    FloatHeaderTable,
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';
@import '@/legacy/styles/_settings.scss';
@import '@/legacy/styles/_table.scss';
@import '@/legacy/styles/_tableSorting.scss';

table.table-unborder {
  .last-cell {
    border-right: 1px solid #d8d8d8;
  }

  tr.group-head > th {
    @extend .last-cell;

    &:last-child {
      border-right: 0;
    }
  }

  tr > td,
  th {
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(10),
    &:nth-child(11) {
      @extend .last-cell;
    }
  }

  th {
    div {
      padding: 0;
      margin: 0;
    }
  }
}

.form {
  font-size: 0.875rem;

  div {
    display: inline-block;
    padding-right: 5px;

    &.form-text {
      color: #aab2c0;
    }
  }
}

#total_bar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 64px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);

  font-size: 0.875rem;

  div {
    margin: auto;
  }
}

.color2 {
  color: rgba(51, 51, 51, 0.5);

  &.sorting_asc,
  &.sorting_desc {
    color: $sorting-color;
  }
}

.color3 {
  color: $sorting-color;
}
</style>
