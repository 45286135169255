import { RouteConfig } from 'vue-router'
import VerifyEmail from '../views/Auth/VerifyEmail.vue'
import LoginForm from '../views/Auth/LoginForm.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ResetForm from '../views/Auth/ResetForm.vue'

export const ROUTER_NAME_LOGIN_PAGE = 'login'
export const ROUTER_NAME_VERIFY_EMAIL = 'verify-email'
export const ROUTER_NAME_RESET_PASSWORD = 'reset-password'
export const ROUTER_NAME_FORGOT_PASSWORD = 'forgot-password'

const routes: RouteConfig[] = [
  {
    name: ROUTER_NAME_VERIFY_EMAIL,
    path: '/verify-email/:token',
    component: VerifyEmail,
  },
  {
    name: ROUTER_NAME_LOGIN_PAGE,
    path: '/login',
    component: LoginForm,
  },
  {
    name: ROUTER_NAME_FORGOT_PASSWORD,
    path: '/password/reset',
    component: ForgotPassword,
  },
  {
    name: ROUTER_NAME_RESET_PASSWORD,
    path: '/password/reset_form/:token',
    component: ResetForm,
  },
]

export default routes
