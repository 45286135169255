import _ from 'lodash'
import cuid from 'cuid'
import { FormOrderItem } from './types/order'
import { RuleMap, RuleItem } from '@/legacy/stores/mixins/form-validate'
import { StatusMap } from '@/legacy/stores/mixins/form-status'
import { transformOrderMergedGroup2PreviewOrderList } from './preview-order.transformer'
import { generateOrderGroupMergeArrived } from './shipping.service'

export const validateRules: RuleMap = {
  forwarder: [
    {
      required: true,
      message: '物流公司 必填',
    },
  ],
  shipmentId: [
    {
      required: true,
      message: '貨運編號 必填',
    },
    {
      pattern: /^\w+$/,
      message: '勿填 -/.@*',
    },
  ],
  boxCount: [
    {
      required: true,
      message: '箱數 必填',
    },
    {
      type: 'number',
      message: '箱數 為數字',
    },
  ],
  estimateDeliveryDate: [
    {
      type: 'date',
      required: true,
      message: '預計到貨日 必填',
      trigger: 'change',
    },
  ],
}

export const generateValidateOrderItemKey = (
  orderKey: string,
  validateField: string
) => `${orderKey}.${validateField}`

/**
 * 建立預設的表單資訊
 */
export const generateDefaultFormData = (
  orders?: FormOrderItem[]
): FormData => ({
  orders: orders || [],
})

/**
 * 建立預設的表單狀態項目
 */
export const generateDefaultFormStatusItem = (): StatusItem => ({
  checked: false,
  overdued: false,
  merge: undefined,
  parent: undefined,
})

/**
 * 建立預設的子訂單項目
 * @param target 目標訂單項目
 */
export const generateDefaultSubFormOrderItem = (
  target: FormOrderItem
): FormOrderItem => ({
  key: cuid(),
  code: target.code,
  createdDate: target.createdDate,
  estimateDeliveryDate: target.estimateDeliveryDate,
  forwarder: null,
  shipmentId: null,
  boxCount: null,
  comment: null,
})

export const generateOrderListByItemIsChecked = (
  orders: OrderItem[],
  status: StatusMap<StatusItem>
): OrderItem[] =>
  _.reduce<OrderItem, OrderItem[]>(
    orders,
    (all, order) => {
      const state = status[order.key]
      if (!state) return all
      if (!state.checked) return all

      all.push(order)
      return all
    },
    [] as OrderItem[]
  )

export const generatePreviewOrderList = (
  status: StatusMap<StatusItem>,
  orders: OrderItem[]
) =>
  _.flow(
    generateOrderListByItemIsChecked,
    generateOrderGroupMergeArrived,
    transformOrderMergedGroup2PreviewOrderList
  )(orders, status)

/**
 * 建立已勾選訂單項目的驗證參數
 * @param status 表單的狀態
 * @param orders normalized訂單列表
 */
export const generateValidationListFromOrderItemIsChecked = (
  status: StatusMap<StatusItem>,
  orders: OrderMap
) => {
  return _.flow(
    // 已勾選的訂單key
    (s) =>
      Object.keys(s).reduce((all, key) => {
        const value = s[key]
        if (value.checked === true) {
          all.push(key)
        }
        return all
      }, [] as string[]),

    // 已勾選的訂單項目
    (s) =>
      s.reduce((all, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (orders.hasOwnProperty(key)) {
          all.push(orders[key])
        }
        return all
      }, [] as OrderItemWithIndex[]),

    // [驗證[]]
    (orders) =>
      orders.map((order) => {
        // 當前訂單項目是合併的
        const isMergeArrived = !_.isEmpty(status[order.key].merge)
        // 廠商親自送貨
        const isForwarderVND = order.forwarder === 'VND'

        return _.reduce(
          validateRules,
          (all, rules, field) => {
            // 合併的到貨的訂單不需要驗證的欄位
            if (
              isMergeArrived &&
              (field === 'boxCount' || field === 'estimateDeliveryDate')
            ) {
              return all
            }

            if (isForwarderVND && field === 'shipmentId') {
              return all
            }

            // 此驗證規則是否有對應得值
            if (!_.has(order, field)) return all

            const value = _.get(order, field)
            const path = generateValidateOrderItemKey(order.key, field)

            all.push({ rules, value, path })

            return all
          },
          [] as { rules: RuleItem[]; value: any; path: string }[]
        )
      }),

    // 驗證 []
    (validates) => _.flatten(validates)
  )(status)
}

export interface OrderItem {
  /** 唯一key */
  key: string

  /** 訂單編號 */
  code: string

  /** 訂貨時間 */
  createdDate: Date

  /** 預計到貨時間 */
  estimateDeliveryDate: Date

  /** 貨運商 key */
  forwarder: string | null

  /** 貨運編號 */
  shipmentId: string | null

  /** 箱數 */
  boxCount: number | null

  /** 備註 */
  comment: string | null
}

export interface OrderItemWithIndex extends OrderItem {
  index: number
}

export interface OrderMap {
  [key: string]: OrderItemWithIndex
}

export interface FormData {
  orders: OrderItem[]
}

export interface StatusItem {
  checked: boolean
  overdued: boolean
  merge?: string
  parent?: string
}
