<template>
  <transition name="popup">
    <div class="popup-mask" @click="$emit('close')">
      <div class="popup-wrapper">
        <div class="popup-container" @click.stop>
          <div class="popup-header">
            <span class="title">匯入備貨量 EXCEL</span>
            <a
              class="el-button el-button--default sampleBtn button small"
              :href="downloadExcelUrl"
              target="_blank"
            >
              下載 EXCEL 範本
            </a>
          </div>
          <div class="popup-body">
            <p>請選擇要匯入的 Excel 檔案</p>
            <input
              ref="file"
              type="file"
              id="import_file"
              accept=".xlsx"
              @change="handleUpload"
            />
            <button class="button small" @click="pickFile">選擇檔案</button>
            <span class="file-name" v-if="showUpload">{{ fileName }}</span>
          </div>
          <div class="popup-footer">
            <button
              class="closeBtn button hollow radius"
              @click="$emit('close')"
            >
              取消
            </button>
            <button
              class="button secondary radius"
              @click="$emit('upload')"
              :disabled="!showUpload"
            >
              匯入
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    downloadExcelUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileName: '',
      showUpload: false,
    }
  },

  methods: {
    pickFile() {
      this.$refs.file.click()
    },

    handleUpload(e) {
      const files = e.target.files
      const file = files[0]
      this.fileName = file.name
      this.showUpload = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';

input[type='file'] {
  display: none;
}

.file-name {
  margin-left: 12px;
  font-weight: bold;
}

.popup-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.popup-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.popup-container {
  max-width: 710px;
  min-height: 396px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.popup-header {
  height: 85px;
  border-bottom: solid 1px $light-gray;
  line-height: 85px;
  position: relative;

  .title {
    margin-left: 2em;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .sub-title {
    cursor: pointer;
    color: #169bd5;
    text-decoration: underline;
  }

  i {
    margin-right: 23px;
    cursor: pointer;
  }

  .sampleBtn {
    position: absolute;
    right: 45px;
    top: 25px;
  }
}

.popup-body {
  height: 217px;
  padding: 35px;

  .title {
    display: block;
    text-align: left;
    font-weight: bold;
  }

  .description {
    text-align: left;
  }
}

.popup-footer {
  height: 94px;
  line-height: 94px;
  text-align: right;
  padding: 8px 55px;
  box-shadow: 0 -6px 6px -5px rgba(0, 0, 0, 0.16);

  .closeBtn {
    margin-right: 12px;
    border: 1px solid $dark-gray;
  }
}

.popup-enter {
  opacity: 0;
}

.popup-leave-active {
  opacity: 0;
}

.popup-enter .popup-container,
.popup-leave-active .popup-container {
  transform: scale(1.1);
}
</style>
