


































import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import DefaultLogo from './DefaultLogo.vue'
import { Supplier } from '@/legacy/api/auth/auth.types'
import { AuthActionType } from '@/legacy/stores/modules/auth'
import { ROUTE_NAME_PRODUCT_LIST } from '@/product-manager/routes/base'

@Component({
  components: {
    DefaultLogo,
  },
})
export default class DefaultHeader extends Vue {
  get supplierList(): Supplier[] {
    return this.$store.state.auth.supplier.list
  }

  get supplier(): Supplier {
    return this.$store.getters['auth/supplier']
  }

  get isEmptySupplierList() {
    return _.isEmpty(this.supplierList)
  }

  async handleSupplierSelectorChange(id: string) {
    await this.$store
      .dispatch(`auth/${AuthActionType.SwitchSupplier}`, {
        id,
      })
      .catch(() => {
        this.$message.error('切換廠商時出現錯誤')
      })

    // 商品列表
    if (this.$route.name === ROUTE_NAME_PRODUCT_LIST) {
      this.$router.push({
        name: ROUTE_NAME_PRODUCT_LIST,
      })
    }
  }

  handleLogoutClick() {
    this.$store.dispatch(`auth/${AuthActionType.Logout}`).then(() => {
      this.$router.push('/login')
    })
  }
}
