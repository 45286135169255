import { isEmpty } from 'lodash'
import { Module } from 'vuex'

import * as ApiBusinessLineGroup from '@/legacy/api/commons/business-line-group'
import { BusinessBranchItem } from '@/legacy/api/commons/business-line-group.types'

export const NAME = 'BUSINESS_LINE_GROUP'
export const MUTATION_SET_DATA = 'SET_DATA'
export const MUTATION_SET_FETCHING = 'SET_FETCHING'
export const ACTION_FETCH_BUSINESS_LINE_GROUP_LIST =
  'fetchBusinessLineGroupList'

export default {
  namespaced: true,

  state: {
    fetching: false,
    data: [],
  },

  mutations: {
    [MUTATION_SET_DATA](state, { data }: PayloadSetData) {
      state.data = data
    },

    [MUTATION_SET_FETCHING](state, { value }: PayloadSetFetching) {
      state.fetching = value
    },
  },

  actions: {
    async [ACTION_FETCH_BUSINESS_LINE_GROUP_LIST](
      { commit, state },
      payload: PayloadFetchData
    ) {
      const forced = payload ? payload.forced : false
      if (
        forced === false &&
        (state.fetching === true || !isEmpty(state.data))
      ) {
        return
      }

      commit(MUTATION_SET_FETCHING, {
        value: true,
      } as PayloadSetFetching)

      const { data } = await ApiBusinessLineGroup.list()

      commit(MUTATION_SET_DATA, {
        data,
      } as PayloadSetData)

      commit(MUTATION_SET_FETCHING, {
        value: false,
      } as PayloadSetFetching)
    },
  },
} as Module<State, {}>

export interface State {
  fetching: boolean
  data: BusinessBranchItem[]
}

export interface PayloadSetData {
  data: BusinessBranchItem[]
}

export interface PayloadSetFetching {
  value: boolean
}

export interface PayloadFetchData {
  forced: boolean
}
