





































































































































































































































































































































































import _ from 'lodash'
import qs from 'query-string'
import {
  defineComponent,
  ref,
  computed,
  nextTick,
  watch,
} from '@vue/composition-api'
import { useEventListener, useWindowScroll } from '@vueuse/core'
import { useMessageBox, useRoute, useRouter, useStore } from '@/composition-api'
import { ElTable } from 'element-ui/types/table'
import { useStocksData } from './composition-api/useStocksData'
import { useStockForm } from './composition-api/useStockForm'
import { ElForm } from 'element-ui/types/form'
import { ElPagination } from 'element-ui/types/pagination'

import BreadCrumb from '@/common/components/BreadCrumb/BreadCrumb.vue'
import PageHeader from '@/common/components/PageHeader/PageHeader.vue'
import QueryFilter from './components/QueryFilter.vue'
import EditDateModal from './components/EditDateModal.vue'
import ImportModal from './components/ImportModal.vue'

import * as apiStock from '@/legacy/api/stock/stock'

export default defineComponent({
  components: {
    PageHeader,
    QueryFilter,
    ImportModal,
    BreadCrumb,
    EditDateModal,
  },
  setup(props, ctx) {
    const { $alert } = ctx.root
    const store = useStore<any>()
    const router = useRouter()
    const route = useRoute()
    const { y: scrollY } = useWindowScroll()
    const { $confirm } = useMessageBox()
    const {
      stocks,
      shippingType,
      tabs,
      statusOptions,
      searched,
      pagination,
      totalRow,
      rowInfo,
      getQuantityInputErrorTooltip,
      changeShippingType,
      getStockList,
      isStockProhibited,
      isStockSuspend,
    } = useStocksData()
    const {
      form: stockForm,
      showEditDateModal,
      editStockId,
      isEdited,
      initStockForm,
      handleChangeStockStatus,
      handleChangeToSuspend,
      handleChangeQuantity,
      isQuantityDisabled,
      isActionPunished,
      showQuantityPunishMessage,
      getDropdownDetail,
    } = useStockForm()
    const showPhoto = ref(true)
    const showImportModal = ref(false)
    const tableMaxHeight = ref(516)
    const stockFormRef = ref<ElForm | null>(null)
    const paginationRef = ref<ElPagination | null>(null)
    const stockTableRef = ref<ElTable | null>(null)
    const isSortUpdateByRouteGuard = ref(false)
    const defaultSort = {
      prop: route.value.query.order_by_column,
      order:
        route.value.query.order_by_direction === 'asc'
          ? 'ascending'
          : 'descending',
    }
    const downloadExcelUrl = computed(() => {
      const url = '/stock/download_virtual_stock_list'
      const query = qs.stringify(route.value.query)
      const isShippingByVendor = shippingType.value === 'vendor' ? '1' : '0'

      return `${url}?is_shipping_by_vendor=${isShippingByVendor}&${query}`
    })
    const tableRefSortProp = computed(() => {
      // @ts-ignore
      return stockTableRef.value?.store?._data?.states?.sortProp
    })
    const tableRefSortOrder = computed(() => {
      // @ts-ignore
      return stockTableRef.value?.store?._data?.states?.sortOrder
    })

    const fixTableLayout = () => {
      stockTableRef.value?.doLayout()
    }

    const getForSort = (prop_a, prop_b) => {
      if (typeof prop_a === typeof prop_b && typeof prop_b === 'string') {
        return prop_a.localeCompare(prop_b, undefined, { numeric: true })
      } else {
        return prop_a - prop_b
      }
    }

    const getStocksAndInitForm = async () => {
      await getStockList(route.value.query)
      const prop = '' + route.value.query.order_by_column
      const order = '' + route.value.query.order_by_direction

      if (order === 'desc') {
        stocks.value = stocks.value.sort((a, b) => getForSort(b[prop], a[prop]))
      } else {
        stocks.value = stocks.value.sort((a, b) => getForSort(a[prop], b[prop]))
      }
      initStockForm(stocks.value)
      nextTick(() => {
        fixTableLayout()
      })
    }

    const showUpdateMessage = async (data) => {
      const successMessage = `${data.success_count} 項商品成功更新`
      const errorMessage =
        data.failure_count > 0 ? `<br>${data.failure_count} 項商品更新失敗` : ''
      const errorDetail = _.reduce(
        data.update_errors,
        (result, error, key) => {
          result += `<br>商品 ID:${key} ${error.message}`
          return result
        },
        ''
      )

      await $alert(`${successMessage}${errorMessage}${errorDetail}`, {
        dangerouslyUseHTMLString: true,
      }).catch((r) => r)
    }

    const submit = () => {
      stockFormRef.value?.validate(async (isValid) => {
        if (!isValid) return

        const payload = stockForm.value.createPayload(stocks.value)
        if (isActionPunished(stocks.value, payload)) {
          const action = await $confirm(
            '部分商品修改後將影響客訂數量，依合約可能產生對應罰則。是否仍確認送出？',
            '警告',
            {
              confirmButtonText: '取消',
              cancelButtonText: '確認儲存',
              confirmButtonClass: 'is-round',
              cancelButtonClass: 'is-round',
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
            }
          ).catch((action) => action)

          if (action === 'confirm') return
        }

        try {
          store.dispatch('startLoading')
          const data = await apiStock.updateStocks(payload)
          if (data.failure_count === 0) {
            await getStocksAndInitForm()
          }
          store.dispatch('endLoading')
          await showUpdateMessage(data)
        } catch (e) {
          store.dispatch('endLoading')
          await $alert(e, { dangerouslyUseHTMLString: true }).catch((r) => r)
        }
      })
    }

    const uploadFile = async () => {
      showImportModal.value = false
      store.dispatch('startLoading')

      const fileInput = document.querySelector<HTMLInputElement>('#import_file')

      try {
        const data = await apiStock.updateStockByExcel(fileInput!.files?.[0])
        store.dispatch('endLoading')
        await showUpdateMessage(data)

        router.push({
          query: {
            is_shipping_by_vendor: '1',
            page: '1',
            search_type: 'item_id',
            item_ids: data.success_item_ids.join(','),
          },
        })
      } catch (error) {
        store.dispatch('endLoading')
        $alert(error.error_message)
      }
    }

    const handleSorting = ({ prop, order }) => {
      if (isSortUpdateByRouteGuard.value) return

      router.push({
        query: {
          ...route.value.query,
          order_by_column: prop,
          order_by_direction: order === 'ascending' ? 'asc' : 'desc',
          page: '1',
        },
      })
    }

    const handleChangePage = (page) => {
      router.push({
        query: {
          ...route.value.query,
          page,
        },
      })
    }

    const handleTabChange = (activeName) => {
      if (activeName !== shippingType.value) return false
    }

    const changeTableMaxHeight = () => {
      nextTick(() => {
        tableMaxHeight.value = window.innerHeight - 170
      })
    }

    watch(() => route.value.query, getStocksAndInitForm, {
      deep: true,
      immediate: true,
    })

    watch(tableRefSortOrder, (order, oldOrder) => {
      const orderMatchQueryMap = {
        ascending: 'asc',
        descending: 'desc',
      }

      // el-table sort 的狀態 和 query 不一致，導致錯誤 UI 顯示
      if (orderMatchQueryMap[order] !== route.value.query.order_by_direction) {
        isSortUpdateByRouteGuard.value = true
        stockTableRef.value?.sort(tableRefSortProp.value, oldOrder) // this will trigger handleSorting
        isSortUpdateByRouteGuard.value = false
      }
    })

    watch(tableRefSortProp, (prop, oldProp) => {
      // el-table sort 的狀態 和 query 不一致，導致錯誤 UI 顯示
      if (prop !== route.value.query.order_by_column) {
        isSortUpdateByRouteGuard.value = true
        stockTableRef.value?.sort(oldProp, tableRefSortOrder.value) // this will trigger handleSorting
        isSortUpdateByRouteGuard.value = false
      }
    })

    useEventListener(window, 'beforeunload', (e) => {
      if (!isEdited.value) return

      e.returnValue = ''
    })

    useEventListener(window, 'resize', () => {
      changeTableMaxHeight()
    })

    return {
      paginationRef,
      stockFormRef,
      stockTableRef,
      showPhoto,
      showImportModal,
      showEditDateModal,
      editStockId,
      stocks,
      stockForm,
      statusOptions,
      downloadExcelUrl,
      defaultSort,
      shippingType,
      tabs,
      isEdited,
      searched,
      pagination,
      totalRow,
      rowInfo,
      scrollY,
      tableMaxHeight,

      submit,
      uploadFile,
      handleChangeStockStatus,
      handleChangeToSuspend,
      handleChangeQuantity,
      handleChangePage,
      handleSorting,
      handleTabChange,
      changeShippingType,
      isStockProhibited,
      isStockSuspend,
      isQuantityDisabled,
      getQuantityInputErrorTooltip,
      getDropdownDetail,
      showQuantityPunishMessage,
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (!this.isEdited) {
      next()
    } else {
      const answer = window.confirm('不會儲存商品資料喔！確定要離開嗎？')

      // pagination 的 internalCurrentPage 和 current_page 不一致，導致錯誤的 active 樣式
      if (to.query.page !== from.query.page) {
        // @ts-ignore
        this.$refs.paginationRef.internalCurrentPage =
          this.pagination?.current_page
      }
      answer ? next() : next(false)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isEdited) {
      next()
    } else {
      const answer = window.confirm('不會儲存商品資料喔！確定要離開嗎？')
      answer ? next() : next(false)
    }
  },
})
