import { User, Supplier, PermissionItem } from '@/legacy/api/auth/auth.types'

export enum MutationType {
  SetUserData = 'SET_USER_DATA',
  SetUserFetching = 'SET_USER_FETCHING',
  SetCurrentSupplier = 'SET_CURRENT_SUPPLIER',
  SetSupplierList = 'SET_SUPPLIER_LIST',
  SetPermissionList = 'SET_PERMISSION_LIST',
  SetPermissionFetching = 'SET_PERMISSION_FETCHING',
}

export interface MutationPayload {
  [MutationType.SetUserData]: {
    value: User
  }
  [MutationType.SetUserFetching]: {
    value: boolean
  }
  [MutationType.SetCurrentSupplier]: {
    value: Supplier
  }
  [MutationType.SetSupplierList]: {
    value: Supplier[]
  }
  [MutationType.SetPermissionList]: {
    value: PermissionItem[]
  }
  [MutationType.SetPermissionFetching]: {
    value: boolean
  }
}
