<template>
  <transition name="popup">
    <div class="popup-mask" @click="$emit('close')">
      <div class="popup-wrapper">
        <div class="popup-container" @click.stop>
          <div class="popup-header grid-x">
            <div class="medium-8">
              <span class="title">上傳貨運編號</span>
              或
              <span class="sub-title" @click="$emit('gotoEditForm')">
                使用介面輸入貨運編號
              </span>
            </div>
            <div class="medium-4 text-right">
              <i
                class="fa fa-times"
                aria-hidden="true"
                @click="$emit('close')"
              ></i>
            </div>
          </div>
          <div class="popup-body grid-x">
            <div class="medium-6">
              <img
                src="https://images.mamilove.com.tw/scm/shipping/import_sample.png"
                alt="import sample"
              />
            </div>
            <div class="medium-6">
              <span class="title">如左側範例所示：</span>
              <p class="description">
                於您的出貨單，在「訂單編號」右側
                <br />
                填入「物流公司」與「貨運編號」的欄位資訊後，上傳檔案。
              </p>
              <input
                ref="file"
                type="file"
                id="import_file"
                accept=".xlsx"
                @change="handleUpload"
              />
              <button class="button hollow secondary" @click="pickFile">
                <i class="fa fa-upload" aria-hidden="true"></i>
                上傳檔案
              </button>
            </div>
          </div>
          <div class="text-center" v-if="showUpload">
            <div>
              檔案名稱：
              <span class="file-name">{{ fileName }}</span>
            </div>
            <button class="button warning" @click="$emit('upload')">
              送出
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      fileName: '',
      showUpload: false,
    }
  },

  methods: {
    pickFile() {
      this.$refs.file.click()
    },

    handleUpload(e) {
      const files = e.target.files
      const file = files[0]
      this.fileName = file.name
      this.showUpload = true
    },
  },
}
</script>

<style lang="scss" scoped>
input[type='file'] {
  display: none;
}

.file-name {
  color: blue;
  font-weight: bold;
}

.button {
  &.warning {
    font-size: 1.3rem;
    padding: 0.8rem 8rem;
  }
}

.popup-mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.popup-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.popup-container {
  max-width: 738px;
  min-height: 363px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.popup-header {
  height: 60px;
  border-bottom: solid 1px;
  line-height: 60px;
  .title {
    margin-left: 2em;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .sub-title {
    cursor: pointer;
    color: #169bd5;
    text-decoration: underline;
  }

  i {
    margin-right: 23px;
    cursor: pointer;
  }
}

.popup-body {
  padding: 35px;
  text-align: center;

  .title {
    display: block;
    text-align: left;
    font-weight: bold;
  }

  .description {
    text-align: left;
  }

  button {
    width: 100%;
  }
}

.popup-enter {
  opacity: 0;
}

.popup-leave-active {
  opacity: 0;
}

.popup-enter .popup-container,
.popup-leave-active .popup-container {
  transform: scale(1.1);
}
</style>
