import { has, isNil } from 'lodash'
import { AxiosError } from 'axios'
import { ApiErrorResponse } from './types/api-error-response'

export function isAxiosResponseError<T = any>(
  error: AxiosError | any
): error is AxiosError<T> {
  if (!error.isAxiosError) return false
  if (!has(error, 'response')) return false
  if (!has(error, 'request')) return false
  if (!has(error, 'config')) return false
  if (isNil(error.config.url)) return false
  if (!error.config.url) return false

  return true
}

export function isApiErrorResponse(
  response: ApiErrorResponse | any
): response is ApiErrorResponse {
  if (!has(response, 'data.error_message')) return false

  return true
}
