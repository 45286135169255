<template>
  <h1 class="PageTitle">
    <slot>{{ title }}</slot>
  </h1>
</template>

<script>
export default {
  computed: {
    title() {
      let routes = this.$route.matched
      let titles = []
      routes.forEach(function (route) {
        if (route.meta && route.meta.pageTitle) {
          titles.push(route.meta.pageTitle)
        }
      })
      return titles.join(' - ')
    },
  },
}
</script>

<style lang="scss" scoped>
.PageTitle {
  padding: 20px 40px;
  font-weight: 600;
  font-size: 32px;
  color: #f6798e;
  margin: 0;
}
</style>
