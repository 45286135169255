import * as types from '../mutation-types'

const state = {
  isLoading: false,

  flashMessage: {
    type: 'info',
    message: null,
  },
}

const getters = {
  isLoading: (state) => state.isLoading,

  flashMessage: (state) => state.flashMessage,
}

const actions = {
  startLoading({ commit }) {
    commit(types.BEFORE_LOADING)
  },

  endLoading({ commit }) {
    commit(types.AFTER_LOADING)
  },

  flash({ commit }, payload) {
    if (typeof payload === 'string') {
      payload = {
        message: payload,
      }
    }
    const obj = Object.assign(
      {
        type: 'info',
      },
      payload
    )

    commit(types.SET_FLASH_MESSAGE, obj)
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  flashError({ commit }, message) {
    this.dispatch('flash', {
      message: message,
      type: 'error',
    })
  },

  clearFlash({ commit }) {
    commit(types.CLEAR_FLASH_MESSAGE)
  },
}

const mutations = {
  [types.BEFORE_LOADING](state) {
    state.isLoading = true
  },

  [types.AFTER_LOADING](state) {
    state.isLoading = false
  },

  [types.SET_FLASH_MESSAGE](state, flash) {
    state.flashMessage = {
      type: flash.type,
      message: flash.message,
    }
  },

  [types.CLEAR_FLASH_MESSAGE](state) {
    state.flashMessage = {
      type: 'info',
      message: null,
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
