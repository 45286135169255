export enum ActionType {
  Init = 'init',
  Login = 'login',
  Logout = 'logout',
  SwitchSupplier = 'switchSupplier',
  FetchPermissions = 'fetchPermissions',
}

export interface ActionPayload {
  [ActionType.Login]: {
    email: string
    password: string
  }
  [ActionType.SwitchSupplier]: {
    id: string
  }
}
