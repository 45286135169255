<template>
  <div v-if="totalRow > 0" class="bar text-center">
    <a
      class="pagination"
      :class="{ disabled: currentPage <= 1 }"
      @click="setPage(currentPage - 1)"
    >
      &lt;
    </a>

    <template v-for="n in totalPage">
      <a
        class="pagination"
        :key="`pagination-${n}`"
        :class="{ active: n == currentPage }"
        v-if="showPage(n)"
        @click="setPage(n)"
      >
        {{ n }}
      </a>
      <span v-else class="page-more" :key="`pagination-${n}`">...</span>
    </template>

    <a
      class="pagination"
      :class="{ disabled: currentPage >= totalPage }"
      @click="setPage(currentPage + 1)"
    >
      &gt;
    </a>
  </div>
</template>

<script>
export default {
  props: {
    perPageRow: {
      type: Number,
      default: 20,
    },

    totalRow: {
      type: Number,
      default: 0,
      require: true,
    },
  },

  data() {
    return {
      currentPage: 1,
    }
  },

  computed: {
    totalPage() {
      return Math.ceil(this.totalRow / this.perPageRow)
    },
    pageFirstRow() {
      if (this.totalRow == 0) {
        return 0
      }
      return (this.currentPage - 1) * this.perPageRow + 1
    },
    pageLastRow() {
      return Math.min(this.currentPage * this.perPageRow, this.totalRow)
    },
  },

  mounted() {
    this.setPage(1)
  },

  methods: {
    setPage(n) {
      n = Math.min(this.totalPage, n)
      n = Math.max(1, n)
      this.currentPage = n
      this.$emit('setPage', this.pageFirstRow, this.pageLastRow)
    },

    showPage(n) {
      if (n <= 1) {
        return true
      }
      if (n >= this.totalPage) {
        return true
      }
      if (n >= this.currentPage - 2 && n <= this.currentPage + 2) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin-left: 3px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
  color: #333333;
  background-color: #ffffff;
  border: solid 1px #c6cbd4;

  &.active {
    color: #ffffff;
    background-color: #333333;
  }

  &.disabled {
    opacity: 0.24;
    cursor: default;
  }
}

.page-more + .page-more {
  display: none;
}
</style>
