import request from '@/legacy/utils/request'
import {
  ReturnOrder,
  SearchReturnOrdersResponse,
  UpdateReturnOrderPayload,
} from './vendor-order-refund.types'

export const searchReturnOrders = async (
  startDate: string,
  endDate: string
): Promise<ReturnOrder[]> => {
  const response = await request.get<SearchReturnOrdersResponse>(
    'vendor-order-refund?start_date=' + startDate + '&end_date=' + endDate
  )
  return response.data.data
}

export const updateReturnOrder = async (
  payload: UpdateReturnOrderPayload
): Promise<ReturnOrder> => {
  const { data } = await request.put<ReturnOrder>(
    '/vendor-order-refund/' + payload.order_number,
    payload
  )
  return data
}
