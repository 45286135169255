import _, { Dictionary } from 'lodash'
import { OrderItem as ShippingFormOrderItem } from './shipping-list.form'
import { OrderItem as ResendFormOrderItem } from './resend-list.form'

interface OrderGroup
  extends Dictionary<Array<ShippingFormOrderItem | ResendFormOrderItem>> {}

export const transformOrderMergedGroup2PreviewOrderList = (
  orderGroup: OrderGroup
) =>
  _.map(orderGroup, (orders) => {
    const { estimateDeliveryDate, forwarder, shipmentId, boxCount, comment } =
      _.first(orders)!

    const isForwarderVND = forwarder === 'VND'

    return {
      keys: _.map(orders, 'key'),
      codes: _.map(orders, 'code'),
      estimateDeliveryDate,
      forwarder,
      shipmentId: isForwarderVND ? null : shipmentId,
      boxCount,
      comment,
    } as PreviewOrderItem
  })

export interface PreviewOrderItem {
  keys: string[]
  codes: string[]
  estimateDeliveryDate: Date
  forwarder: string
  shipmentId: string
  boxCount: number
  comment: string
}
