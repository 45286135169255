<template>
  <div class="callout alert-message h5" :class="color">
    <i class="fa fa-info-circle" aria-hidden="true"></i>
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      default: 'info',
      validator: (value) => {
        const acceptType = ['info', 'error']
        return acceptType.indexOf(value) !== -1
      },
    },
  },

  computed: {
    color() {
      switch (this.type) {
        case 'error':
          return 'red'
        default:
          return 'blue'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';

.alert-message {
  padding: 0.4rem;
  background-color: #f8f8f8;

  .fa-info-circle {
    margin-left: 1rem;
    font-size: 0.9rem;
  }

  &.blue {
    color: $secondary-color;
  }

  &.red {
    color: $alert-color;
  }
}
</style>
