<template>
  <div>
    <ul class="tabs hide-for-print">
      <router-link
        class="tabs-title"
        :to="{ path: '/shipping', params: { title: '轉為出貨中' } }"
        tag="li"
        active-class="is-active"
        exact
      >
        <a href>待出貨 ({{ readyToShippingOrderCount }})</a>
      </router-link>
      <router-link
        class="tabs-title"
        :to="{ path: '/shipping/prepare_delivery', params: { title: '出貨' } }"
        tag="li"
        active-class="is-active"
      >
        <a href>出貨中 ({{ prepareDeliverOrderCount }})</a>
      </router-link>
      <router-link
        class="tabs-title"
        to="/shipping/delivered_orders"
        tag="li"
        active-class="is-active"
      >
        <a href>已出貨</a>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('shipping/fetchOrderCount')
  },

  computed: {
    readyToShippingOrderCount() {
      return this.$store.getters['shipping/readyToShippingOrderCount']
    },

    prepareDeliverOrderCount() {
      return this.$store.getters['shipping/prepareDeliverOrderCount']
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/foundation';

$tab-color: black;
$tab-item-font-size: 1rem;
$tab-item-padding: 0.7rem 3rem;
$background-active: #f2f2f2;
$color-active: #5aa0d5;
.tabs {
  @include clearfix;

  margin: 0.8rem 0;
  border-bottom: 1px solid #5899d3;
  list-style-type: none;
  .tabs-title {
    @include tabs-title;

    &.is-active {
      position: relative;
      > a {
        background: $background-active;
        color: $color-active;
        font-weight: bold;
        &::after {
          @include css-triangle(0.4rem, $color-active, up);

          position: absolute;
          bottom: 0;
          left: calc(50% - 0.25rem);
        }
      }
    }
  }
}
</style>
