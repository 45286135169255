import moment from 'moment'
import Vue from 'vue'

Vue.filter('formatToLocalDate', function (value) {
  if (value) {
    const utcDatetime = moment.utc(value)
    const localDatetime = utcDatetime.local()
    return localDatetime.format('YYYY-MM-DD')
  }
})

Vue.filter('formatToLocalDateTime', function (value) {
  if (value) {
    const utcDatetime = moment.utc(value)
    const localDatetime = utcDatetime.local()
    return localDatetime.format('YYYY-MM-DD HH:mm:ss')
  }
})

Vue.filter('thousandSign', function (value) {
  value += ''
  const x = value.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
})

Vue.filter('autoFix', function (value) {
  if (value % 1 === 0) {
    return value
  } else {
    return Math.round(value * 100) / 100
  }
})
