import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'
import { multiguard } from '@/legacy/utils/router'

import '@/libs/modified-vue-router'

import GuardAuth from '@/legacy/routes/guards/auth'
import GuardGuest from '@/legacy/routes/guards/guest'
import GuardBillboard from '@/legacy/routes/guards/billboard'
import Prepare from '@/core/views/Prepare.vue'
import LayoutNoAuth from '@/legacy/layouts/NoAuth.vue'
import DefaultLayout from '@/layout/DefaultLayout.vue'

import auth from '@/legacy/routes/auth'
import home from '@/legacy/routes/home'
import shipping from '@/legacy/routes/shipping'
import shippingToUpn from '@/legacy/routes/shipment-manager-upn'
import stock from '@/legacy/routes/stock'
import sales from '@/legacy/routes/sales'
import billing from '@/legacy/routes/billing'
import productManager from '@/product-manager/routes/base'
import RefundList from '@/vendor_refund/routes/refund-list'

class Bootstrap {
  _store: Store<any>

  _router: VueRouter

  _app: Vue

  constructor() {
    this.applyBeforeCreate()

    this._store = this.createStore()
    this._router = this.createRouter()
    this._app = this.createApp()

    this.applyCreated()

    this._app.$mount('#app')
  }

  applyBeforeCreate() {
    Vue.config.productionTip = false

    Vue.use(Vuex)
    Vue.use(VueRouter)
    Vue.use(VueCompositionAPI)

    require('@/legacy/plugins/fundaction-ui')
    require('@/legacy/plugins/element-ui')
    require('@/legacy/plugins/v-tooltip')
    require('@/legacy/plugins/filters')
  }

  applyCreated() {
    const request = require('@/legacy/utils/request').default
    require('@/legacy/utils/request/interceptors/response-auth-exception').default(
      request,
      this._router,
      this._store
    )
  }

  createApp() {
    const Root = require('@/legacy/views/Root.vue').default

    const app = new Vue({
      ...Root,
      router: this._router,
      store: this._store,
    })

    return app
  }

  createStore() {
    const store = new Vuex.Store({
      modules: {
        auth: require('@/legacy/stores/modules/auth').default,
        common: require('@/legacy/stores/modules/commons/main').default,
        utils: require('@/legacy/stores/modules/utils').default,
        billboard: require('@/legacy/stores/modules/billboard').default,
        shipping: require('@/legacy/stores/modules/shipping').default,
        form: require('@/legacy/stores/modules/forms/form').default,
      },
    })

    return store
  }

  createRouter() {
    const router = new VueRouter({
      mode: 'hash',
      base: '/',
      linkActiveClass: 'active',
      scrollBehavior() {
        return { x: 0, y: 0 }
      },
      routes: [
        {
          path: '/',
          beforeEnter: multiguard([
            GuardAuth(this._store),
            GuardBillboard(this._store),
          ]),
          component: Prepare,
          children: [
            {
              path: '/',
              component: DefaultLayout,
              children: [
                ...home,
                ...shipping,
                ...shippingToUpn,
                ...stock,
                ...sales,
                ...billing,
                ...productManager,
                ...RefundList,
              ],
            },
          ],
        },
        {
          path: '/',
          beforeEnter: GuardGuest(this._store),
          component: LayoutNoAuth,
          children: [...auth],
        },
      ],
    })

    return router
  }
}

new Bootstrap()
