const checkQueyrSelectorScopeSupport = () => {
  try {
    document.querySelector(':scope body')
    return true
  } catch (error) {
    return false
  }
}

const parseScopeSelector = (selector: string, target: HTMLElement) => {
  let _id: string = ''
  let clear = () => {}

  if (/(^|,)\s*:scope/.test(selector) && !checkQueyrSelectorScopeSupport()) {
    _id = target.id
    target.id = 'ID_' + Date.now()
    selector = selector.replace(/((^|,)\s*):scope/g, '$1#' + target.id)
    clear = () => _id && (target.id = _id)
  } else {
    selector = ''
  }

  return {
    selector,
    clear,
  }
}

export const querySelectorByTarget = (
  selector: string,
  target: HTMLElement
) => {
  const { selector: _selector, clear } = parseScopeSelector(selector, target)
  const result = _selector
    ? document.querySelector(_selector)
    : target.querySelector(selector)
  clear()
  return result
}

export const querySelectorAllByTarget = (
  selector: string,
  target: HTMLElement
) => {
  const { selector: _selector, clear } = parseScopeSelector(selector, target)
  const result = _selector
    ? document.querySelectorAll(_selector)
    : target.querySelectorAll(selector)
  clear()
  return result
}
