import request from '../../utils/request'
import { ResponsePostList, ResponseAttachList } from './post.types'

export const list = (): Promise<ResponsePostList> => {
  return request
    .get<ResponsePostList>('/billboard/posts')
    .then((res) => res.data)
}

export const single = (id: number): Promise<ResponseAttachList> => {
  return request
    .get<ResponseAttachList>(`/billboard/posts/${id}`)
    .then((res) => res.data)
}
