import _ from 'lodash'
import { Module } from 'vuex'

import {
  validate,
  RuleItem,
  ValidateError,
  ValidateMessage,
} from '@/legacy/stores/mixins/form-validate'
import FormCoreMixin, {
  State as FormCoreState,
  ACTION_UPDATE_DATA_FIELD,
  PayloadActionUpdateDataField,
} from '../../mixins/form-core'
import FormOriginalMixin, {
  State as FormOriginalState,
} from '../../mixins/form-original'
import FormStatusMixin, {
  State as FormStatusState,
  ACTION_UPDATE_STATUS_ITEM,
  PayloadActionUpdateStatusItem,
  MUTATION_DEL_STATUS_ITEM,
  PayloadDeleteStatusItem,
} from '../../mixins/form-status'
import FormValidateMixin, {
  State as FormValidateState,
  MUTATION_SET_VALIDATE_ITEM,
  PayloadSetValidateItem,
} from '../../mixins/form-validate'
import {
  FormData,
  generateDefaultFormData,
  StatusItem,
  generateDefaultFormStatusItem,
  generateDefaultOrderItem,
  generateValidationListFromOrderItemIsChecked,
  OrderMap as FormOrderMap,
  OrderItemWithIndex as FormOrderItemWithIndex,
  OrderItem as FormOrderItem,
} from '@/legacy/views/ShipmentUpnStock/domain/resend-list.form'

/** 表單名稱 */
export const NAME = 'SHIPMENT_UPN_STOCK_RESEND_LIST'

/** ACTION-新增預設的訂單項目 */
export const ACTION_ADD_DEFAULT_ORDER_ITEM = 'addDefaultOrderItem'

/** ACTION-刪除訂單項目 */
export const ACTION_DEL_ORDER_ITEM = 'deleteOrderItem'

/** ACTION-更新訂單項目欄位 */
export const ACTION_UPDATE_ORDER_ITEM_FIELD = 'updateOrderItemField'

/** ACTION-驗證訂單項目內的欄位 */
export const ACTION_VALIDATE_ORDER_ITEM_FIELD = 'validateOrderItemField'

/** ACTION-驗證訂單項目被勾選的 */
export const ACTION_VALIDATE_ORDER_ITEM_FOR_CHECKED =
  'validateOrderItemForChecked'

/** ACTION-更新訂單合併狀態 */
export const ACTION_UPDATE_ORDER_ITEM_MERGE_STATUS =
  'updateOrderItemMergeStatus'

/** MUTATION-增加訂單項目 */
export const MUTATION_APPEND_ORDER_ITEM = 'APPEND_ORDER_ITEM'

/** MUTATION-移除訂單項目 */
export const MUTATION_REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM'

/** ACTION-更新全部的勾選狀態 */
export const ACTION_UPDATE_All_ORDER_ITEM_CHECKED_STATUS =
  'updateAllOrderItemCheckedStatus'

const transformOrdersNormalizePath2KeyField = (path: string) => {
  const matches = path.match(/([^\.]+)\.(\S+)/)
  // if (matches.length < 3) return;
  if (!matches) return null
  return {
    key: matches[1],
    field: matches[2],
  }
}

const main = {
  namespaced: true,

  mutations: {
    [MUTATION_APPEND_ORDER_ITEM](state, { order }: PayloadAddOrderItem) {
      state.data.orders.push(order)
    },

    [MUTATION_REMOVE_ORDER_ITEM](state, { index }: PayloadRemoveOrderItem) {
      state.data.orders.splice(index, 1)
    },
  },

  actions: {
    [ACTION_UPDATE_ORDER_ITEM_FIELD](
      { dispatch, getters },
      { path, value }: PayloadActionUpdateOrderItemField
    ) {
      const normalizedPath = transformOrdersNormalizePath2KeyField(path)
      if (!normalizedPath) return
      const { key, field } = normalizedPath

      const index = _.get(getters.normalizedOrderList, `${key}.index`, -1)
      if (index < 0) return

      dispatch(ACTION_UPDATE_DATA_FIELD, {
        path: `orders[${index}].${field}`,
        value,
      } as PayloadActionUpdateDataField)
    },

    [ACTION_ADD_DEFAULT_ORDER_ITEM]({ commit, dispatch }) {
      const defaultOrderItem = generateDefaultOrderItem()

      commit(MUTATION_APPEND_ORDER_ITEM, {
        order: defaultOrderItem,
      } as PayloadAddOrderItem)

      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key: defaultOrderItem.key,
        path: 'checked',
        value: false,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_DEL_ORDER_ITEM](
      { commit, getters },
      { key }: PayloadActionDeleteOrderItem
    ) {
      const orders: FormOrderMap = getters.normalizedOrderList
      // eslint-disable-next-line no-prototype-builtins
      if (!orders.hasOwnProperty(key)) return

      const index = orders[key].index
      if (index < 0) return

      commit(MUTATION_REMOVE_ORDER_ITEM, {
        index,
      } as PayloadRemoveOrderItem)

      commit(MUTATION_DEL_STATUS_ITEM, {
        key,
      } as PayloadDeleteStatusItem)
    },

    async [ACTION_VALIDATE_ORDER_ITEM_FIELD](
      { commit, getters },
      { path, rules }: PayloadActionValidateOrderItemField
    ) {
      const normalizedPath = transformOrdersNormalizePath2KeyField(path)
      if (!normalizedPath) return
      const { key, field } = normalizedPath
      const orders: FormOrderMap = getters.normalizedOrderList
      if (!_.has(orders, `${key}.${field}`)) return

      const validated = await validate(
        rules,
        _.get(orders, `${key}.${field}`),
        path
      )

      commit(MUTATION_SET_VALIDATE_ITEM, {
        key: path,
        message: validated,
      } as PayloadSetValidateItem)
    },

    async [ACTION_VALIDATE_ORDER_ITEM_FOR_CHECKED]({ state, commit, getters }) {
      const validations = generateValidationListFromOrderItemIsChecked(
        state.status,
        getters.normalizedOrderList
      )

      const errors: ValidateMessage[] = []

      const validateds = await Promise.all(
        _.map(validations, (v) => validate(v.rules, v.value, v.path))
      )

      _.each(validateds, (validated) => {
        if (validated.state === 'error') errors.push(validated)
        commit(MUTATION_SET_VALIDATE_ITEM, {
          key: validated.field,
          message: validated,
        } as PayloadSetValidateItem)
      })

      if (!_.isEmpty(errors)) throw new ValidateError()
    },

    [ACTION_UPDATE_ORDER_ITEM_MERGE_STATUS](
      { dispatch },
      { key, value }: PayloadActionUpdateOrderItemMergeStatus
    ) {
      dispatch(ACTION_UPDATE_STATUS_ITEM, {
        key,
        path: 'merge',
        value,
      } as PayloadActionUpdateStatusItem)
    },

    [ACTION_UPDATE_All_ORDER_ITEM_CHECKED_STATUS](
      { dispatch, state },
      { checked }: PayloadActionUpdateAllOrderItemCheckedStatus
    ) {
      _.each(state.data.orders, (order) => {
        dispatch(ACTION_UPDATE_STATUS_ITEM, {
          key: order.key,
          path: 'checked',
          value: checked,
        } as PayloadActionUpdateStatusItem)
      })
    },
  },

  getters: {
    normalizedOrderList(state): FormOrderMap {
      return _.chain(state.data.orders)
        .map(
          (order, index) => _.assign(order, { index }) as FormOrderItemWithIndex
        )
        .keyBy('key')
        .value()
    },

    checkedOrderCount(state) {
      return _.reduce(
        state.status,
        (count, status) => {
          if (status.checked === true) {
            count += 1
          }

          return count
        },
        0
      )
    },

    orderCount(state) {
      return state.data.orders.length
    },
  },
} as Module<State, {}>

export default _.merge(
  FormCoreMixin(generateDefaultFormData()),
  FormOriginalMixin(generateDefaultFormData()),
  FormStatusMixin({}, generateDefaultFormStatusItem()),
  FormValidateMixin({}),
  main
)

export interface State
  extends FormCoreState<FormData>,
    FormOriginalState<FormData>,
    FormStatusState<StatusItem>,
    FormValidateState<{}> {}

export interface PayloadAddOrderItem {
  order: FormOrderItem
}

export interface PayloadRemoveOrderItem {
  index: number
}

export interface PayloadActionUpdateOrderItemField {
  path: string
  value
}

export interface PayloadActionValidateOrderItemField {
  path: string
  rules: RuleItem[]
}

export interface PayloadActionDeleteOrderItem {
  key: string
}

export interface PayloadActionUpdateOrderItemMergeStatus {
  key: string
  /** 合併的父Key */
  value: string
}

export interface PayloadActionUpdateAllOrderItemCheckedStatus {
  checked: boolean
}
