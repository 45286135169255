



































































import { FunctionalComponentOptions } from 'vue/types/options'

export default {
  functional: true,
} as FunctionalComponentOptions<Props>

export interface Props {}
