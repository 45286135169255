





































import { Vue, Component } from 'vue-property-decorator'
import { AuthActionType } from '@/legacy/stores/modules/auth'

@Component
export default class LoginForm extends Vue {
  email: string = ''
  password: string = ''

  async handleLoginSubmit() {
    try {
      await this.$store.dispatch(`auth/${AuthActionType.Login}`, {
        email: this.email,
        password: this.password,
      })

      await this.$nextTick()

      window.location.href = '/'
    } catch (error) {
      this.$alert('登入失敗，請確認帳密是否正確')
    }
  }
}
