import { RouteConfig } from 'vue-router'
import ConsignmentStock from '@/legacy/views/Stock/ConsignmentStock.vue'
import StockManagement from '@/legacy/views/Stock/StockManagement.vue'

export const ROUTER_NAME_VIRTUAL_STOCK_MANAGE = 'virtual-stock-manage'
export const ROUTER_NAME_CONSIGNMENT_STOCK = 'consignment-stock'

const routes: RouteConfig[] = [
  {
    name: ROUTER_NAME_CONSIGNMENT_STOCK,
    path: '/stock/consignment_stock',
    meta: {
      pageTitle: '寄倉庫存表',
    },
    component: ConsignmentStock,
  },
  {
    name: ROUTER_NAME_VIRTUAL_STOCK_MANAGE,
    path: '/stock/virtual_stock_manage',
    meta: {
      breadcrumbs: [{ name: '庫存管理' }, { name: '供貨管理' }],
    },
    component: StockManagement,
  },
]

export default routes
