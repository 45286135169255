<template>
  <div class="login-form">
    <h3 class="title text-center">重新設定您的密碼</h3>

    <label>E-Mail：{{ email }}</label>

    <form v-on:submit.prevent="changePassword">
      <div>
        <label for="password">密碼</label>

        <input
          id="password"
          type="password"
          class="form-control"
          v-model="password"
          minlength="6"
          required
        />
      </div>

      <div>
        <label for="passwordConfirm">確認密碼</label>

        <input
          id="passwordConfirm"
          type="password"
          class="form-control"
          v-model="passwordConfirmation"
          minlength="6"
          required
        />
      </div>

      <button class="button secondary large login-btn" type="submit">
        完成
      </button>
    </form>
  </div>
</template>

<script>
import request from '@/legacy/utils/request'

export default {
  data() {
    return {
      token: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    }
  },
  watch: {
    $route() {
      this.setParam()
    },
  },

  mounted() {
    this.setParam()
  },

  methods: {
    setParam() {
      this.email = this.$route.query.email
      this.token = this.$route.params.token
    },

    changePassword() {
      this.$store.dispatch('startLoading')
      request
        .post('password/reset', {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        })
        .then(() => {
          this.$alert('修改完成，請使用新的密碼進行登入')
          this.$router.push('/login')
        })
        .catch((error) => {
          const response = error.response
          if (response.status === 422) {
            if (response.data.password) {
              this.$alert(response.data.password[0])
              return
            }
            if (response.data.error_message) {
              this.$alert(response.data.error_message)
              return
            }
          }
          this.$alert('重設密碼錯誤，請洽管理員')
          return
        })
        .finally(() => {
          this.$store.dispatch('endLoading')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/legacy/styles/_settings.scss';

.login-form {
  .title {
    font-weight: bold;
    margin-bottom: 30px;
  }

  width: 367px;
  height: 428px;
  background-color: $light-gray;
  margin: 10rem auto;
  padding: 36px;

  label {
    font-weight: bold;
  }

  .login-btn {
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
  }

  .forgot-btn {
    cursor: pointer;
  }
}
</style>
