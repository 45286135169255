import { Store } from 'vuex'
import { NavigationGuard } from 'vue-router'
import Cookie, { URL_INTENDED } from '@/legacy/utils/cookie'

export default <S>(store: Store<S>): NavigationGuard =>
  (to, from, next) => {
    // 未登入
    if (!store.getters['auth/isLoggedIn']) {
      next()
      return
    }

    // 已登入 && 有 url 轉跳
    if (Cookie.has(URL_INTENDED)) {
      next(Cookie.getOnce(URL_INTENDED))
      return
    }

    // 預設行為
    next('/')
  }
