



























import { useEventListener } from '@vueuse/core'
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  onBeforeUnmount,
} from '@vue/composition-api'
import PageHeader from '@/common/components/PageHeader/PageHeader.vue'
import BreadCrumb from '@/common/components/BreadCrumb/BreadCrumb.vue'
import QueryFilter from '../components/QueryFilter.vue'
import ReturnOrderTemplate from '../components/ReturnOrder.vue'
import { searchReturnOrders } from '../api/vendor-order-refund'
import { ReturnOrder } from '../api/vendor-order-refund.types'

interface QueryFilterInstance {
  handleSearchClick(): any
}

const isEdited = ref(false)
const confirmLeave = () => {
  return window.confirm('有資料尚未儲存，確定要離開嗎？')
}
const confirmStayInDirtyForm = () => {
  return isEdited.value && !confirmLeave()
}

export default defineComponent({
  components: {
    PageHeader,
    BreadCrumb,
    QueryFilter,
    ReturnOrderTemplate,
  },
  setup() {
    const filterRef: Ref<QueryFilterInstance | null> = ref(null)
    const loading = ref(false)
    const returnOrders: Ref<Array<ReturnOrder>> = ref([])
    const beforeWindowUnload = (e) => {
      if (confirmStayInDirtyForm()) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    useEventListener(window, 'beforeunload', (e) => {
      if (!isEdited.value) return

      e.returnValue = ''
    })

    onMounted(() => {
      if (filterRef.value) {
        filterRef.value.handleSearchClick()
      }
      window.addEventListener('beforeunload', beforeWindowUnload)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', beforeWindowUnload)
    })

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    })
    const getReturnOrders = async (startDate: string, endDate: string) => {
      loading.value = true
      returnOrders.value = (await searchReturnOrders(
        startDate,
        endDate
      )) as ReturnOrder[]
      returnOrders.value.sort((a, b) => {
        return -collator.compare(a.order_number, b.order_number)
      })
      loading.value = false
    }
    const setIsEdited = () => {
      isEdited.value = true
    }

    return {
      filterRef,
      loading,
      getReturnOrders,
      returnOrders,
      isEdited,
      setIsEdited,
      confirmStayInDirtyForm,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!confirmStayInDirtyForm()) {
      next()
    } else {
      next(false)
    }
  },
})
