import _ from 'lodash'

import FormMixin, { State as FormState } from '../../mixins/form-core'
import {
  FormData,
  generateDefaultFormData,
} from '@/legacy/views/ShipmentUpnStock/domain/prepare-delivery.form'
import { Module } from 'vuex'

export const NAME = 'SHIPMENT_UPN_STOCK_PREPARE_DELIVERY'

const main = {
  namespaced: true,

  getters: {
    checkedCount(state) {
      return state.data.orders.length
    },
  },
} as Module<State, {}>

export default _.merge(FormMixin(generateDefaultFormData()), main)

export interface State extends FormState<FormData> {}
