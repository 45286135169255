import request from '@/legacy/utils/request'
import {
  ResponseOrderList,
  ConvertToDeliveredData,
  ResponseConvertToDelivered,
  ConvertToDeliveredOrderItem,
  ResponseCount,
} from './shipping.types'

/**
 * 出貨中訂單列表
 * url: [GET]/vendor-order/delivering_orders
 */
export const orders = (): Promise<ResponseOrderList> => {
  return request
    .get<ResponseOrderList>('/vendor-order/delivering_orders')
    .then(({ data }) => data)
}

/**
 * 出貨中 => 已出貨
 * url: [POST]/vendor-order/delivered
 */
export const convertToDelivered = (
  orderList: ConvertToDeliveredOrderItem[]
): Promise<ResponseConvertToDelivered> => {
  const data: ConvertToDeliveredData = {
    data: orderList,
  }

  return request
    .post<ResponseConvertToDelivered>('/vendor-order/delivered', data)
    .then(({ data }) => {
      if (!data || !data.result) throw data

      return data
    })
}

/**
 * 訂單數量
 * url: [GET]/vendor-order/delivering_orders_count
 */
export const count = (): Promise<ResponseCount> => {
  return request
    .get<ResponseCount>('/vendor-order/delivering_orders_count')
    .then(({ data }) => data)
}
