import _ from 'lodash'
import request from '@/legacy/utils/request'
import { isAxiosResponseError } from '@/legacy/utils/asserts'
import {
  GetStocksPayload,
  GetStockResponse,
  UpdateStocksPayload,
  UpdateStocksResponse,
  UpdateByExcelResponse,
} from './stock.type'

export const getStocks = async (
  payload?: GetStocksPayload,
  productOrderType?: string
) => {
  try {
    const params = {
      ..._.pickBy(payload, (v) => !_.isNil(v)),
      product_order_type: productOrderType,
    }
    const { data } = await request.get<GetStockResponse>(
      'stock/fetch_virtual_stock',
      { params }
    )
    return data
  } catch (error) {
    if (isAxiosResponseError(error)) {
      return Promise.reject(error.response?.data)
    } else {
      throw error
    }
  }
}

export const updateStocks = async (form: UpdateStocksPayload) => {
  try {
    const { data } = await request.put<UpdateStocksResponse>(
      'stock/reset_virtual_stock',
      {
        item_ids: form,
      }
    )
    return data.data
  } catch (error) {
    if (isAxiosResponseError(error)) {
      return Promise.reject(error.response?.data)
    } else {
      throw error
    }
  }
}

export const updateStockByExcel = async (file) => {
  const formData = new FormData()
  formData.append('import_file', file)
  try {
    const { data } = await request.post<UpdateByExcelResponse>(
      'stock/reset_virtual_stock_by_excel',
      formData
    )
    return data.data
  } catch (error) {
    if (isAxiosResponseError(error)) {
      return Promise.reject(error.response?.data)
    } else {
      throw error
    }
  }
}
