<template>
  <InfoMessage
    v-if="message"
    :message="message"
    :type="messageType"
  ></InfoMessage>
</template>

<script>
import InfoMessage from './components/InfoMessage.vue'
export default {
  computed: {
    message() {
      return this.$store.getters['flashMessage'].message
    },

    messageType() {
      return this.$store.getters['flashMessage'].type
    },
  },

  components: {
    InfoMessage,
  },
}
</script>

<style scoped></style>
