


































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { NavItem } from '@/legacy/layouts/Header/models/nav-item'

@Component({})
export default class DefaultNavbar extends Vue {
  @Prop({ type: Array })
  navList!: NavItem[]

  isCollapse = false

  paths: string[] = []

  get activeMenuPath() {
    const matchedPath = this.paths.find((path) => {
      return this.$route.path.startsWith(path) && path !== '/'
    })

    return matchedPath || '/'
  }

  @Watch('navList', { immediate: true, deep: true })
  onNavListChanged() {
    this.paths = []

    this.navList.forEach((navItem) => {
      if (navItem.to) {
        this.paths.push(navItem.to as string)
      }

      if (navItem.items) {
        navItem.items.forEach((navSubItem) => {
          if (navSubItem.to) {
            this.paths.push(navSubItem.to as string)
          }
        })
      }
    })
  }

  isOnNavChildrenRoute(navItem: NavItem) {
    return navItem.items?.map((item) => item.to)?.includes(this.activeMenuPath)
  }

  subMenuIconClass(nav: NavItem) {
    const iconClass = [nav.icon, 'nav-icon']

    if (nav.icon === 'el-icon-price-tag') {
      iconClass.push('rotate')
    }

    if (this.isOnNavChildrenRoute(nav) && this.isCollapse) {
      iconClass.push('is-active')
    }

    return iconClass
  }
}
