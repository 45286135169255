<template>
  <el-dialog
    custom-class="BillboardPostDetailDialog"
    :visible.sync="visible"
    :show-close="false"
    @open="handleOpen"
    @closed="handleClosed"
  >
    <div slot="title" class="BillboardPostDetailDialog__title-container">
      <span class="title">{{ subject }}</span>
      <span class="extra">{{ date }}</span>
    </div>

    <div
      class="BillboardPostDetailDialog__body-container"
      v-loading="isFetching"
    >
      <span class="content">{{ content }}</span>
    </div>

    <div slot="footer" class="BillboardPostDetailDialog__footer-container">
      <div class="BillboardPostDetailDialog__footer-container__attach-list">
        <a
          v-for="(attach, index) in attaches"
          :key="`attach-item-${index}`"
          :href="attach.url"
          target="_blank"
        >
          <el-button type="primary" round>{{ attach.title }}</el-button>
        </a>
      </div>
      <el-button round @click="handleCustomCloseButtonClick">關閉</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    id: {
      type: Number,
    },
  },

  data() {
    return {
      visible: false,
    }
  },

  computed: {
    ...mapState({
      detail: (state) => state.billboard.detail,
    }),
    isFetching() {
      return this.detail.fetching
    },
    subject() {
      return this.detail.data ? this.detail.data.subject : '載入中...'
    },
    date() {
      return this.detail.data && this.detail.data.updated_at
    },
    content() {
      return this.detail.data && this.detail.data.content
    },
    attaches() {
      return this.detail.data && this.detail.data.attaches
    },
  },

  methods: {
    handleCustomCloseButtonClick() {
      this.visible = false
    },
    handleOpen() {
      this.$store.dispatch('billboard/REST_post')
      this.$store.dispatch('billboard/GET_post', { id: this.id })
    },
    handleClosed() {
      this.$store.dispatch('billboard/REST_post')
    },

    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    toggle() {
      this.visible = !this.visible
    },
  },
}
</script>

<style lang="scss" scoped>
.BillboardPostDetailDialog {
  &__title-container {
    .title {
      line-height: 24px;
      font-size: 18px;
      font-weight: 500;
      color: #414141;
    }
    .extra {
      line-height: 24px;
      text-align: right;
      font-size: 14px;
      float: right;
      color: #afafaf;
    }
  }

  &__body-container {
    border-top: solid 1px #e6e6e6;
    border-bottom: solid 1px #e6e6e6;
    margin: -20px;
    padding: 20px;
    min-height: 100px;

    span.content {
      font-size: 16px;
      line-height: 2em;
      color: #414141;
    }
  }

  &__footer-container {
    display: flex;
    flex-direction: row;
    text-align: initial;

    &__attach-list {
      flex: 1;
      overflow-x: scroll;
      white-space: nowrap;
      margin-right: 20px;
    }
  }
}
</style>
