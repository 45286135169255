import { RouteConfig } from 'vue-router'
import Home from '../views/Home/Main.vue'

const routes: RouteConfig[] = [
  {
    path: '/',
    meta: {
      pageTitle: '廠商後台管理系統',
    },
    component: Home,
  },
]

export default routes
