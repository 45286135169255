import { getCurrentInstance } from './wrapper'

export const useMessageBox = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('This must be called within a setup function.')

  return {
    $msgbox: vm.$msgbox,
    $alert: vm.$alert,
    $confirm: vm.$confirm,
    $prompt: vm.$prompt,
  }
}
