import axios from 'axios'
import responseCancelToken from './interceptors/response-cancel-token'
import requestCancelToken from './interceptors/request-cancel-token'

const headers: Record<string, string> = {}

;(() => {
  const $meta = document.head.querySelector<HTMLMetaElement>(
    'meta[name="csrf-token"]'
  )
  if (!$meta) return

  headers['X-CSRF-TOKEN'] = $meta.content
})()

const request = axios.create({
  baseURL: '',
  headers,
})

export const INTERCEPTOR_REQUEST_CANCEL_TOKEN = requestCancelToken(request)
export const INTERCEPTOR_RESPONSE_CANCEL_TOKEN = responseCancelToken(request)
export default request
