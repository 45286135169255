import { RouteConfig } from 'vue-router'

import SalesReport from '../views/Sales/SalesReport.vue'

const routes: RouteConfig[] = [
  {
    path: '/sales/sales_report',
    meta: {
      pageTitle: '銷售報表',
    },
    component: SalesReport,
  },
]

export default routes
