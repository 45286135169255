
























































































































import _ from 'lodash'
import { Route } from 'vue-router'
import * as stringHelper from '@/legacy/utils/helper/string'
import * as ApiBrand from '@/legacy/api/commons/brand'
import { SearchType, ProductSaleStatus } from '@/legacy/api/stock/stock.type'
import {
  defineComponent,
  reactive,
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api'
import { useRouter, useRoute } from '@/composition-api'

interface FormData {
  search_type: string
  brand_id: string
  item_name: string
  upcs: string
  barcodes: string
  item_ids: string
  product_sale_status: ProductSaleStatus
}

const typeOptions = [
  {
    label: '品牌品名',
    value: SearchType.ItemNameAndBrand,
  },
  {
    label: '廠商貨號',
    value: SearchType.Upc,
  },
  {
    label: '國際條碼',
    value: SearchType.Barcode,
  },
  {
    label: '商品 ID',
    value: SearchType.ItemId,
  },
]

const statusOptions = [
  {
    label: '供貨中',
    value: ProductSaleStatus.OnOffer,
  },
  {
    label: '活動中商品',
    value: ProductSaleStatus.InActivity,
  },
  {
    label: '供貨中，數量少於 5',
    value: ProductSaleStatus.OverSoon,
  },
  {
    label: '暫停供貨',
    value: ProductSaleStatus.Suspend,
  },
  {
    label: '永久停供',
    value: ProductSaleStatus.Prohibited,
  },
]

export default defineComponent({
  props: {
    showSaleStatus: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const formData = reactive<FormData>({
      search_type: '',
      brand_id: '',
      item_name: '',
      upcs: '',
      barcodes: '',
      item_ids: '',
      product_sale_status: ProductSaleStatus.OnOffer,
    })
    const brands = ref<{ id: string; name: string }[]>([])

    const filterData = computed(() => {
      const filter: Record<string, any> = {
        search_type: formData.search_type,
        product_sale_status: formData.product_sale_status,
      }

      switch (formData.search_type) {
        case SearchType.ItemNameAndBrand:
          filter.brand_id = formData.brand_id
          filter.item_name = formData.item_name
          break
        case SearchType.Upc:
          filter.upcs = stringHelper.toIds(formData.upcs).join(',')
          break
        case SearchType.Barcode:
          filter.barcodes = stringHelper.toIds(formData.barcodes).join(',')
          break
        case SearchType.ItemId:
          filter.item_ids = stringHelper.toIds(formData.item_ids).join(',')
          break
      }

      return filter
    })

    const isSearchByItemName = computed(
      () => formData.search_type === SearchType.ItemNameAndBrand
    )
    const isSearchByUpcCode = computed(
      () => formData.search_type === SearchType.Upc
    )
    const isSearchByBarcode = computed(
      () => formData.search_type === SearchType.Barcode
    )
    const isSearchByItemId = computed(
      () => formData.search_type === SearchType.ItemId
    )

    const handleSearchClick = () => {
      const query = { ...route.value.query }

      Object.keys(filterData.value).forEach((key) => {
        if (filterData.value[key] !== '') {
          query[key] = filterData.value[key]
        }

        if (filterData.value[key] === '') {
          delete query[key]
        }
      })

      // 每次更新搜尋條件，都從預設值 page = 1 開始查詢
      query.page = '1'

      router.push({ query })
    }

    const handleResetClick = () => {
      router.push({ query: {} })
    }

    const fetchBrands = async () => {
      const { data } = await ApiBrand.list()
      brands.value = data
    }

    const validValueFromQuery = (
      array: (string | null)[],
      index: number = 0
    ): string => {
      const stringArray = array.filter(
        (item): item is string => typeof item === 'string'
      )
      return stringArray[index] || ''
    }

    const setFormData = (query: Route['query']) => {
      formData.search_type = _.isArray(query.search_type)
        ? validValueFromQuery(query.search_type)
        : query.search_type || ''
      formData.brand_id = _.isArray(query.brand_id)
        ? validValueFromQuery(query.brand_id)
        : query.brand_id || ''
      formData.item_name = _.isArray(query.item_name)
        ? validValueFromQuery(query.item_name)
        : query.item_name || ''
      formData.upcs = _.isArray(query.upcs)
        ? validValueFromQuery(query.upcs)
        : query.upcs || ''
      formData.barcodes = _.isArray(query.barcodes)
        ? validValueFromQuery(query.barcodes)
        : query.barcodes || ''
      formData.item_ids = _.isArray(query.item_ids)
        ? validValueFromQuery(query.item_ids)
        : query.item_ids || ''
      formData.product_sale_status = _.isArray(query.product_sale_status)
        ? (validValueFromQuery(query.product_sale_status) as ProductSaleStatus)
        : (query.product_sale_status as ProductSaleStatus) ||
          ProductSaleStatus.OnOffer
    }

    watch(
      () => route.value.query,
      (newQuery) => {
        setFormData(newQuery)
      },
      { immediate: true }
    )

    onMounted(() => {
      fetchBrands()
    })

    return {
      formData,
      brands,
      typeOptions,
      statusOptions,
      isSearchByItemName,
      isSearchByUpcCode,
      isSearchByBarcode,
      isSearchByItemId,
      handleSearchClick,
      handleResetClick,
    }
  },
})
