<template>
  <div class="Home">
    <page-title />
    <div class="page-content">
      <billboard-card
        :posts="billboardPosts.data"
        :loading="billboardPosts.fetching"
        @click-item="handleClickItem"
      />
    </div>

    <billboard-post-detail-dialog
      ref="billboard-post-detail-dialog"
      :id="currentPostId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageTitle from '@/legacy/components/PageTitle/PageTitle.vue'

import BillboardCard from './components/BillboardCard.vue'
import BillboardPostDetailDialog from './components/BillboardPostDetailDialog.vue'

export default {
  components: {
    PageTitle,
    BillboardCard,
    BillboardPostDetailDialog,
  },

  mounted() {
    this.$store.dispatch('billboard/GET_posts')
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('billboard/listReaded')
    })
  },

  data() {
    return {
      currentPostId: null,
    }
  },

  computed: {
    ...mapState({
      billboardPosts: (state) => state.billboard.list,
    }),
  },

  methods: {
    handleClickItem(id) {
      this.currentPostId = id
      this.$refs['billboard-post-detail-dialog'].open()
    },
  },
}
</script>

<style lang="scss" scoped>
.Home {
}
</style>
