












import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { CombinedVueInstance } from 'vue/types/vue'
import IconLogo from '@/legacy/components/IconLogo/IconLogo.vue'
import { AuthActionType } from '@/legacy/stores/modules/auth'
import { PermissionItem, User, Supplier } from '@/legacy/api/auth/auth.types'
import {
  NAME as COMMON_FORWARDER,
  ACTION_FETCH_FORWARDER_LIST,
} from '@/legacy/stores/modules/commons/forwarder'
import {
  NAME as COMMON_BRAND,
  ACTION_FETCH_BRAND_LIST,
} from '@/legacy/stores/modules/commons/brand'

export default {
  created() {
    this.$store.dispatch(`auth/${AuthActionType.FetchPermissions}`)
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    supplier() {
      return this.$store.getters['auth/supplier']
    },

    permissions() {
      return this.$store.getters['auth/permissions']
    },

    isFetchingForwarders() {
      return this.$store.state.common[COMMON_FORWARDER].fetching
    },

    isFetchingBrands() {
      return this.$store.state.common[COMMON_BRAND].fetching
    },

    isFetchingPermissions() {
      return this.$store.state.auth.permissions.fetching
    },

    isVisibleLoading() {
      if (
        this.isFetchingForwarders ||
        this.isFetchingBrands ||
        this.isFetchingPermissions
      ) {
        return true
      }

      if (!this.user) {
        return true
      }
      if (!this.supplier) {
        return true
      }

      return false
    },
  },

  watch: {
    supplier: {
      immediate: true,
      async handler(val: Supplier, oldVal: Supplier) {
        if (!val) {
          return
        }

        await Promise.all([
          this.$store.dispatch(
            `common/${COMMON_FORWARDER}/${ACTION_FETCH_FORWARDER_LIST}`,
            {
              forced: true,
            }
          ),
          this.$store.dispatch(
            `common/${COMMON_BRAND}/${ACTION_FETCH_BRAND_LIST}`,
            {
              forced: true,
            }
          ),
        ])

        if (val && oldVal && val.id !== oldVal.id) {
          this.$message.success('切換廠商成功')
        }
      },
    },
  },

  components: {
    IconLogo,
  },
} as ComponentOption

export type ComponentOption = ThisTypedComponentOptionsWithRecordProps<
  Instance,
  Data,
  Methods,
  Computed,
  Props
>

export type ComponentInstance = CombinedVueInstance<
  Instance,
  Data,
  Methods,
  Computed,
  Props
>

export interface Instance extends Vue {}

export interface Data {}

export type Methods = {}

export interface Computed {
  user: User
  supplier: Supplier
  permissions: PermissionItem[]
  isFetchingForwarders: boolean
  isFetchingBrands: boolean
  isFetchingPermissions: boolean
  isVisibleLoading: boolean
}

export interface Props {}
